import ServiceRequest from "../../app/services/index";
import { ApiPath, METHOD } from "../../app/config/apiPath";
import { ParserInterface } from "../../app/duck/types";
import { ApiConfig } from "../../app/config/apiConfig";
import { GetProjectDetailsApiPayloadInterface } from "../../project-setup/ducks/types";
import { GetPackDataApiPayloadInterface } from "./types";
import { omit } from "lodash";

class Apis {
    public *getSubDomainList(
        payload: GetProjectDetailsApiPayloadInterface,
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.GET,
            url: ApiPath.GET_SUBDOMAIN_LIST.replace("{{tenantCode}}", parser.tenantCode).replace(
                "{{project_id}}",
                payload.projectID.toString()
            )
        };
        const response = yield ServiceRequest.invoke(axiosParam);
        return response;
    }

    public *getPackData(
        payload: GetPackDataApiPayloadInterface,
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.GET,
            params: {
                ...omit(payload, ["projectID"])
            },
            url: ApiPath.GET_PACK_DATA.replace("{{tenantCode}}", parser.tenantCode).replace(
                "{{project_id}}",
                parser.projectId ? parser.projectId.toString() : ""
            )
        };
        const response = yield ServiceRequest.invoke(axiosParam);
        return response;
    }
}
export default new Apis();
