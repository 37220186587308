import * as React from "react";
import { isNull, get } from "lodash";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { downArrowWhite, lockIconAlps } from "../../app/icons";
import { HeaderNavListInterface, NavLinkType, PrimaryNavListChildrenInterface, PrimaryNavListInterface } from "../ducks/types";
import { getLinkWithParams } from "../ducks/utils";
import { ContextReducerInterface } from "../../app/duck/types";
import { SIMULATOR_URL } from "../ducks/routes-url-constant";
import { ALPS_LOCAL_STORAGE_KEY } from "../../app/const";

export const StyledSeconaryNavContainer = styled.div`
    height: 100%;
    margin-left: 60px;
`;
export const StyledSecondaryNavUl = styled.nav`
    height: 100%;
    display: flex;
    > a {
        color: #fff !important;
        font-weight: 700 !important;
        font-size: 12px;
        border-bottom: none;
        padding: 17px 20px 10px 20px;
        border-bottom: none !important;
    }
    > a.active {
        background: #0198b6;
    }
`;

export const StyledSecondarySubUl = styled.nav`
    min-width: 301px;
    background: #fff;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.34);
    position: absolute;
    top: 100%;
    box-sizing: border-box;
    left: 0;
    display: none;
    z-index: 1;
    line-height: 15px;
    border-radius: 3px;

    &:hover {
        display: block;
    }
`;
export const StyledSecondaryNavUlNavLink = styled(NavLink)`
    position: relative;
    float: left;
    font-size: 14px;
    font-weight: 700;
    padding: 14px 20px 10px 20px;
    letter-spacing: 1px;
    cursor: pointer;
    color: #4b5166;
    border-bottom: 0 solid #fff;
    line-height: 17px;

    &:hover {
        ${StyledSecondarySubUl} {
            display: block;
        }
    }
    &.active {
        color: #00acce;
        border-bottom: 4px solid #00acce;
    }
`;

export const StyledSecondaryNavUlAnchor = styled.a`
    position: relative;
    float: left;
    font-size: 14px;
    font-weight: 700;
    padding: 14px 20px 10px 20px;
    letter-spacing: 1px;
    cursor: pointer;
    color: #343434;
    border-bottom: 0px solid #fff;
    line-height: 17px;

    &:hover {
        ${StyledSecondarySubUl} {
            display: block;
        }
    }
    &.active {
        color: #00acce;
        border-bottom: 4px solid #00acce;
    }
`;

export const StyledSecondaryNavUlLinkHighlight = styled.span`
    background: #fb764b;
    position: absolute;
    font-size: 10px;
    color: #fff;
    border-radius: 2px;
    font-weight: 400;
    box-sizing: border-box;
    width: 30px;
    height: 13px;
    top: 3px;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: normal;
`;

export const StyledSecondaryProjectList = styled.div`
    float: left;
    font-size: 12px;
    font-weight: 700;
    padding: 14px 40px 0px 20px;
    height: 100%;
    letter-spacing: 1px;
    cursor: pointer;
    position: relative;
    color: #ffffff;
    border-bottom: 0px solid #fff;
    line-height: 17px;
    background: url(${downArrowWhite}) no-repeat;
    background-position: calc(100% - 20px) center;
    background-size: 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding-top: 0;

    &:hover {
        ${StyledSecondarySubUl} {
            min-width: 301px;
            display: block;
            color: #343434;
        }
    }
    &.active {
        background-color: #0198b6;
    }
`;

export const StyledSecondarySubNavLink = styled(NavLink)`
    display: block;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: normal;
    color: #343434;
    padding: 10px 20px;
    border-bottom: 1px solid #ebecf0;
    &:hover {
        ${StyledSecondarySubUl} {
            display: block;
        }
    }
    &:last-child {
        border: none;
    }
`;

export const StyledSecondarySubAnchor = styled.a`
    display: block;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: normal;
    color: #343434;
    padding: 10px 20px;
    border-bottom: 1px solid #ebecf0;
    &:hover {
        ${StyledSecondarySubUl} {
            display: block;
        }
    }
    &:last-child {
        border: none;
    }
`;

export const StyledBetaTag = styled.i`
    font-size: 12px;
    color: #ff0000;
    position: relative;
    font-weight: normal;
    top: -6px;
    left: 4px;
    text-transform: capitalize;
    &.lock {
        top: 0px;
        left: 15px;
        float: right;
    }
`;
export const StyledLockImg = styled.img``;

export const StyledNavLinkTitle = styled.div`
    display: flex;
    margin-bottom: 4px;
`;

export const StyledNavLinkIcon = styled.div`
    // tslint:disable-next-line:no-any
    background: url(${(props: { icon?: string }) => props.icon}) no-repeat;
    width: 16px;
    height: 16px;
    background-size: 16px;
    margin-right: 5px;
`;

export const StyledNavLinkDescription = styled.div`
    margin-left: 21px;
    line-height: 1.67;
    color: #4c4c4c;
`;

interface LocationInterface {
    pathname: string;
}

// tslint:disable-next-line:no-any
const matchRoute = (match: any, location: LocationInterface, highlightWhen: string[], activeRouteUrl: string) => {
    if (match) {
        return true;
    }
    return !!(highlightWhen.includes(location.pathname) || highlightWhen.includes(activeRouteUrl));
};

interface PrimaryNavigationPropsInterface {
    links: PrimaryNavListInterface[];
    activeRouteUrl: string;
    context: ContextReducerInterface;
    activeDropdownId?: string;
    toggleDropdown(link: HeaderNavListInterface | PrimaryNavListInterface, event: React.MouseEvent): void;
    handleRedirection(to: string, isExternal?: boolean): void;
    popup(arg: boolean): void;
}

interface PrimaryNavigationStateInterface {
    isDropdownOpen?: boolean;
    isUnsavedWarningPopEnabled?: boolean;
    redirectionInfo: { to: string; isExternal: boolean };
}

interface PrimaryNavigationChildrenPropsInterface {
    subLinks: PrimaryNavListChildrenInterface[];
    context: ContextReducerInterface;
    activeRouteUrl: string;
    handleRedirection(to: string, isExternal?: boolean): void;
    popup(arg: boolean): void;
}

const shouldChildHide = (link: PrimaryNavListChildrenInterface): boolean => {
    if (link.id === "data-on-demand") {
        const preMeta = localStorage.getItem(ALPS_LOCAL_STORAGE_KEY);
        const ls = !isNull(preMeta) ? JSON.parse(preMeta) : {};
        return get(ls, ["featureAddOns"], []).includes("dod");
    }
    if (link.id === "serp-feature-coverage") {
        const preMeta = localStorage.getItem(ALPS_LOCAL_STORAGE_KEY);
        const ls = !isNull(preMeta) ? JSON.parse(preMeta) : {};
        return get(ls, ["featureAddOns"], []).includes("serp-feature-coverage");
    }
    return !link.isHidden;
};

const PrimaryNavigationChildren: React.FunctionComponent<PrimaryNavigationChildrenPropsInterface> = ({
    subLinks,
    context,
    activeRouteUrl,
    handleRedirection,
    popup
}: PrimaryNavigationChildrenPropsInterface): JSX.Element => {
    return (
        <StyledSecondarySubUl>
            {subLinks
                .filter((subLink: PrimaryNavListChildrenInterface) => shouldChildHide(subLink))
                .map((subLink: PrimaryNavListChildrenInterface) => {
                    const {
                        id = "",
                        to = "",
                        title = "",
                        description = "",
                        icon,
                        type,
                        highlightWhen = [],
                        showOnlyForMobileType = false,
                        showOnlyForAdvancedProjectType = false,
                        isLocked = false,
                        isNew = false
                    } = subLink;
                    if (
                        (showOnlyForMobileType && !context.isMobileProject) ||
                        (showOnlyForAdvancedProjectType && context.isStandardProject)
                    ) {
                        return null;
                    }
                    if (type === NavLinkType.INTERNAL) {
                        const realPath = getLinkWithParams({ to, context, isExternal: false });
                        const toPath = isLocked ? "#" : realPath;

                        if (id === "content-brief-url") {
                            return (
                                <StyledSecondarySubNavLink
                                    key={id}
                                    id={id}
                                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                                        e.preventDefault();
                                        handleRedirection(getLinkWithParams({ to, context, isExternal: false }), false);
                                    }}
                                    to={isLocked ? toPath : getLinkWithParams({ to, context, isExternal: false })}
                                    isActive={(match, location) => matchRoute(match, location, highlightWhen, activeRouteUrl)}
                                >
                                    <StyledNavLinkTitle>
                                        <StyledNavLinkIcon icon={(icon as unknown) as string} />
                                        {title}
                                    </StyledNavLinkTitle>
                                    <StyledNavLinkDescription>{description}</StyledNavLinkDescription>
                                    {isLocked && (
                                        <StyledBetaTag className="lock">
                                            <StyledLockImg src={lockIconAlps} />
                                        </StyledBetaTag>
                                    )}
                                </StyledSecondarySubNavLink>
                            );
                        }

                        if (id === "serp-feature-coverage") {
                            return (
                                <StyledSecondarySubNavLink
                                    key={id}
                                    id={id}
                                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                                        if (isLocked) {
                                            // alert("This Feature is locked !!");
                                            popup(true);
                                        }
                                        if (activeRouteUrl === SIMULATOR_URL) {
                                            e.preventDefault();
                                            handleRedirection(getLinkWithParams({ to, context, isExternal: false }), false);
                                        }
                                    }}
                                    // to={getLinkWithParams({ to, context, isExternal: false })}
                                    to={toPath}
                                    isActive={(match, location) => matchRoute(match, location, highlightWhen, activeRouteUrl)}
                                >
                                    <StyledNavLinkTitle>
                                        <StyledNavLinkIcon icon={(icon as unknown) as string} />
                                        {title}
                                        <StyledBetaTag>Beta</StyledBetaTag>
                                    </StyledNavLinkTitle>
                                    <StyledNavLinkDescription>{description}</StyledNavLinkDescription>
                                    {isLocked && (
                                        <StyledBetaTag className="lock">
                                            <StyledLockImg src={lockIconAlps} />
                                        </StyledBetaTag>
                                    )}
                                </StyledSecondarySubNavLink>
                            );
                        }
                        return (
                            <StyledSecondarySubNavLink
                                key={id}
                                id={id}
                                onClick={(e: React.MouseEvent<HTMLElement>) => {
                                    if (isLocked) {
                                        // alert("This Feature is locked !!");
                                        popup(true);
                                    }
                                    if (activeRouteUrl === SIMULATOR_URL) {
                                        e.preventDefault();
                                        handleRedirection(getLinkWithParams({ to, context, isExternal: false }), false);
                                    }
                                }}
                                // to={getLinkWithParams({ to, context, isExternal: false })}
                                to={toPath}
                                isActive={(match, location) => matchRoute(match, location, highlightWhen, activeRouteUrl)}
                            >
                                <StyledNavLinkTitle>
                                    <StyledNavLinkIcon icon={(icon as unknown) as string} />
                                    {title}
                                </StyledNavLinkTitle>
                                <StyledNavLinkDescription>{description}</StyledNavLinkDescription>
                                {isNew && <StyledBetaTag>New</StyledBetaTag>}
                                {isLocked && (
                                    <StyledBetaTag className="lock">
                                        <StyledLockImg src={lockIconAlps} />
                                    </StyledBetaTag>
                                )}
                            </StyledSecondarySubNavLink>
                        );
                    }
                    return (
                        <StyledSecondarySubAnchor
                            key={id}
                            onClick={(e: React.MouseEvent<HTMLElement>) => {
                                if (isLocked) {
                                    return popup(true);
                                }
                                e.preventDefault();
                                handleRedirection(getLinkWithParams({ to, context, isExternal: true }), true);
                            }}
                            href={isLocked ? "javascript:void(0)" : getLinkWithParams({ to, context, isExternal: true })}
                            className={matchRoute(undefined, location, highlightWhen, activeRouteUrl) ? "active" : ""}
                        >
                            <StyledNavLinkTitle>
                                <StyledNavLinkIcon icon={(icon as unknown) as string} />
                                {title}
                            </StyledNavLinkTitle>
                            <StyledNavLinkDescription>{description}</StyledNavLinkDescription>
                            {isLocked && id !== "keyword-gap-analysis-beta" && (
                                <StyledBetaTag className="lock">
                                    <StyledLockImg src={lockIconAlps} />
                                </StyledBetaTag>
                            )}
                        </StyledSecondarySubAnchor>
                    );
                })}
        </StyledSecondarySubUl>
    );
};

interface PrimaryChildMenuNavigationStateInterface {
    isDropdownOpen: boolean;
}

interface PrimaryChildMenuNavigationPropsInterface {
    link: PrimaryNavListInterface;
    context: ContextReducerInterface;
    activeRouteUrl: string;
    activeDropdownId?: string;
    toggleDropdown(link: PrimaryNavListInterface, event: React.MouseEvent): void;
    handleRedirection(to: string, isExternal?: boolean): void;
    popup(arg: boolean): void;
}

class PrimaryChildMenuNavigation extends React.Component<
    PrimaryChildMenuNavigationPropsInterface,
    PrimaryChildMenuNavigationStateInterface
> {
    constructor(props: PrimaryChildMenuNavigationPropsInterface) {
        super(props);
        this.state = {
            isDropdownOpen: false
        };
    }

    public render(): JSX.Element {
        const { activeRouteUrl, context, toggleDropdown, link } = this.props;
        const { id, children, title = "", highlightWhen = [], isProjectUrl } = link;
        if (isProjectUrl && !context.projectId) {
            return <></>;
        }
        return (
            <StyledSecondaryProjectList
                key={id}
                onClick={(event: React.MouseEvent) => toggleDropdown(link, event)}
                className={`
                ${matchRoute(undefined, location, highlightWhen, activeRouteUrl) ? "active" : ""}`}
            >
                {title}
                <PrimaryNavigationChildren
                    handleRedirection={this.props.handleRedirection}
                    subLinks={children}
                    context={context}
                    activeRouteUrl={activeRouteUrl}
                    popup={this.props.popup}
                />
            </StyledSecondaryProjectList>
        );
    }
}

// tslint:disable-next-line:max-classes-per-file
class PrimaryNavigation extends React.Component<PrimaryNavigationPropsInterface, PrimaryNavigationStateInterface> {
    constructor(props: PrimaryNavigationPropsInterface) {
        super(props);
        this.shouldNavHide = this.shouldNavHide.bind(this);
    }

    public shouldNavHide(link: PrimaryNavListInterface): boolean {
        if (link.id === "custom-reporting") {
            const preMeta = localStorage.getItem(ALPS_LOCAL_STORAGE_KEY);
            const ls = !isNull(preMeta) ? JSON.parse(preMeta) : {};
            return get(ls, ["featureAddOns"], []).includes("custom-bi");
        }
        return !link.isHidden;
    }

    public render(): JSX.Element {
        const { links, context, activeRouteUrl, toggleDropdown, activeDropdownId } = this.props;

        return (
            <StyledSeconaryNavContainer>
                <StyledSecondaryNavUl>
                    {links.length &&
                        links.filter(this.shouldNavHide).map((link: PrimaryNavListInterface) => {
                            const {
                                id = "",
                                to = "",
                                title = "",
                                highlightWhen = [],
                                children = [],
                                type,
                                isNew = false,
                                isLocked = false
                            } = link;
                            if (!children.length) {
                                if (type === NavLinkType.INTERNAL) {
                                    return (
                                        <StyledSecondaryNavUlNavLink
                                            key={id}
                                            id={id}
                                            to={getLinkWithParams({ to, context, isExternal: false })}
                                            isActive={(match, location) => matchRoute(match, location, highlightWhen, activeRouteUrl)}
                                        >
                                            {title}
                                            {isNew && <StyledSecondaryNavUlLinkHighlight>New</StyledSecondaryNavUlLinkHighlight>}
                                        </StyledSecondaryNavUlNavLink>
                                    );
                                }
                                return (
                                    <StyledSecondaryNavUlAnchor
                                        key={id}
                                        href={isLocked ? "javascript:void(0)" : getLinkWithParams({ to, context, isExternal: true })}
                                        className={matchRoute(undefined, location, highlightWhen, activeRouteUrl) ? "active" : ""}
                                    >
                                        {title}
                                        {isNew && <StyledSecondaryNavUlLinkHighlight>New</StyledSecondaryNavUlLinkHighlight>}
                                    </StyledSecondaryNavUlAnchor>
                                );
                            }
                            return (
                                <PrimaryChildMenuNavigation
                                    key={id}
                                    link={link}
                                    context={context}
                                    activeRouteUrl={activeRouteUrl}
                                    toggleDropdown={toggleDropdown}
                                    activeDropdownId={activeDropdownId}
                                    handleRedirection={this.props.handleRedirection}
                                    popup={this.props.popup}
                                />
                            );
                        })}
                </StyledSecondaryNavUl>
            </StyledSeconaryNavContainer>
        );
    }
}

export default PrimaryNavigation;
