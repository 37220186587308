import { createAction } from "redux-actions";

/**
 * prefix GET, POST to communicate with server via saga
 * prefix WATCH to ask saga listen to me.
 * prefix SET to save in store
 */
const ActionTypes = {
    GET_SUB_DOMAIN_LIST: "GET_SUB_DOMAIN_LIST",
    GET_PACK_DATA: "GET_PACK_DATA",
    SAVE_PACK_DATA: "SAVE_PACK_DATA"
};
export default ActionTypes;

// GETTERS / API CALL ACTIONS
export const callToGetSubDomainList = createAction(ActionTypes.GET_SUB_DOMAIN_LIST);
export const callToGetPackData = createAction(ActionTypes.GET_PACK_DATA);
export const savePackData = createAction(ActionTypes.SAVE_PACK_DATA);

