import { combineReducers } from "redux";
import context from "./reducer";
import userSubscription from "../../user-subscription/ducks/reducer";
import dataOnDemand from "../../data-on-demand/ducks/reducer";
import competitiveIntelligence from "../../competitive-intelligence/ducks/reducer";
import customReporting from "../../custom-reporting/ducks/reducer";
import keyWordResearch from "../../kw-research/ducks/reducer";
import contentBrief from "../../content-brief/ducks/reducer";
import contentBriefNew from "../../content-brief-new/ducks/reducer";
import contentBriefView from "../../content-brief-view/ducks/reducer";
import suggestedKeyword from "../../suggested-keywords/ducks/reducer";
import performanceImpact from "../../performance-impact/ducks/reducer";

// beta reducers
import keyWordAnalysisBeta from "../../beta/key-word-analysis/ducks/reducer";
import pageOptimizerBeta from "../../beta/page-optimizer/ducks/reducer";
import serpResultBeta from "../../beta/serp-result/ducks/reducer";
import simulatorBeta from "../../beta/simulator/ducks/reducer";
import multiKeywordBeta from "../../beta/simulator/Components/AddModifyKeyword/ducks/reducer";
import autoLogin from "../../auto-login/ducks/reducer";
import projectSetup from "../../project-setup/ducks/reducer";
import recommendation from "../../beta/simulator/Components/ContentOptimization/SummaryComponent/ducks/reducer";
import emailVreification from "../../email-verification/ducks/reducer";
import userAccount from "../../user_account/ducks/reducer";
import packData from "../../serp-dashboard/ducks/reducer";


import app from "./appReducer";
import routes from "../../routes/ducks/reducer";

const CombineReducers = combineReducers({
    app,
    context,
    dataOnDemand,
    userSubscription,
    routes,
    competitiveIntelligence,
    customReporting,
    keyWordResearch,
    performanceImpact,
    // beta reducers
    serpResultBeta,
    simulatorBeta,
    multiKeywordBeta,
    pageOptimizerBeta,
    keyWordAnalysisBeta,
    contentBrief,
    contentBriefNew,
    contentBriefView,
    suggestedKeyword,
    autoLogin,
    projectSetup,
    recommendation,
    emailVreification,
    userAccount,
    packData
});
export default CombineReducers;
