import {
    CategoryType,
    ImportanceColorSchemeInterface,
    FilterListInterface,
    ImportanceLevelType,
    LegendInterface,
    SubscriptionNavigationOptionInterface,
    SubscriptionNavigationType,
    PerformanceType,
    COPYInterface,
    ALPSScoreSubCategoryType,
    FooterLinkInterface,
    AppliedFilterSortInterface,
    SerpListProgressStatusType,
    MultiKeywordTrackRequestApiRequestIdStatusResponseInterface,
    RadioInterface,
    SimulationHistoryTableInterface,
    RelatedKwSseEventInterface,
    RelatedKwInititeApiTypeInterface,
    KwResearchInputTypesInterface,
    ContentSimulatorModelScoreListTypeBeta,
    RecommendationSseEventInterface,
    PageNotCrawlInterface
} from "./duck/types";

import {
    OnPageElementsResponseInterfaceBeta,
    SimulatePerformanceLabelType
} from "../beta/simulator/ducks/types";
import { OptionTypeInterface } from "./styledComponents/drop-down/types";
import { BrandingFilter } from "../beta/simulator/Components/AddModifyKeyword/ducks/types";
import { AppliedFilterOnKeywordInterface } from "../suggested-keywords/ducks/types";
import {
    PROJECT_SETUP_ADD_KEYWORDS_URL,
    PROJECT_SETUP_COMPETITORS_URL,
    PROJECT_SETUP_INTEGRATIONS_URL
} from "../routes/ducks/routes-url-constant";

export const MAX_ALLOWED_INPUT_KEYWORDS: number = 20;
export const MAX_ALLOWED_INPUT_KEYWORDS_SIMULATION = 5;
export const TRACK_TIME_OUT = 15 * 60 * 1000;
export const MULTI_KEYWORD_MAX_TRACKING_TIME_IN_MIN = 15;
export const IQ_ALPS_COOKIE_EXPIRY_TIME = 3600000 * 24;
export const SESSION_REQUEST_ID = "req_id";
export const ALPS_LOCAL_STORAGE_KEY = "ls.__iQ_alps";
export const TYPEFORM_LOCAL_STORAGE_KEY = "__iQ_typeform";
export const ALPS_SELECTED_LOCALE_KEY = "selected_locale";
export const SEARCH_ENGINE = "google";
export const CSV_WITH_KEYWORD = "serp_layout.csv";
export const CSV_WITH_DOMAIN = "list_of_all_parameters.csv";
export const KEYWORD = "keyword";
export const SORT = "sort";
export const ASCENDING = "asc";
export const DESCENDING = "desc";
export const RESET_CATEGORY_SIMULATOR = "alert reset";
export const ALL_KEYWORD_PROCESSED = "alert all_keyword_processed";
export const CONSENT_TO_PROCEED_ON_REFRESH = "alert consent_to_proceed_on_resfresh";
export const CONSENT_TO_PROCEED_ON_REFRESH_WHEN_DIRTY = "alert consent_to_proceed_on_resfresh_when_dirty";
export const HIGH_TO_LOW = "high_to_low";
export const LOW_TO_HIGH = "low_to_high";
export const DEFAULT_LOCALE = "en-us";
export const SERP_COMPLETE_PROGRESS_MAX_PERCENTAGE = 100;
export const NUMBER_FORMAT = ["K", "M", "B", "T"];
export const PARAMETER_NUMBET_FORMAT = ["M", "B", "T"];
export const MAX_CHARACTERS_SIZE = 50;
export const MAX_CONTENT_BRIEF_KEYWORD_CHARACTERS_SIZE = 1000;
export const CONTENT_BRIEF_MAX_SELECTED_KEYWORDS = 20;
export const DEFAULT_CONTENT_BRIEF_LOCALE = { value: "en-us", label: "us" };
export const MAX_CB_DESCRIPTION_CHARACTERS_SIZE = 300;
export const TENANT_LIST_LOCAL_STORAGE_KEY = "tenant_list";

export const HEXCODE = {
    ERROR: "#FB764B",
    WARNING: "#FFA501"
};
export const PARAMETER_LEGENDS: LegendInterface[] = [
    {
        symbolColor: "blue",
        symbolText: "Target"
    },
    {
        symbolColor: "dark_black",
        symbolText: "Competitor"
    },
    {
        symbolColor: "orange",
        symbolText: "Competitor is better"
    },
    {
        symbolColor: "green",
        symbolText: "Target is better"
    }
];
export const VIEW_ALL_COMPATITORS_LEGENDS: LegendInterface[] = [
    {
        symbolColor: "blue",
        symbolText: "Target"
    },
    {
        symbolColor: "dark_black",
        symbolText: "Search Competitor"
    }
];

export const KGA_TABS = [
    {
        displayName: "Content",
        id: CategoryType.CONTENT,
        isActive: true
    },
    {
        displayName: "Authority",
        id: CategoryType.AUTHORITY,
        isActive: false
    },
    {
        displayName: "Technical",
        id: CategoryType.TECHNICAL,
        isActive: false
    }
];

export const DEVICE_TYPE_OPTIONS: OptionTypeInterface[] = [
    { default: true, label: "Desktop", value: "desktop" },
    { default: false, label: "Mobile", value: "mobile" }
];

export const SORTING_OPTIONS: OptionTypeInterface[] = [
    { default: true, label: "Name ( A – Z)", order: [], value: ASCENDING },
    { default: false, label: "Name ( Z – A)", order: [], value: DESCENDING },

    {
        default: false,
        label: "Importance ( V High – V low)",
        order: [
            ImportanceLevelType.VERY_HIGH,
            ImportanceLevelType.HIGH,
            ImportanceLevelType.MEDIUM,
            ImportanceLevelType.LOW,
            ImportanceLevelType.VERY_LOW,
            ImportanceLevelType.EMPTY
        ],
        value: "VH-VL"
    },
    {
        default: false,
        label: "Importance (V Low – V High)",
        order: [
            ImportanceLevelType.VERY_LOW,
            ImportanceLevelType.LOW,
            ImportanceLevelType.MEDIUM,
            ImportanceLevelType.HIGH,
            ImportanceLevelType.VERY_HIGH,
            ImportanceLevelType.EMPTY
        ],
        value: "VL-VH"
    },
    {
        default: false,
        label: "Gap ( High – Low)",
        order: [],
        value: HIGH_TO_LOW
    },
    {
        default: false,
        label: "Gap ( Low – High)",
        order: [],
        value: LOW_TO_HIGH
    }
];

export const KWA_PAGE_TITLE = "Keyword Gap Analysis";

export const IMPORTANCE_COLOR_SCHEME: ImportanceColorSchemeInterface[] = [
    {
        hexCode: "#3c1674",
        color: "very_high_purple",
        importance: ImportanceLevelType.VERY_HIGH
    },
    {
        hexCode: "#625b94",
        color: "high_purple",
        importance: ImportanceLevelType.HIGH
    },
    {
        hexCode: "#988dc0",
        color: "med_purple",
        importance: ImportanceLevelType.MEDIUM
    },
    {
        hexCode: "#c3b7ed",
        color: "low_purple",
        importance: ImportanceLevelType.LOW
    },
    {
        hexCode: "#e9c3e1",
        color: "v_low_purple",
        importance: ImportanceLevelType.VERY_LOW
    },
    {
        hexCode: "#ABDAD3",
        color: "not_in_model",
        importance: ImportanceLevelType.EMPTY
    }
];

export const filters: FilterListInterface[] = [
    {
        classProp: "vh",
        isChecked: false,
        label: "Very High",
        value: ImportanceLevelType.VERY_HIGH,
        disabled: false
    },
    {
        classProp: "high",
        isChecked: false,
        label: "High",
        value: ImportanceLevelType.HIGH,
        disabled: false
    },
    {
        classProp: "med",
        isChecked: false,
        label: "Medium",
        value: ImportanceLevelType.MEDIUM,
        disabled: false
    },
    {
        classProp: "low",
        isChecked: false,
        label: "Low",
        value: ImportanceLevelType.LOW,
        disabled: false
    },
    {
        classProp: "vlow",
        isChecked: false,
        label: "Very Low",
        value: ImportanceLevelType.VERY_LOW,
        disabled: false
    }
];

export const ALPS_SCORE_HELPER: string = `ALPS score for a keyword-URL pair denotes the strength of a URL to rank for a keyword.
 The score ranges between 0-100, higher being better.ALPS score is calculated by taking into account content,
 authority and technical performances of your page. Use this score to analyze performance
 gap between your URL and competitors`;

export const HIGHEST_RANK_HELPER: string = `Highest ranking URL for the selected keyword from your domain.`;

export const PARAMETER_HELPER: string = `ALPS considers numerous SEO parameters and allocates scores that quantify the Content, Authority and Technical health of a ranking URL.`;

export const DOWNLOAD_WITH_KEYWORD = "SERP layout for selected keyword";
export const DOWNLOAD_WITH_KEYWORD_URL = "Parameter Values for selected keyword";
export const DOWNLOAD_WITH_ALL_KEYWORD = "SERP layout for all keywords";

export const SUBSCRIPTION_NAVIGATION_ENTERPRISE_OPTIONS: SubscriptionNavigationOptionInterface[] = [
    {
        id: SubscriptionNavigationType.PROFILE,
        isActive: true,
        label: "USER PROFILE"
    },
    {
        id: SubscriptionNavigationType.BILLING,
        isActive: false,
        label: "BILLING INVOICE"
    },
    {
        id: SubscriptionNavigationType.FAQ,
        isActive: false,
        label: "FAQ"
    }
];
export const SUBSCRIPTION_NAVIGATION_OPTIONS: SubscriptionNavigationOptionInterface[] = [
    {
        id: SubscriptionNavigationType.USAGE,
        isActive: true,
        label: "LICENSE USAGE"
    },
    {
        id: SubscriptionNavigationType.PROFILE,
        isActive: false,
        label: "USER PROFILE"
    },
    {
        id: SubscriptionNavigationType.BILLING,
        isActive: false,
        label: "BILLING INVOICE"
    },
    {
        id: SubscriptionNavigationType.FAQ,
        isActive: false,
        label: "FAQ"
    }
];
export const HOME_BREADCRUMB: string[] = ["Welcome!"];
export const PAGE_OPTIMIZER_BREADCRUMB: string[] = ["Page Optimizer"];

export const SERP_RESULT_BREADCRUMB: string[] = ["RANK SIMULATOR", "results"];

export const KEYWORD_ANALYSIS_BREADCRUMB_LIST: string[] = ["RANK SIMULATOR", "keyword gap analysis"];
export const KEYWORD_ANALYSISPROJECT_CONTEXT_BREADCRUMB_LIST: string[] = ["optimize keyword", "keyword optimization"];
export const MY_ACCOUNT_BREADCRUMB: string[] = ["Home", "My Account"];

/** change names for all above bread crumb prefix will be BREADCRUMB */

export const BREADCRUMB_SIMULATOR: string[] = ["RANK SIMULATOR", "SIMULATOR"];

export const BREADCRUMB_COMPETITION_INTELLIGENCE: string[] = ["COMPETITION INTELLIGENCE"];

export const DAY = "day";
export const DAYS = "days";
export const MONTH = "month";
export const MONTHS = "MONTHS";
export const YEAR = "year";
export const YEARS = "years";

export const CONTENT_SIMULATOR_LEGEND: LegendInterface[] = [
    {
        symbolColor: "high_bg",
        symbolText: ImportanceLevelType.HIGH
    },
    {
        symbolColor: "medium_bg",
        symbolText: ImportanceLevelType.MEDIUM
    },
    {
        symbolColor: "low_bg",
        symbolText: ImportanceLevelType.LOW
    }
];
export const PERFORMANCE_SCORE_ORDER: PerformanceType[] = [PerformanceType.TRAFFIC, PerformanceType.ORGANIC_RANK];

export const CONTENT_SIMULATOR_PARAMETERS_ORDER_LIST_BETA = [
    ContentSimulatorModelScoreListTypeBeta.CATEGORY_SCORES,
    ContentSimulatorModelScoreListTypeBeta.URL_SCORE,
    ContentSimulatorModelScoreListTypeBeta.TITLE_SCORE,
    ContentSimulatorModelScoreListTypeBeta.META_DESCRIPTION_SCORE,
    ContentSimulatorModelScoreListTypeBeta.H1_SCORE,
    ContentSimulatorModelScoreListTypeBeta.H2_SCORE,
    ContentSimulatorModelScoreListTypeBeta.H3_SCORE,
    ContentSimulatorModelScoreListTypeBeta.VISIBLE_CONTENT_SCORE
];

// tslint:disable-next-line:prefer-array-literal
export const IMPACTED_KW_ALL_SCORES_PARAM_ORDER_BETA: ContentSimulatorModelScoreListTypeBeta[] = [
    ContentSimulatorModelScoreListTypeBeta.URL_SCORE,
    ContentSimulatorModelScoreListTypeBeta.TITLE_SCORE,
    ContentSimulatorModelScoreListTypeBeta.META_DESCRIPTION_SCORE,
    ContentSimulatorModelScoreListTypeBeta.H1_SCORE,
    ContentSimulatorModelScoreListTypeBeta.H2_SCORE,
    ContentSimulatorModelScoreListTypeBeta.H3_SCORE,
    ContentSimulatorModelScoreListTypeBeta.VISIBLE_CONTENT_SCORE,
    ContentSimulatorModelScoreListTypeBeta.READABILITY_GRADE
];

export const DEFAULT_EDITOR_CONTENT_BETA: OnPageElementsResponseInterfaceBeta = {
    missing_h1: [],
    missing_h2: [],
    missing_h3: [],
    meta_description: [],
    title: [],
    url: "",
    stripped_page: ""
};
export const IS_DEV_SRC = process.env && process.env.IMAGE_URL && (process.env.IMAGE_URL as string).startsWith("http");
export const REPORTS_GRID_DATA = [
    {
        description: "Stay on top of keywords that create the most impact. View accurate traffic and conversion data at a keyword level.",
        img: IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/report-1.svg` : require(`${process.env.DEV_IMAGE_URL}/img/report-1.svg`),
        modalImg: IS_DEV_SRC
            ? `${process.env.IMAGE_URL}/img/Keywords-Rank-Performance.png`
            : require(`${process.env.DEV_IMAGE_URL}/img/Keywords-Rank-Performance.png`),
        title: "Keyword Rank & Performance"
    },
    {
        description: "Compare various areas of your SEO with competitors and see the gaps which can be optimized for a better rank.",
        img: IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/report-2.svg` : require(`${process.env.DEV_IMAGE_URL}/img/report-2.svg`),
        modalImg: IS_DEV_SRC
            ? `${process.env.IMAGE_URL}/img/Keyword-Gap-Analysis.png`
            : require(`${process.env.DEV_IMAGE_URL}/img/Keyword-Gap-Analysis.png`),
        title: "Keyword Gap Analysis"
    },
    {
        description: "Predict the impact of your on-Page content changes on keyword rank, traffic and conversions.",
        img: IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/report-3.svg` : require(`${process.env.DEV_IMAGE_URL}/img/report-3.svg`),
        modalImg: IS_DEV_SRC
            ? `${process.env.IMAGE_URL}/img/Content-Simulation.png`
            : require(`${process.env.DEV_IMAGE_URL}/img/Content-Simulation.png`),
        title: "Content Simulation"
    },
    {
        description:
            "Identify content gaps in pages for targeted keywords. View recommendations to improve content relevance and on-page SEO.",
        img: IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/report-4.svg` : require(`${process.env.DEV_IMAGE_URL}/img/report-4.svg`),
        modalImg: IS_DEV_SRC
            ? `${process.env.IMAGE_URL}/img/Content-Gap-Analysis.png`
            : require(`${process.env.DEV_IMAGE_URL}/img/Content-Gap-Analysis.png`),
        title: "Content Gap Analysis"
    },
    {
        description: "Analyze backlink issues that need attention and opportunities to build new links.",
        img: IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/report-5.svg` : require(`${process.env.DEV_IMAGE_URL}/img/report-5.svg`),
        modalImg: IS_DEV_SRC
            ? `${process.env.IMAGE_URL}/img/Backlink-Analysis.png`
            : require(`${process.env.DEV_IMAGE_URL}/img/Backlink-Analysis.png`),
        title: "Backlink Analysis"
    },
    {
        description: "Identify technical issues across indexation, speed and mobility that need fixed.",
        img: IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/report-6.svg` : require(`${process.env.DEV_IMAGE_URL}/img/report-6.svg`),
        modalImg: IS_DEV_SRC ? `${process.env.IMAGE_URL}/img/TechAudit .png` : require(`${process.env.DEV_IMAGE_URL}/img/TechAudit .png`),
        title: "Technical Audit"
    }
];

export const SERP_PAGE_DOMAIN_PROTOCOL_OPTIONS: OptionTypeInterface[] = [
    { label: "https", value: "https" },
    { label: "http", value: "http" }
];
export const SERP_PAGE_URL_DOMAIN_SEARCH_OPTIONS: OptionTypeInterface[] = [
    { label: "Domain", value: "domain" },
    { label: "Url", value: "url" }
];
export interface HomeSubtitleDataInterface {
    step: number;
    line1: string;
    line2: string;
}
export const HOME_BANNER_SUBTITLE_DATA: HomeSubtitleDataInterface[] = [
    {
        line1: "Enter a",
        line2: "Keyword/URL",
        step: 1
    },
    {
        line1: "Select",
        line2: "Competitor",
        step: 2
    },
    {
        line1: "Explore Content,",
        line2: "Authority & Technical gaps",
        step: 3
    },
    {
        line1: "Simulate",
        line2: "",
        step: 4
    },
    {
        line1: "Get predicted Rank &",
        line2: "Traffic",
        step: 5
    }
];

export const PAGECONTENTBRIEF_BANNER_SUBTITLE_DATA: HomeSubtitleDataInterface[] = [
    {
        line1: "Select the locale and enter a topic ",
        line2: "for the content brief",
        step: 1
    },
    {
        line1: "Add/select the keywords you",
        line2: "would like to target",
        step: 2
    },
    {
        line1: "Generate, view & download the",
        line2: "content brief",
        step: 3
    }
];

export const DEPENDENT_PARAMETERS = {
    BACK_LINK_COUNT: "backlink_count",
    BACK_LINKS_WITH_REDIRECT: "redirect_count",
    BACK_LINKS_WITH_GENERIC_ANCHOR_TEXT: "url_bck_gen_ac_txt_cnt_pct",
    BACK_LINKS_WITH_URL_ANCHOR_TEXT: "url_bck_ac_txt_url_cnt_pct",
    BACK_LINK_WITH_NON_EMPTY_ANCHOR_TEXT: "url_bck_ac_txt_cnt_pct",
    REF_DOMAINS_COUNT: "refdomains_count",
    KW_URL_BACK_AC_TEXT_FULL_MATCH_TEXT: "kw_url_bck_ac_txt_full_mtch_pct"
};

export const IMPACTED_KEYWORD_HEADER_ORDER = [
    "",
    "keyword",
    "URL",
    SimulatePerformanceLabelType.ORGANIC_RANK,
    SimulatePerformanceLabelType.TRAFFIC,
    ALPSScoreSubCategoryType.CONTENT_SCORE,
    ALPSScoreSubCategoryType.AUTHORITY_SCORE,
    ALPSScoreSubCategoryType.TECHNICAL_SCORE
];

export const IMPACTED_KEYWORD_CONTENT_HEADER_ORDER_BETA = [...IMPACTED_KW_ALL_SCORES_PARAM_ORDER_BETA];

export const DEFAULT_KEYWORD_LEVEL_STATUS: MultiKeywordTrackRequestApiRequestIdStatusResponseInterface = {
    completion_status: SerpListProgressStatusType.DEFAULT,
    is_my_url_processed: true,
    percent_completed: 100,
    my_url_status: SerpListProgressStatusType.DEFAULT
};

export const DOD_USER_INSTRUCTIONS = [
    "Select a locale (ex: en-us for English - United States)",
    "Select the device type Desktop or Mobile. This option is available only for keyword ranks.",
    "For Rank and Search Volume, upload the list of keywords (max 100 per upload) using the sample template and click on ‘Submit’.",
    "For Footprint data, upload the list of URLs (max 10 per upload) using the sample template and click on ‘Submit’.",
    "You will receive an email notification with a link to download the report."
];

/** Copy content goes here */
export const COPY: COPYInterface = {
    /**
     * Keep all notification related constants at one place
     * Notification Starts
     *
     * MESSAGE_MODULENAME_CTA_?FLOW
     */

    /**
     * Notification ends
     */
    KEYWORD: "Keyword",
    TARGET: "Target",
    SUBMIT: "Submit",
    RANK: "rank",
    SV: "sv",
    URL_FOOTPRINT: "url_footprint",
    DOMAIN_FOOTPRINT: "domain_footprint",
    DOD_DATA_TYPE_KEYWORD_RANK: "Keyword Rank",
    DOD_DATA_TYPE_PAGE_FOOTPRINT: "Page Footprint",
    DOD_DATA_TYPE_DOMAIN_FOOTPRINT: "Domain Footprint",
    DOD_RANK_INFO_TEXT: "Get the latest Desktop/Mobile Google SERP (top 100 ranks) for a list of Keywords.",
    DOD_SV_INFO_TEXT: "Get the latest and historical search volume from Google for a list of keywords.",
    DOD_PAGE_FOOTPRINT_INFO_TEXT: "Get the entire list of keywords that a page ranks for. You can provide multiple pages at once.",
    DOD_DOMAIN_FOOTPRINT_INFO_TEXT: "Get the entire list of keywords that a Domains ranks for. You can provide multiple domains at once.",
    DOD_SELECT_LOCAL_LABEL: "Select Locale",
    DOD_SELECT_DEVICE_LABEL: "Select Device",
    DOD_FILE_UPLOAD_PLACEHOLER: "Select a .csv file upto 1MB",
    DOD_SAMPLE_TEMPLATE_LINK: "Sample Template",
    DOD_FILE_UPLOAD_INFO_TEXT: "ETA for {{inputData}} is 10 mins",
    DOD_FILE_UPPLOAD_NON_CSV_ERROR_TEXT: "Please upload a valid csv file as per the template",
    DOD_FILE_UPPLOAD_EMPTY_FILE_ERROR_TEXT: "Please upload a file with at least 1 input",
    DOD_FILE_NOT_UPLOADED_ERROR: "Please upload a file",
    DOD_FILE_SIZE_MORE_THAN_1MB_ERROR: "Please upload a .csv file with size < 1 MB",
    DOD_FILE_UPLOAD_MAX_SIZE_ERROR_TEXT: "Upload file can have a max 100 keywords only",
    DOD_FOOT_PRINT_FILE_MAX_SIZE_ERROR: "Upload file can have a max 10 URLs only",
    DOD_USER_GUIDE_TITLE: "HOW TO USE THE TOOL",
    DOD_REQUEST_SUCCESS_MSG:
        "Your data request {{reqCode}} is being processed. You will receive an email with a link to download the report.",
    DOD_SERVER_ERROR: "Unable to process your request at this moment. Please try again later",

    FILTERS_TEXT: "FILTERS",
    KEYWORD_SUGGESTIONS: "Keyword Suggestions",
    KEYWORD_URL_BOX_I_DO_NOT_HAVE_LIVE_URL: "Create a new page",
    KEYWORD_URL_BOX_I_HAVE_LIVE_URL: "Optimize an existing page",
    KEYWORD_SUCESSFULLY_DELETED: "Keywords successfully deleted",
    KEYWORD_DELETION_FAILED: "Could not delete the keywords. Please try again or contact alps@iquanti.com",
    COMPETITOR_SAVED_SUCCESSFULLY: "Successfully saved the Competitor Domain URLs",
    COMPETITOR_SAVED_FAILED: "Could not save the Competitor Domain URLs.\n Please try again or contact alps@iquanti.com",

    HOME_BANNER_SUBTITLE: "Subscribe today and get",
    HOME_BANNER_SUBTITLE_HIGHLIGHT: "{{discount}}",
    HOME_BANNER_SUBTITLE_HIGHLIGHT_2: "discount.",
    HOME_BANNER_TITLE1: "Make your",
    HOME_BANNER_TITLE2: "predictable in",
    HOME_BANNER_TITLE3: "easy steps",
    HOME_BANNER_TITLE_HIGHLIGHT1: "SEO",
    HOME_BANNER_TITLE_HIGHLIGHT2: "5",
    HOME_KW_SEARCHBOX_DEFAULT_LOCALE: "en-us",
    HOME_KW_SEARCHBOX_INFO_LIST_1: "SERP Layout",
    HOME_KW_SEARCHBOX_INFO_LIST_2: "Top Ranking URLs",
    HOME_KW_SEARCHBOX_INFO_LIST_3: "ALPS Score for top URLs",
    HOME_KW_SEARCHBOX_INFO_TITLE: "What you get?",
    HOME_KW_SEARCHBOX_SUBMIT_BUTTON_LABEL: "Go",
    HOME_KW_SEARCHBOX_TITLE: "Provide Keyword & URL for Analysis",
    HOME_REPORTS_GRID_TITLE: "ALPS SAMPLE REPORTS",

    DATA_ON_DEMAND_TITLE1: "Data on Demand",
    DATA_ON_DEMAND_TITLE2:
        "ALPS Data on Demand tool helps you get instant live data for keyword ranks, search volume and page footprints in bulk.",

    PROJECT_SETUP_PROJECT_LABEL: "Project",
    PROJECT_SETUP_LOCALE_LABEL: "Locale",
    PROJECT_SETUP_ENTER_DOMAIN_SUBDOMAIN_LABEL: "Domain/Sub-Domain/Folder",
    PROJECT_SETUP_SUB_DOMAIN_LIMIT_REACHED: "You can only add upto 5 sub-domains",
    PROJECT_SETUP_ENTER_SUB_DOMAIN_PLACEHOLDER: "Enter Sub-Domain URL(s) (separate URLs by comma)",
    PROJECT_SETUP_ENTER_COMPETITOR_DOMAIN_PLACEHOLDER: "Enter Domain (separate URL by comma)",
    PROJECT_SETUP_COMPETITOR_DOMAIN_FORM_ERROR_TEXT: "Please enter a valid domain (e.g. https://www.example.com)",
    PROJECT_SETUP_COMPETITOR_DOMAIN_INPUT_TEXT_LABEL: "* Add upto 20 Competitor Domains",
    PROJECT_SETUP_BUTTON_LABEL: "Setup a Project",
    PROJECT_ALREADY_EXIST: "This Project name is already in use. Try another",
    PROJECT_SETUP_TITLE: "For Advanced Reports and Analysis",
    PROJECT_SETUP_ADD_KEYWORD_ADD_CATEGORY_OPTION_FIELD: "Category (Optional)",
    PROJECT_SETUP_ADD_KEYWORD_POP_UP_MAP_DETAILS: "Map additional details to the keywords",
    PROJECT_SETUP_ADD_KEYWORD_POP_UP_ADD_CATEGORY: "+ Add New Category",
    PROJECT_SETUP_ADD_KEYWORD_POP_UP_ADD_TO_OPTIMIZATION: "Do you want to optimise for these keywords (Optional)",
    PROJECT_SETUP_MANAGE_KEYWORD_DATA_STILL_PROCESSING:
        "Some data is still processing. Typically it takes 8-10hrs after a new keyword has been added. Please come back after a few hours",
    PROJECT_SETUP_MANAGE_KEYWORD_THEMING_IN_PROGRESS: "Theming in progress. Please revisit in 24 hours to view themed list of keywords",
    PROJECT_SETUP_COULD_NOT_FIND_ANY_COMPETITOR_DOMAINS: "We could not find any Competitive domain with respect to your domain.",
    SERP_RESULT_DOMAIN_SEARCH_ERROR_TEXT: "Please enter a valid domain name",
    SERP_RESULT_DOMAIN_SEARCH_RANK_LABEL: "YOU ARE AT RANK",
    SERP_RESULT_DOMAIN_SEARCH_RANK_PLACEHOLDER: "URL entered by you is not ranked in Top 100",
    SERP_RESULT_DOMAIN_SEARCH_TITLE: "Find Ranking for your domain name",
    SERP_RESULT_META_INFO_CONTENT_NOT_AVAILABLE: "No Packs Available",
    SERP_RESULT_META_INFO_CONTENT_TITLE: "Search Volume",
    SERP_RESULT_META_INFO_CONTENT_TITLE2: "SERP Packs",
    SERP_RESULT_META_INFO_PREFIX: "RESULTS",

    SERP_RESULT_META_INFO_KEYWORD_PREFIX: "for Keyword",
    SERP_RESULT_META_INFO_URL_PREFIX: "& URL like",
    SERP_RESULT_SCORE_PROGRESS_DONE_SIM_BUTTON_LABEL: "Simulation",
    SERP_RESULT_SCORE_PROGRESS_DONE_KWA_BUTTON_LABEL: "Keyword Gap Analysis",
    SERP_RESULT_SCORE_PROGRESS_FAILED_BUTTON_LABEL: "Unable to get scores",
    SERP_RESULT_SCORE_PROGRESS_INITIAL_BUTTON_LABEL: "Calculating ALPS score",
    SERP_RESULT_SCORE_PROGRESS_SUB_TITLE:
        "Benchmark and compare keyword performance with competitor URLs\nOR\nUse the one-of-a-kind content simulator for faster content iterations and to assess rank improvement",
    SERP_RESULT_SCORE_PROGRESS_TITLE:
        "ALPS algorithm analyzes 80+ factors to score competitor and your URLs on their content, authority and technical performance",
    FETCH_RELATED_KEYWORDS_NOTIFICATION_MESSAGE:
        "As there is change in url or  locale click on the “Refresh keywords” to get the updated related Keywords.",
    SERP_RESULT_STATUS_SECTION_BUTTON_LABEL: "Go",
    SERP_RESULT_STATUS_SECTION_LABEL: "Get a detailed analysis for this keyword",
    SERP_RESULT_URL_RANK_LIST_HEADER_COL1_LABEL: "TOP RANKING URLs",
    SERP_RESULT_URL_RANK_LIST_HEADER_COL2_LABEL: "TRAFFIC",
    SERP_RESULT_URL_RANK_LIST_HEADER_COL3_LABEL: "CTR",
    SERP_RESULT_URL_RANK_LIST_HEADER_COL4_LABEL: "CRAWL STATUS",
    SERP_RESULT_URL_RANK_LIST_PACK_ROW_TITLE: "PACKS",
    // RELATED_KW_SSE_FAILED_MESSAGE_LIVE: "No keyword suggestions found. Please click on the 'Refresh Keywords' button to try again.",
    CONTENT_SIMULATOR_1_5: "1-5",
    CONTENT_SIMULATOR_6_10: "6-10",
    CONTENT_SIMULATOR_CATEGORY_SCORES: "SCORES",
    CONTENT_SIMULATOR_CATEGORY_SCORES_AUTHORITY_SCORE: "AUTHORITY SCORE",
    CONTENT_SIMULATOR_CATEGORY_SCORES_CONTENT_SCORE: "CONTENT SCORE",
    CONTENT_SIMULATOR_CATEGORY_SCORES_TECHNICAL_SCORE: "TECHNICAL SCORE",

    CONTENT_SIMULATOR_COMPETITOR_ELEMENT_BODY_CONTENT_SCORE: "Body Content Score",

    SIMULATOR_COMPETITOR_ELEMENT_H1_SCORE: "H1 Score",
    SIMULATOR_COMPETITOR_ELEMENT_H2_SCORE: "H2 Score",
    SIMULATOR_COMPETITOR_ELEMENT_H3_SCORE: "H3 Score",
    SIMULATOR_COMPETITOR_ELEMENT_META_DESCRIPTION_SCORE: "Meta Description Score",
    SIMULATOR_COMPETITOR_ELEMENT_TITLE_SCORE: "Title Score",
    SIMULATOR_COMPETITOR_ELEMENT_URL_SCORE: "URL Score",
    SIMULATOR_COMPETITOR_ELEMENT_VISIBLE_CONTENT_SCORE: "Body Content Score",

    CONTENT_SIMULATOR_COMPETITOR_ELEMENT_TITLE_H1_SCORE: "H1",
    CONTENT_SIMULATOR_COMPETITOR_ELEMENT_TITLE_H2_SCORE: "H2",
    CONTENT_SIMULATOR_COMPETITOR_ELEMENT_TITLE_H3_SCORE: "H3",
    CONTENT_SIMULATOR_COMPETITOR_ELEMENT_TITLE_META_DESCRIPTION_SCORE: "Meta Description",
    CONTENT_SIMULATOR_COMPETITOR_ELEMENT_TITLE_TITLE_SCORE: "Title",

    COMPETITOR_SCORES_HEADER_CONTENT_SCORE_LABEL: "Content Score",
    COMPETITOR_SCORES_HEADER_H1_SCORE_LABEL: "H1 Score",
    COMPETITOR_SCORES_HEADER_H3_SCORE_LABEL: "H3 Score",
    COMPETITOR_SCORES_HEADER_READABILITY_SCORE_LABEL: "Readability Score",
    COMPETITOR_SCORES_HEADER_META_DESCRIPTION_SCORE_LABEL: "Meta Description Score",
    COMPETITOR_SCORES_HEADER_URL_LABEL: "URL",

    CONTENT_SIMULATOR_EDITOR_ELEMENT_BODY_CONTENT: "Body Content",
    CONTENT_SIMULATOR_EDITOR_ELEMENT_URL: "URL",
    CONTENT_SIMULATOR_EDITOR_RUN_SIMULATION: "Run Simulation",
    CONTENT_SIMULATOR_EDITOR_TITLE: "Simulator",
    CONTENT_SIMULATOR_VIEW_ORIGINAL_ACTION_LABEL: "View Original",
    CONTENT_SIMULATION_ON_SCORE_COMPELTED: "Simulation Complete.",
    CONTENT_SIMULATION_REFRESH: "Content Simulation Refresh",
    CONTENT_SIMULATION_REFRESH_CONTENT_LABEL: "Get Live Content",

    VIEW_ORIGINAL_URL: "VIEW ORIGINAL",
    IMPACTED_KEYWORDS_OVERALL_SIMULATOR_KEYWORD: "Keyword",
    IMPACTED_KEYWORDS_OVERALL_SIMULATOR_CONTENT_SCORE: "Content Score",
    IMPACTED_KEYWORDS_OVERALL_SIMULATOR_AUTHORITY_SCORE: "Authority Score",
    IMPACTED_KEYWORDS_OVERALL_SIMULATOR_TECHNICAL_SCORE: "Technical Score",
    IMPACTED_KEYWORDS_OVERALL_SIMULATOR_SEARCH_VOLUME: "Search Volume",
    IMPACTED_KEYWORDS_OVERALL_SIMULATOR_RANK: "Rank",
    IMPACTED_KEYWORDS_OVERALL_SIMULATOR_TRAFFIC: "Traffic",
    IMPACTED_KEYWORDS_OVERALL_SIMULATOR_VISIBLE_CONTENT_SCORE: "Body Content",
    IMPACTED_KEYWORDS_OVERALL_SIMULATOR_READABILITY_GRADE: "Readability",
    CONTENT_SIMULATION_ON_PERFORMANCE_COMPELTED: "Simulation Complete",
    CONTENT_SIMULATION_ON_NO_CHANGE_PERFORMANCE_COMPELTED: "You have not made any changes to the parameters. The scores remain unchanged.",
    CONTENT_SIMULATOR_GT10: ">10",
    CONTENT_SIMULATOR_HIGH: "High",
    CONTENT_SIMULATOR_KEYWORD_IMPACT_RANK_LABEL: "Rank",
    CONTENT_SIMULATOR_KEYWORD_IMPACT_TITLE: "keyword impact",
    CONTENT_SIMULATOR_SEARCH_VOLUME: "Search Volume",
    CONTENT_SIMULATOR_RELEVANCE_SCORE: "Relevance Score",

    CONTENT_SIMULATOR_KEYWORD_IMPACT_PHRASE_COUNT: "PHRASE MATCH COUNT",

    CONTENT_SIMULATOR_KEYWORD_IMPACT_COMMON_WORD_COUNT: "COMMON WORDS COUNT",
    SIMULATOR_META_INFO_PREFIX: "SIMULATOR For URL",

    SIMULATOR_PAGE_TITLE: "Simulator",
    CONTENT_SIMULATOR_TITLE_SCORE_IS_PRESENT: "PRESENT",
    CONTENT_SIMULATOR_TITLE_SCORE_LENGTH: "NO. OF CHARACTERS",
    CONTENT_SIMULATOR_TITLE_SCORE_SCORES_BUCKET: "KEYWORD SCORE DISTRIBUTION",
    CONTENT_SIMULATOR_TITLE_SCORE_WORD_FREQUENCY_DISTRIBUTION: "WORD FREQUENCY",

    CONTENT_SIMULATOR_TITLE_SCORE_PHRASE_FREQUENCY_DISTRIBUTION: "PHRASE MATCHES",

    CONTENT_SIMULATOR_TITLE_SCORE_WORD_COUNT: "WORD COUNT",

    CONTENT_SIMULATOR_H1_SCORE_COUNT: "COUNT",
    CONTENT_SIMULATOR_H1_SCORE_IS_PRESENT: "PRESENT",
    CONTENT_SIMULATOR_H1_SCORE_LENGTH: "NO. OF CHARACTERS",
    CONTENT_SIMULATOR_H1_SCORE_SCORES_BUCKET: "KEYWORD SCORE DISTRIBUTION",
    CONTENT_SIMULATOR_H1_SCORE_WORD_FREQUENCY_DISTRIBUTION: "WORD FREQUENCY",

    CONTENT_SIMULATOR_H1_SCORE_PHRASE_FREQUENCY_DISTRIBUTION: "PHRASE MATCHES",

    CONTENT_SIMULATOR_H1_SCORE_WORD_COUNT: "WORD COUNT",

    CONTENT_SIMULATOR_H2_SCORE_COUNT: "COUNT",
    CONTENT_SIMULATOR_H2_SCORE_IS_PRESENT: "PRESENT",
    CONTENT_SIMULATOR_H2_SCORE_LENGTH: "NO. OF CHARACTERS",
    CONTENT_SIMULATOR_H2_SCORE_SCORES_BUCKET: "KEYWORD SCORE DISTRIBUTION",
    CONTENT_SIMULATOR_H2_SCORE_WORD_COUNT: "WORD COUNT",
    CONTENT_SIMULATOR_H2_SCORE_WORD_FREQUENCY_DISTRIBUTION: "WORD FREQUENCY",

    CONTENT_SIMULATOR_H2_SCORE_PHRASE_FREQUENCY_DISTRIBUTION: "PHRASE MATCHES",

    CONTENT_SIMULATOR_META_DESCRIPTION_SCORE_SCORES_BUCKET: "KEYWORD SCORE DISTRIBUTION",
    CONTENT_SIMULATOR_META_DESCRIPTION_SCORE_WORD_FREQUENCY_DISTRIBUTION: "WORD FREQUENCY",

    CONTENT_SIMULATOR_META_DESCRIPTION_SCORE_PHRASE_FREQUENCY_DISTRIBUTION: "PHRASE MATCHES",

    CONTENT_SIMULATOR_META_DESCRIPTION_SCORE_IS_PRESENT: "PRESENT",
    CONTENT_SIMULATOR_META_DESCRIPTION_SCORE_LENGTH: "NO. OF CHARACTERS",
    CONTENT_SIMULATOR_META_DESCRIPTION_SCORE_WORD_COUNT: "WORD COUNT",
    CONTENT_SIMULATOR_URL_SCORE_SCORES_BUCKET: "KEYWORD SCORE DISTRIBUTION",
    CONTENT_SIMULATOR_VISIBLE_CONTENT_SCORE_IS_PRESENT: "PRESENT",
    CONTENT_SIMULATOR_VISIBLE_CONTENT_SCORE_LENGTH: "NO. OF CHARACTERS",
    CONTENT_SIMULATOR_VISIBLE_CONTENT_SCORE_SCORES_BUCKET: "KEYWORD SCORE DISTRIBUTION",
    CONTENT_SIMULATOR_VISIBLE_CONTENT_SCORE_WORD_COUNT: "WORD COUNT",
    CONTENT_SIMULATOR_VISIBLE_CONTENT_SCORE_WORD_FREQUENCY_DISTRIBUTION: "WORD FREQUENCY",

    CONTENT_SIMULATOR_VISIBLE_CONTENT_SCORE_PHRASE_FREQUENCY_DISTRIBUTION: "PHRASE MATCHES",

    CONTENT_SIMULATOR_LOW: "Low",
    CONTENT_SIMULATOR_MEDIUM: "Medium",

    CONTENT_SIMULATOR_META_DESCRIPTION_SCORE: "META DESCRIPTION",
    CONTENT_SIMULATOR_ORGANIC_RANK: "RANK",
    CONTENT_SIMULATOR_ORGANIC_RANK_ORGANIC_RANK_BUCKET: "RANK DISTRIBUTION",
    CONTENT_SIMULATOR_TECHNICAL_SCORE: "TECHNICAL SCORE",
    CONTENT_SIMULATOR_TITLE_SCORE: "TITLE",
    CONTENT_SIMULATOR_URL_SCORE: "URL",
    CONTENT_SIMULATOR_VISIBLE_CONTENT_SCORE: "BODY CONTENT",
    CONTENT_SIMULATOR_READABILITY_GRADE: "READABILITY",

    CONTENT_SIMULATOR_COMPETITOR_AVG_COMPETITORS_SCORE_TITLE: "AVG. COMPETITOR SCORE (TOP 10)",
    CONTENT_SIMULATOR_COMPETITOR_COMPETITORS_LINK_TITLE: "Show Competitors' Scores",
    CONTENT_SIMULATOR_COMPETITOR_COMPETITORS_MODAL_TITLE: "Competitors'",
    CONTENT_SIMULATOR_SHOW_ALL_SCORES_LINK: "Show All On-Page Scores",
    CONTENT_SIMULATOR_SHOW_ALL_SCORES_MODAL_TITLE: "All On-Page Element Scores",


    OVERALL_SIMULATOR_RESET_CATEGORY: "Will Reset {CATEGORY}",
    COMPETITIVE_ANALYSIS_LOADING_MESSAGE: "Competitive Analysis should be available once the processing is complete.",
    COMPETITIVE_ANALYSIS_PROCESSING_TITLE: "Competitive Analysis processing",

    CONTENT_SIMULATOR_FETCH_CONTENT_FAILED_WARNING:
        "We could not fetch content for this page accurately. Hence baseline content scores could be inaccurate. Please copy and paste the content from the page and proceed with optimization.",

    CONTENT_SIMULATION_PARTIAL_CONTENT_FETCH_NOTIFICATION: "Page content was processed partially. Unable to load some page elements",
    CONTENT_SIMULATION_FETCH_FAIL: "Unable to process the page content completely",

    OVERALL_SIMULAITON_VALIDATION_BACK_LINK_COUNT: "This value impacts certain other parameters. Please review before the simulation",
    OVERALL_SIMULAITON_VALIDATION_CAN_NOT_EXCEED: "{param_1} can't exceed {param_2}",
    OVERALL_SIMULAITON_VALIDATION_SUM_CAN_NOT_EXCEED: "The sum of {param_1} and {param_2} can't exceed {param_3}",
    OVERALL_SIMULATION_VALIDATION_CAN_NOT_BE_LESS: "{param_1} can't be less than {param_2}",
    OVERALL_SIMULATION_VALIDATION_SUM_CAN_NOT_EXCEED_2: "{param_1} can't be less than the sum of {param_2} and {param_3}",
    OVERALL_SIMULATION_VALIDATION_TEXT_CAN_NOT_EXCEED: "can't exceed",
    OVERALL_SIMULATION_VALIDATION_TEXT_TEXTUAL: "textual",
    MULTI_KEYWORD_KEYWORD_FETCH_LOADER_TITLE: "Fetching data",
    MULTI_KEYWORD_KEYWORD_FETCH_LOADER_MESSAGE: "Keywords Processed",
    MULTI_KEYWORD_KEYWORD_FETCH_NOTIFICATION_BAR_1: "Partial data available.",
    MULTI_KEYWORD_KEYWORD_FETCH_NOTIFICATION_BAR_2: "keywords still processing...",
    MULTI_KEYWORD_NOTIFICATION_WHEN_ALL_KW_PROCESSED_FAILED_PROCESSED: "keywords processed",
    MULTI_KEYWORD_NOTIFICATION_WHEN_ALL_KW_PROCESSED_FAILED: "failed",
    MULTI_KEYWORD_ALERT_DUPLICATE_KEYWORD: "One or more keyword(s) is already present",
    MULTI_KEYWORD_ALERT_INVALID_INPUT: "Please enter the valid keyword",
    MULTI_KEYWORD_ALERT_MAX_KEYWORDS: "Unable to add keyword as the limit is {{MAX}} keyword. Please try again by reducing the keyword(s)",
    MULTI_KEYWORD_MAX_KEYWORDS_WARNING:
        "You can add only up to {{MAX}} keywords for simulation. Please try again after deselecting some keywords.",
    MULTI_KEYWORD_BODY_INPUT_SHOW_ALL: "Show all",
    MULTI_KEYWORD_BODY_INPUT_PLACEHOLDER: "Enter keyword (separated by comma)",
    MULTI_KEYWORD_BODY_INPUT_TITLE: "Add Keyword",
    MULTI_KEYWORD_BODY_URL_DROPDOWN_PLACEHOLDER: "Select",
    DOD_FORM_SELECT_DATA_LABEL: "Select the data that you need",
    MULTI_KEYWORD_TITLE_BUTTON_LABEL: "Proceed to Simulation",
    MULTI_KEYWORD_LOCALE_LABEL: "Locale",
    MULTI_KEYWORD_LOCALE_DROPDOWN_PLACEHOLDER: "Enter language/country",
    MULTI_KEYWORD_NOTIFICATION_MESSAGE: "{{COUNT}} keywords have been added to the Simulation with their original scores.",
    MULTI_KEYWORD_NOTIFICATION_MESSAGE_POSTFIX: " Any parameter changes that you have made are retained.",
    MULTI_KEYWORD_NOTIFICATION_KEYWORD_FAILED_MESSAGE: "Unable to simulate scores for {{FAILED}} / {{TOTAL}} keywords. Please try again.",
    MULTI_KEYWORD_LOCALE_CHANGE_MESSAGE: " Locale has been updated for the simulation",
    MULTI_KEYWORD_FILE_UPLOAD_PLACEHOLER: "Select a *.html file upto 500KB",
    NO_RESULT_FOR_APPLIED_FILTER: "No results are available for the applied filters/search.",
    OVERALL_SIMULATION_VIEW_SIMULATION_LINK: " View the impact of simulation on scores and performance of each keyword.",

    FOOTER_COPY_RIGHT_TEXT: `${new Date().getFullYear()} iQuanti Pvt. Ltd`,
    FOOTER_PRIVACY_POLICY: "Privacy Policy",
    FOOTER_TERMS_AND_CONDITIONS: "General Terms of Use",
    KWA_COMPETITOR_URL: "Competitor URL",
    KWA_FILTER_CLICK: "Filter",
    KWA_KEYWORD_PERFORMANCE: "Keyword Performance",
    KWA_PARAMETER_CLICK: "parameter selected",
    KWA_REPORT_DOWNLOAD: "Report-Download",
    KWA_SERP_PACKS: "SERP Packs",
    KWA_SORT: "Sort",
    KWA_TAB_CLICK: "Tab click",
    KWA_TARGET_URL: "Target URL",
    KWA_VIEW_ALPS_SCORE: "View ALPS Score for competitors",
    KWA_LEFT_NAV_MONTHLY_SEARCH_VOLUME_LABEL: "SEARCH VOLUME",
    KWA_LEFT_NAV_HIGHEST_RANK_LABEL: "HIGHEST RANK",
    KWA_LEFT_NAV_TOP_RANKING_URL: "TOP RANKING URL",
    KWA_LEFT_NAV_KEYWORD_PERFORMANCE: "KEYWORD PERFORMANCE",
    KWA_LEFT_NAV_SERP_PACKS: "SERP PACKS",
    KWA_LEFT_NAV_KEYWORD_PERFORMANCE_TRAFFIC: "TRAFFIC",
    KWA_LEFT_NAV_KEYWORD_PERFORMANCE_CTR: "CTR",
    KW_TOTAL_SEARCH_VOLUME_DESCRIPTION: "Sum of average monthly search volumes for the keyword list.",
    KW_TOTAL_KEYWORD_COUNT_DESCRIPTION: "Total number of keywords listed in the table below.",

    MODAL_KWA_VIEW_ALPS_SCORE_TITLE: "Top 10 Competitor Scores",
    SIMULATE_PERFORMANCE: "Simulate Performance",
    PERFORMANCE: "PERFORMANCE",
    SIMULATE_PERFORMANCE_RANK: "RANK",
    OR: "OR",
    SEARCH_PLACEHOLDER: "Search",
    PROTOCOL_OPTIONSID: "protocol",
    DOMAIN_OPTIONSID: "domain",
    ALERT_OK_LABEL: "OK",
    ALERT_DELETE_LABEL: "Delete",
    ALERT_CANCEL_LABEL: "Cancel",
    ALERT_DELETE_CONFIRMATION_TITLE: "Are you sure you want to delete ",
    SELECT_ALL: "Select All",
    SELECT_THEME_PLACEHOLDER: "Theme",
    SELECT_KEYWORDS_TYPE_PLACEHOLDER: "Keyword Type",
    ALL_FILTERS_LABEL: "FILTERS",
    MKW_TARGETED_KEYWORD_LABEL: "Targeted keywords",
    MKW_RANKING_KEYWORD_LABEL: "Ranking keywords",
    NO_KEYWORD_FOUND: "No Keywords Found",
    KEYWORDS_LOADING_MSG: "Loading keywords. Please wait.",
    ADD_KEYWORD: "Add Keyword",
    ADD_PROJECT: "Add Project",
    REMOVE_KEYWORD: "Remove Keyword",
    DEFINITION: "Definition:",
    CORRELATION_TEXT: " Lower the value of the parameter, more optimized it is",
    FILTER_IMPORTANCE: "Importance",
    PARAMETER_GROUPS: "Parameter Groups",
    VIEW_TRAFFIC_RANK: "View Traffic & Rank",
    VIEW_TRAFFIC_RANK_MESSAGE:
        "View the predicted change in traffic and avg. rank after the simulation. Post simulation, this section will be enabled once the data for all keywords is available.",
    VIEW_SCORE_EXPANDED_MESSAGE:
        "These metrics represent the average score of all keywords added to the simulation, weighted by the search volume of each keyword.",
    SIMULATE_WAIT: "Wait",
    SIMULATE_CONTINUE: "Continue",
    SIMULATOR_ALERT_MESSAGE_FOR_ALL_PROCESSED_KEYWORD:
        "Data for all keywords is available. Simulation will show changes for all the keywords",
    STILL_PROCESSING_TEXT: "This data is still being processed",
    GO_TO_SIMULATION: "Go To Simulation",
    KEYWORD_IS_REQUIRED_TO_PROCEED: " Input keyword(s) to view data.",
    PAGE_OPTIMIZER_PAGE_TITLE: "Page Optimizer",
    PAGE_OPTIMIZER_KW_SEARCHBOX_TITLE: "Enter a Locale and URL",
    PAGE_OPTIMIZER_KW_INPUT_TITLE: "Enter Keyword(s)",
    PAGE_OPTIMIZER_KWs_INPUT_PLACEHOLDER: "Enter up to {{KEYWORD_COUNT}} Keywords separated by a comma.",
    PAGE_OPTIMIZER_LOCALE_DROPDOWN_TITLE: "Locale",
    PAGE_OPTIMIZER_URL_SEARCHBOX_TITLE: "Enter the URL",
    PAGE_OPTIMIZER_KW_SEARCHBOX_SUBMIT_BUTTON_LABEL: "I don’t have a live page/URL",
    SUGGESTED_URL_REQUIRED_TO_RUN_SIMULATION: "* A URL (Suggested) is required to run simulation",
    WANT_DIRECTLY_INPUT_CONTENT: "I want to directly input content",
    //  MULTI_KEYWORD_URL_SEARCH_ERROR_TEXT: "Please enter a valid URL"
    MULTI_KEYWORD_URL_SEARCH_ERROR_TEXT: "Please enter a valid URL to proceed",
    MULTI_KEYWORD_KEYWORD_ERROR_TEXT: "Please enter keyword(s) to proceed",
    FILE_UPPLOAD_ERROR_TEXT: " Please select a file to upload",
    PERFORMANCE_COMPELTED: "Simulation scores are available, performance related metrics will take longer to load",
    REFRESHED_METRICS: "Refreshed metrics are now available for you",
    // MULTI_KEYWORD_URL_SEARCH_ERROR_TEXT: "Please enter a valid URL to proceed",
    RESET_PARAMETER: " Reseting will undo the changes done by you. Do you want to proceed ?",
    HTML_UPLOAD_UPTO_50: "(HTML format only upto 500 KB)",
    SUGGESTED_URL_TEXT: "URL (Suggested)",
    UPLOAD_FILE_TEXT: "Upload File",
    UPLOAD_TEXT: "Upload",
    GENERIC_API_FAILURE_MSG:
        "Some of the data could not be fetched for this page. You can proceed with available data or click on proceed again to retry.",

    SEARCH_URL_ERROR_TEXT: "Please enter a valid URL (e.g. https://www.example.com)",
    SEARCH_URL_PLACEHOLDER_TEXT: "e.g. https://www.example.com",
    CONSIDER_URL:
        "We have considered the URL as {{url}}. If the actual URL has a different protocol, please update it in the 'URL  AND KEYWORDS' section on top of this page",
    SEARCH_URL_PLACEHOLDER_TEXT_PAGE_OPTIMIZER: "e.g. https://www.example.com/page1",
    SEARCH_URL_ERROR_TEXT_PAGE_OPTIMIZER: "Please enter a valid URL (e.g. https://www.example.com/page1)",
    FRAGMENTED_URL_ERROR_TEXT:
        "For the purpose of scoring and simulation, the system considers the URL without the # values when submitting",
    TRIMMED_ACTUAL_COMPARISION_TEXT: "The ranking URL is {{Original_URL}}. For the purpose of analysis, we will consider {{Trimmed_URL}}",
    SIMULATION_MAX_KEYWORD_LIMIT_EXCEEDED_WARNING:
        "Only the first {{KEYWORD_COUNT}} Keywords were added. Please modify keywords if necessary.",
    SEARCH_VOLUME_INFO_TEXT:
        "Keyword Planner is used to fetch default Monthly Search Volume. If the data is unavailable, we use alternate sources.",
    SIMULATOR_EDITOR_INSTRUCTIONS_TITLE: "How the editor works",
    LAST_CRAWL_DATE: "Last crawl date - ",
    SIMULATOR_VERSION_CHANGE_CONSENT_MESSAGE: "You will lose any pending/un-saved edits. Do you wish to proceed?",
    TECHNICAL_PARAMS_FETCHNIG: "Processing Technical parameters…",
    SAVE_SIMULATION_ACTION: "You are about to close/refresh the tab. Do you want to save the current simulation",
    LEAVE_PAGE_TEXT: "You will lose any pending/un-saved edits. Do you wish to proceed?",
    REFRESH_DIRTY_CONSENT: "There are some changes in the page since the last simulation. Do you want to fetch fresh data before editing?",
    REFRESH_DIRTY_CONSENT_CONTINUE: "Continue with this Version",
    REFRESH_DIRTY_CONSENT_FETCH_FRESH_DATA: "Fetch Fresh Data",
    REFRESH_NOTIFICATION_MESSAGE_1: "Data used for simulation could've changed since the last simulation. Please click on ",
    REFRESH_NOTIFICATION_MESSAGE_2: '"Get Live Content" to update the page content and underlying data.',
    REFRESH_NOTIFICATION_MESSAGE_3:
        "Since the last simulation, the system noticed some changes in page content or underlying data. Please click on ",
    REFRESH_NOTIFICATION_MESSAGE_4: ' Refresh" to update the same.',
    BROWSER_BACK: "Browser Back",
    ADD_AT_MAX_x_KEYWORD: "The keyword limit is {{KEYWORD_COUNT}}. Please modify keywords if necessary.",
    EXIT_FULL_SCREEN: "Exit Full Screen",
    DOWNLOAD_BUTTON: "Download Content",
    DOWNLOAD_BUTTON_DISABLED: "Preparing data for download. Please try after some time.",

    KW_RESEARCH_HOME_BANNER_TITLE1: "Discover thousands of",
    KW_RESEARCH_HOME_BANNER_TITLE_HIGHLIGHT1: "relevant keywords",
    KW_RESEARCH_HOME_BANNER_TITLE2: "with ALPS Keyword Research Tool",
    KW_RSEARCH_INPUT_PLACEHOLDER_TEXT: "Enter a Keyword or URL",
    KW_RESEARCH_INPUT_ERROR_TEXT: "Please enter a valid Keyword or URL",
    KEYWORD_RESEARCH_TEXT: "Keyword Research",
    ADD_TO_CATEGORY_TEXT: "Add to Category",
    ADD_KW_TO_PROJECT_BTN_LABEL: "Add Keyword to Project",
    SEARCH_CATEGORY_NAME: "Search Category Name",

    GO_TO_SIMULATION_CONSENT_MSG: "Page could not be crawled. Please get in touch with",
    FETCH_KEYWORDS_LABEL: "Fetch Keywords",
    REFRESH_KEYWORDS_LABEL: "Refresh Keywords",
    NONLIVE_PRESIM_LEFT_PANEL_MSG: "Overall content scores and element scores will be available once you run simulation",
    NONLIVE_PRESIM_SCORE_ACCODION_MSG: "Scores will be available once you run simulation",
    RELEVANCE_SCORE_ERROR_TEXT: "Unable to calculate relevance score for some/all keywords.",
    CONTENTBRIEF_KW_SEARCHBOX_TITLE: "Locale",
    SEARCH_TOPIC_PLACEHOLDER_TEXT_CONTENTBRIEF: "e.g. Improving credit score",
    CONTENTBRIEF_TOPIC_SEARCHBOX_TITLE: "Enter a Topic to Generate Content Brief",
    CONTENT_BRIEF_SEARCH_ERROR_TEXT: "Please enter topic",
    CONTENT_BRIEF_TABLE_HEADING: "SAVED CONTENT BRIEFS",
    CONTENT_BRIEF_BANNER_HEADING: "Create a comprehensive content brief",
    GENERATE_CONTENT_BUTTON_TITLE: "Generate Content Brief",
    CONTENT_BRIEF_CANCEL: "Cancel",
    PAGE_CONTENTBIREF_PAGE_TITLE: "Page ContentBrief",
    CONTENT_BRIEF_MAX_LENGTH_HINT: "* Add only upto 20 keywords",
    CONTENT_BRIEF_SELECTED_KEYWORD_LABEL: "Add/Modify Keywords",
    CONTENT_BRIEF_NO_KEYWORD_LABEL: "No Keywords",
    NO_CONTENT_BRIEF_FOUND: "No content brief found",
    TOPIC_MAX_CHARACTERS: "Unable to add character as the limit is {{MAX}} characters.",
    KEYWORD_MAX_CHARACTERS: "Unable to add keyword character as the limit is {{MAX}} characters.",
    CONTENT_BRIEF_NAME_UNTITLED: "Untitled",
    STATUS_PROCESSED: "Processed",
    CONTENT_BRIEF_NAME_TEXT: "Content brief name",
    ADD_CONTENT_BRIEF_NAME: "Add content brief name",
    DESCRIPTION: "Description",
    SUMMARY_TITLE: "Summary",
    SEARCH_VOLUME: "Avg. Search Volume",
    ARTICLE_LENGTH: "Avg. Article Length",
    CONTENT_SCORE: "Avg. Content Score",
    SERP_ELEMENTS: "SERP Elements",
    MODIFY_KEYWORDS_TEXT: "Modify Keyword",
    KEYWORDS_TEXT: "Keywords",
    RELEVANCE_SCORE: "Relevance Score",
    LENGTH_LABEL: "Length",
    READABILITY_LABEL: "Readability",
    NAME_LABEL: "name",
    TOP_URL_API_TYPE: "top_urls",
    QUESTIONS_LABEL: "Questions",
    HEADLINES_LABEL: "Optimized H1 Tags from High Ranking Pages",
    H2_LABEL: "Optimized H2 Tags from High Ranking Pages",
    TITLES_LABEL: "Optimized Title Tags from High Ranking Pages",
    META_DESCRIPTIONS_API_TYPE: "meta_descriptions",
    SAVE_LABEL: "Save",
    ADD_LABEL: "Add",
    NOT_FOUND_MESSAGE: "No {{type}} Found",
    CREATED_BY_LABEL: "Created By",
    STATUS_LABEL: "Status",
    COMPLETED_LABEL: "Completed",
    CB_NAME_AND_DESCRIPTION_ERROR_TEXT: "Please enter a valid {{TYPE}}",
    CB_FAILED_DESCRIPTION: "Content brief failed",
    CB_TABLE_SEARCH_PLACEHOLDER: "Search by Name or Topic",
    TOPIC_LABEL: "Topic",
    AVG_SEARCH_VOLUME_DESCRIPTION: "Total average monthly search volume of all the selected keywords.",
    AVG_ARTICLE_LENGTH_DESCRIPTION: "The average length of articles ranking high on SERP for the targeted set of keywords.",
    AVG_CONTENT_SCORE_DESCRIPTION:
        "Content score, is proprietary ALPS score which reflects the content relevancy with respect to the targeted keywords.",
    SERP_ELEMENT_DESCRIPTION: "List of SERP packs that appear for the targeted set of keywords.",
    KEYWORD_DESCRIPTION: `Keywords are the building blocks of online content. They are utilized by search engines to identify subject matter and channel search results to the end user. This brief is generated for the following keywords.`,
    COMPETITOR_URL_DESCRIPTION:
        "Use these page references as a guide. Pages that rank high on SERP for multiple targeted keywords tend to have higher content scores. The length of the content and readability grade gives a perspective on the writing style required for the topic.",
    QUESTIONS_DESCRIPTION:
        "Good SEO optimized content should include answers to some related questions so that users don’t have to navigate to other articles. Including answers to the following questions in your content will ensure comprehensive coverage of the topic for targeted keywords.",
    HEADLINES_DESCRIPTION:
        " The <H1> tag is used to introduce the core topic that is covered on the page. In the table below, you’ll find a list of H1 tags that rank high on SERP for the targeted set of keywords.",
    H2_DESCRIPTION:
        "The <H2> tags are typically used to introduce important subtopics. These tags help search engines identify the hierarchy and structure of the page. In the table below, you’ll find a list of H2 tags that rank high on SERP for the targeted set of keywords.",
    TITLES_DESCRIPTION:
        "Title tags inform the search engines what the webpage is about. In the table below, you’ll find a list of titles that rank high on SERPs for the targeted set of keywords.",
    META_DESCRIPTION_DETAILS:
        "Meta Descriptions briefly summarize what readers can expect to see on the webpage. The table below contains a list of meta descriptions from pages ranking high on SERP for the targeted set of keywords.",
    SHOW_ALL_LABEL: "Show All",
    SHOW_LESS_LABEL: "Show Less",
    ADD_KEYWORD_DESCRIPTION_TEXT: "Add/select a coherent set of keywords to generate content brief*",
    ADD_KEYWORD_LABEL: "Add Keyword",
    CB_ADD_KEYWORD_PLACEHOLDER: "Add keyword (separate by comma)",
    NO_SUGGESTED_KEYWORD_NOTIFICATION:
        "No keyword suggestions found for the given topic/keyword. Please retry after adding another keyword.",
    SEARCH_LABEL: "Search",
    SUGGESTED_KW_SEARCH_PLACEHOLDER: "e.g. credit score",
    SUGGESTED_KW_SEARCH_ERROR_TEXT: "Please enter topic/keyword",
    PHRASES_TO_USE_DESCRIPTION:
        "There are relevant phrases for every topic that boost content relevance and contribute to building authority. Use these phrases optimally in meta data, headlines and body content whenever possible. Certain n-gram also occur frequently on competitor pages that rank high for selected keywords. We’ve provided a list of those below, with their frequency across all the competitor pages.",
    DOWNLOAD_BRIEF_TEXT: "Download Brief",
    PHRASES_TO_USE_LABEL: "Phrases to Use",
    UNIGRAM_WORDS: "Unigrams",
    FREQUENCY: "Frequency",
    BIGRAM_WORDS: "Bigrams",
    TRIGRAM_WORDS: "Trigrams",
    RECOMMENDED_PHRASES: "RECOMMENDED PHRASES",
    WORDS_AND_PHRASES_FROM_SELECTED_KEYWORDS: "FREQUENTLY USED N-GRAM FROM COMPETITOR PAGES",
    PHRASES_URL: "phrases",
    UNIGRAM_URL: "phrases/unigrams",
    BIGRAM_URL: "phrases/bigrams",
    TRIGRAM_URL: "phrases/trigrams",
    GENERATE_BRIEF_MODAL_HEADER: `Generating content brief for selected keywords. You could continue with your work.
        The brief will be available on Content Brief Page in a few minutes.`,
    PROCESSING_LABEL: "Processing",
    CONTENT_BRIEF_GENERATED_SUCCESS_LABEL: "Successfully generated content brief for selected Keywords.",
    VIEW_LABEL: "View",
    DOWNLOAD_BRIEF_LABEL: "Download",
    BRIEF_FAILED_MESSAGE: "Brief could not be generated for the given keyword.",
    BRIEF_FAILED_MESSAGE_INFO: "Please add 2 or 3 more keywords and try again.",
    KEYWORD_LABEL: "Keyword",
    CHARACTERS_LENGTH_LABEL: "You can add only upto {{LENGTH}} characters.",
    ENTER_KEYWORD_TEXT: "Enter keyword",
    APPLY_TEXT: "Apply",
    TOTAL_SEARCH_VOLUME_LABEL: "Total Search Volume",
    TOTAL_KEYWORD_COUNT_LABEL: "Total Keyword Count",
    RELATED_KEYWORDS_LABEL: "Related Keywords",
    NO_RELATED_KEYWORD_NOTIFICATION:
        "No keyword suggestions found for the given Keyword/URL. Please retry after adding another Keyword/URL.",
    KEYWORD_RESEARCH_REPORT_LABEL: "Keyword_Research_",
    COMPETITOR_PAGE: "Competitor Page",
    READABILITY_GRADE_LABEL: "Readability Grade",
    READABILITY_GRADE_TOOLTIP: "Flesch Kincaid Readability Grade",
    PHRASES_ACCORDION_TOOLTIP: "Total number of times the n-gram occurs on competitor pages.",
    CB_SSE_FAILED_MESSAGE:
        "We could not get any keyword suggestions for the given topic. Please contact alps@iquanti.com or enter keywords manually in the Input Keywords tab.",
    META_DESCRIPTION_TITLE: "Optimized Meta Description Tags from High Ranking Pages",
    CONTENT_BRIEF_KEYWORD_ALERT_MAX_KEYWORDS: "Unable to add keyword. You can only add upto {{MAX}} keywords.",
    RETRY_LABEL: "Retry",
    CB_API_HEADLINES: "Headlines",
    CB_API_H2: "H2",
    CB_API_TITLES: "Titles",
    CB_RETRY_POPUP_TEXT: "We could not generate brief for the given keywords.",
    CB_RETRY_POPUP_TEXT_INFO: "Please add a few more keywords and regenerate the brief",
    INCLUDE_KEYWORD_LABEL: "Include Keyword",
    EXCLUDE_KEYWORD_LABEL: "Exclude Keyword",
    CLEAR_ALL_BUTTON_LINK: "Clear all",
    CONTENT_SIMULATOR_VIEW_KEYWORD_IMPACT: "Competitive Analysis",
    CONTENT_SIMULATOR_KEYWORD_DETAILS: "Add / Modify Keywords",
    BRIEF_BUTTON_TEXT: "Brief",
    PRIMARY_TOPIC_LABEL: "Primary Topic",
    CB_NTS_KEYWORD_PLACEHOLDER: "Showing keywords for (latest) topic",
    CONTENTBRIEF_PRIMARY_TOPIC_SEARCHBOX_TITLE: "Enter a Primary Topic to Generate Content Brief",
    INCLUDE_LABEL: "Include",
    EXCLUDE_LABEL: "Exclude",
    NOTIFICATION_LABEL: "Notification",
    CLEAR_ALL: "Clear All",
    PROCEED_TO_EDITOR_MODE_LABEL: "Proceed to editor mode",
    PROCEED_TO_BROSWER_MODE_LABEL: "Proceed to browser mode",
    DOWNLOAD_SIMULATED_DATA: "Download Simulated Data",
    CLEAR_BUTTON_LINK: "Clear",
    RANKING_URL_LABEL: "Ranking URL",
    KRT_SEARCH_LABEL: "Keyword / URL",
    URL_INPUT_HINT_TEXT: "* Add only upto {{MAX}} URL",
    URL_NOT_FOUND_ERROR: "URL not found.",
    URL_ALREADY_EXIST_ERROR: "URL already exists.",
    MAX_URL_LIMIT_ERROR: "Unable to add URL. You can only add upto {{MAX}} URL.",
    LOADING_LABEL: "Loading Please Wait...",
    REQUEST_DEMO_POPUP_TITLE: "Unlock the complete ALPS platform",
    REQUEST_DEMO_TEXT: "Request Demo",
    REQUEST_DEMO_DESCRIPTION1: "Remove guesswork from SEO with predictive software.",
    REQUEST_DEMO_DESCRIPTION2: "Get actionable SEO recommendations based on 100+ ranking variables.",
    REQUEST_DEMO_DESCRIPTION3: "Predict the rank impact of every content change before you publish!",
    TRIAL_EXPIRED: "Your trial has expired",
    CONTACT_US: "Contact Us",
    EXPIRED_MESSAGE: "To view a full product walkthrough please contact us.",
    META_ELEMENT_TITLE: "Meta Title",
    META_ELEMENT_RECOMMENDATION: "Recommendation",
    HEADING_OPTIMIZATION_STATUS: "Optimization Status",
    SELECT_MODE: "Select Mode",
    SELECT_MODE_BROWSER: "Browser",
    SELECT_MODE_EDIT: "Select to Edit",
    SELECT_MODE_META_ELEMENTS: "Meta Elements",
    SELECT_MODE_HIGHLIGHT_COLORS: "Highlight Colors",
    SCORE_BOARD: "ScoreBoard",
    MODE_SWITCH_MESSAGE: "Changes made will not be available on switching modes. Do you want to proceed to the other mode?",
    NO_TAG_FOUND_MESSAGE_PART1: "There are no ",
    NO_TAG_FOUND_MESSAGE_PART2: " in the article.",
    PAGE_NOT_LOADED_ERROR: "Page could not be loaded in the browser mode. You could try editor mode or get in touch with alps@iquanti.com",
    PAGE_CONTENT_NOT_LOADED: "Page content not loaded",
    CLOSE_BUTTON: "Close",
    SCOREBOARD_INFO_MESSAGE: "Hold the header and drag to move the scoreboard across the screen",
    // Excel Template Constant
    DATE: "Date",
    USER: "User ID",
    HEADER_CONTENT_SCORE: "Content Score",
    EXCEL_KEYWORD: "Keywords",
    PERFORMANCE_INDICATORS: "Performance Indicators",
    PRESIMULATED_CONTENT: "Pre-Simulated Content",
    POST_SIMULATED_CONTENT: "Post Simulated Content",
    PARAMETERS: "Parameters",
    KEYWORD_STUFF_ERROR_ONE: "We notice unnatural use of words/phrases in ",
    KEYWORD_STUFF_ERROR_TWO: ". This can impact the accuracy of rank prediction.",
    KEYWORD_STUFF_NO_ERROR: "All params are good",
    KEYWORD_STUFFING_WARNING: "WARNING",
    WARNING: "WARNING",
    HIDDEN_TEXT: "hidden",
    AUTO_TEXT: "auto",
    DOWNLOADING: "Downloading",
    DOWNLOAD_MESSAGE: "Please wait as the data is getting processed",
    DOWNLOAD_FAILED_TITLE: "DOWNLOAD FAILED",
    DOWNLOAD_FAILED_TITLE_MESSAGE: "Download failed. Please try again.",

    ERROR_TEXT: "Error!",
    WARNING_TEXT: "Warning!",
    GSC_HEADING: "Google Search Console",
    GSC_SUB_HEADING:
        "Integrate your search console account (webmaster tools) with ALPS to analyse traffic that you receive from Google search.",
    SEARCH_DOMAIN_ERROR_TEXT: "Please enter a valid Domain (e.g. https://www.example.com)",
    KW_SEARCH_URL_ERROR_TEXT: "Please enter a valid URL (e.g. https://www.example.com/home)",
    ADD_KEYWORDS_FILE_UPLOAD_PLACEHOLDER: "File size upto 2MB",
    VR_H1_SCORES: "H1 Scores",
    VR_H1_H2_READABILITY_GRADES: "H1, H2 and readability grade should be updated to improve content relevance for this page.",
    VR_BY_CLICK_USE_THIS_TEXT: `By clicking on "use this" recommendation will automatically get added in the editor.`,
    VR_MORE_TOPICS: "+ More {{element}} Topics",
    VR_MORE_LIKE_THIS: "+ More Like This",
    VR_VIEW_URL: "View Url",
    VR_HIDE_URL: "Hide Url",
    VR_USE_THIS: "Use This",
    CURRENT_H1_SCORE: "Current H1 Score:",
    AVG_HIGH_RANKING_SCORE: "Avg High Ranking Score:",
    VR_AI_GENERATED_RECOMMENDATIONS: "AI-Generated Recommendations",
    VR_RECO_REFRESH_TEXT: "The keyword set has been updated since the last recommendation was generated. Click on ",
    ADD_KEYWORDS_XLSX_TEMPLATE_LINK: "Download XLSX Template",
    ADD_KEYWORDS_FROM_KEYWORD_RESEARCH: "Add keywords from Keyword Research Tool",
    ADD_KEYWORDS_BOX_WARNING: "Only 100 keywords are allowed at a time.",
    ADD_KEYWORDS_BOX_LABEL: "Add Manually",
    KW_LIMIT_REACHED_ERROR: "You have added {{keywords}} keywords. Only 100 keywords are allowed at a time.",
    COMPETITOR_LIMIT_REACHED_ERROR: "You have added {{competitor}} Competitor Domains. Only 20 are allowed at a time",
    ADD_KEYWORDS_TOP_TEXT: "You can add keywords Manually / from Keyword Research/ Upload XLSX Template",
    ADD_KEYWORDS_SUBMIT_SUCCESS_MSG: "Successfully added Keyword to Project!",
    ADD_KEYWORDS_TENANT_LIMIT_EXCEED_ERROR: "You have exceeded your limit of 2000 keywords across all projects.",
    KW_DESC:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation",
    BUTTON_LABEL_ADD: "Add",
    ENTER_CATEGORY: "Enter New Category",
    ADD_KEYWORDS_WRONG_FILE_UPLOAD_ERROR: "Please upload a valid .xls or .xlsx file.",
    ADD_KEYWORDS_FILE_SIZE_LIMIT_ERROR: "Please upload .xls or .xlsx file with size less than 2MB",
    KEYWORD_VIEW_TOGGLE_LABEL: "Keyword View",
    THEME_VIEW_TOGGLE_LABEL: "Theme View",
    ADD_NEW_CATEGORY_LINK: "+ Add Category Name",
    MK_FILTER_LABEL_TEXT: "Filter the list of keywords",
    TARGET_URL_SEARCH: "Search URL",
    TOPIC_SEARCH: "Search Topic",
    MK_NO_KEYWORD_FOUND_MSG: "No Keyword Found",
    KEYWORDS_ADDED_TO_SELECTED_CATEGORIES: "Keywords added to Selected Categories",
    KEYWORDS_NOT_ADDED_TO_SELECTED_CATEGORIES:
        "Could not add keywords to selected categories. Please try again or contact alps@iquanti.com",
    TARGET_URL_ASSIGNED_SUCCESS_MSG: "Target URL assigned to selected Keywords successfully",
    TARGET_URL_ASSIGNED_FAILED_MSG: "Could not assign Target URL to selected keywords. Please try again or contact alps@iquanti.com",
    KEYWORDS_ADDED_FOR_OPTIMIZATION_MSG: "Keywords successfully added for optimization",
    KEYWORDS_REMOVED_FROM_OPTIMIZATION_MSG: "Keywords successfully removed from optimization set",
    KEYWORDS_TYPE_OPTIMIZATION_FAILED_MSG:
        "Could not add/remove keywords for/from Optimization. Please try again or contact alps@iquanti.com",
    MKW_HEADING: "You can assign categories and target URLs to added keywords",
    KEYWORD_ADDED_TO_SELECTED_CATEGORIES_AND_OPTIMIZED: "Keyword have been added to selected Categories and marked Optimized",
    KEYWORDS_OPTIMIZED: " Keywords have been marked Optimized",
    KEYWORDS_NOT_OPTIMIZED: "Could not mark keywords as Optimized. Please try again or contact alps@iquanti.com",
    KEYWORDS_NOT_ADDED_TO_CATEGORIES_AND_OPTIMIZED:
        "Unable to add keywords to selected categories and mark them Optimized. Please try again or contact alps@iquanti.com",
    AVOID_LOSS_OF_DATA_MSG: "Click Save to avoid loss of data.",
    CLICK_UPLOAD_TO_AVOID_LOSS_OF_DATA_MSG: "Click Upload to avoid loss of data.",
    VR_REFRESH_RECOMMENDATION: "Refresh Recommendation",
    VR_RECOMMENDATION_REFRESH_TEXT: " to view the updated set of recommendations for the new keyword set.",
    DESCRIPTION_CONTAINER: "description-container",
    NO_MORE_ELEMENT_TEXT: "No more {{element}} topics left",
    RECOMMENDATION_NO_DATA_FOR_KEYWORD: "Unable to fetch {parameter} for this keyword.",
    DESCRIPTION_TEXT: "description",
    VR_SHOW_LESS: "- Show Less",
    RUN_SIMULATION_LOADER_TITLE: "Refreshing scores.",
    KEYWORD_RESEARCH_LOADER_TITLE: "Fetching keywords.",
    CONTENT_BRIEF_LOADER_TITLE: "Generating content brief for selected keywords.",
    CONTENT_BRIEF_LAND_LOADER_TITLE: "Fetching Keyword Suggestions.",
    SUCCESS_DOWNLOAD_KEYWORDS_DETAILS: "Successfully downloaded keyword!",
    SUCCESS_FILE_UPLOAD: "Successfully uploaded keywords and underlying parameters to the project!",
    ERROR_EXCEL_FILE: "We found an error in the upload file. Please correct the errors & re-upload",
    FILE_UPLOAD_FAILED:
        "There was an issue while uploading the file. Please try again in sometime or contact alps@iquanti.com if the issue persists.",
    DOWNLOAD_ERROR_FILE: "Download error file",
    ORIGINAL: "Original",
    AVG_OF_TOP_3_URLS: "Avg. of Top 3 URLs",
    METRIC_NAME: "Metric Name",
    HTTPS_ON_PAGE: "HTTPS on page",
    KW_ERROR_TEXT_PAGE_OPTIMIZER: "Enter a Keyword to analyze",
    SEARCH_URL_DOMAIN_ERROR_TEXT_PAGE_OPTIMIZER: "Please enter a valid URL to optimize.",
    SEARCH_URL_KW_ERROR_TEXT_PAGE_OPTIMIZER: "Please enter at least one Keyword to analyze (e.g. 'credit card').",
    FILTER_TEXT: "Filter",
    FILTER_DESC: "Select the Keywords and Competitor URLs to get the Content score, Authority score and Technical score",
    EXAMPLE_PREFIX_PAGE_OPTIMIZATION: "Try this example",
    EXAMPLE_URL_PAGE_OPTIMIZATION: "https://money.com/best-credit-cards/",
    EXAMPLE_KEYWORD_PAGE_OPTIMIZATION: "credit cards"
};

export const MgKwBrandTypeFilterList = [
    { label: "All", value: "all" },
    { label: "Branded", value: "branded" },
    { label: "Non-Branded", value: "non_branded" }
];

/** Copy content ends */
export const HomeKWSearchBoxInfoList = [
    COPY.HOME_KW_SEARCHBOX_INFO_LIST_1,
    COPY.HOME_KW_SEARCHBOX_INFO_LIST_2,
    COPY.HOME_KW_SEARCHBOX_INFO_LIST_3
];

/** Copy content ends */
export const themeSelectedValue = {
    label: "All Themes",
    value: 1
};
export const THEME_SELECTED = 1;
export const BRAND_SELECTED = 1;
export const brandListItem = [
    { value: 1, label: "All" },
    { value: 2, label: "Branded" },
    { value: 3, label: "Non branded" }
];
export const keywordTypeDropdownList = [
    { value: 11, label: COPY.MKW_TARGETED_KEYWORD_LABEL },
    { value: 12, label: COPY.MKW_RANKING_KEYWORD_LABEL }
];
export const SearchType = {
    BRAND: "BRAND",
    KEYWORD: "KEYWORD",
    THEME: "THEME"
};
export const viewRecommendationKeywordsData = [
    { label: "car", value: "1" },
    { label: "speed", value: "2" },
    { label: "speed", value: "3" },
    { label: "car", value: "4" },
    { label: "speed", value: "5" },
    { label: "car", value: "6" }
];

export const VALUE_FILTER = "value";
export const VIEW_BODY_CONTENT = "Click to view Body Content";

export const CONTENT_GAP =
    "The content gap compares target and competitor URLs. It is driven by the specific impact of each on-page parameter for the keyword.";
export const TECHNICAL_GAP =
    "The technical gap compares target and competitor URLs. It is driven by the specific impact of each technical parameter for the page.";
export const AUTHORITY_GAP =
    "The authority gap compares target and competitor URLs. It is driven by the specific impact of each off-page parameter for the page.";

export const SERP_KWA_TRAFFIC =
    "Estimated Traffic - This metric takes into account the monthly search volume and the CTR for the position of the ranking URL for the keyword.";

export const SERP_KWA_CTR =
    "Estimated Click Through Rate - This metric depends on the keyword, its rank position and the SERP layout for the keyword.";
export const KWA_CONTENT_SCORE =
    "The content score is a summation of on-page factors on a scale of 1 - 10. The score quantifies how the keyword is optimized in the page body content.";

export const KWA_TECHNICAL_SCORE = "The technical score quantifies the speed and accessibility aspects of the page on a scale of 1 - 10.";

export const KWA_AUTHORITY_SCORE =
    "The authority score is a summation of off-page factors on a scale of 1 - 10. The score quantifies the authority of the page based on the relevancy and strength of its backlink profile.";

export const PARAMETER_GAP =
    "The gap compares target and competitor URLs. It is driven by the specific impact of each parameter for the keyword/page.";

export const CRAWL_STATUS_INFO = "The green icon next to each URL indicates that ALPS was able to crawl the URL successfully.";

export const CONTENT_SIMULATION_TRAFFIC =
    "Estimated Traffic - This metric takes into account the monthly search volume and the CTR for the position of the ranking URL for the keyword.";

export const CONTENT_SIMULATION_CONVERSION =
    "Estimated Conversions - This metric takes into account the estimated traffic and the conversion rate of the ranking URL available in your analytics data.";

export const CONTENT_SIMULATION_SALES =
    "Estimated sales completion - This metric takes into account the estimated traffic and the sales completion rate of the ranking URL available in your analytics data.";

export const FOOTER_DETAIL_LINK: FooterLinkInterface = {
    dev: {
        Privacy_Policy: "https://alpsnew.smallbizvoices.com/privacy-policy/",
        Terms_Conditions: "https://alpsnew.smallbizvoices.com/terms/"
    },
    prod: {
        Privacy_Policy: "https://www.alps.ai/privacy-policy/",
        Terms_Conditions: "https://www.alps.ai/alps-terms/"
    },
    qa: {
        Privacy_Policy: "https://alpsnew.smallbizvoices.com/privacy-policy/",
        Terms_Conditions: "https://alpsnew.smallbizvoices.com/terms/"
    },
    uat: {
        Privacy_Policy: "https://alpsnew.smallbizvoices.com/privacy-policy/",
        Terms_Conditions: "https://alpsnew.smallbizvoices.com/terms/"
    }
};
export const brandTypeList = [BrandingFilter.BRANDED, BrandingFilter.NON_BRANDED];

export const APPLIED_FILTERS_ON_AUTH_TECH: AppliedFilterSortInterface = {
    filter: [],
    sort: "VH-VL"
};
export const OVERALL_SORTING_OPTIONS: OptionTypeInterface[] = [
    { default: false, label: "Name ( A – Z)", order: [], value: ASCENDING },
    { default: false, label: "Name ( Z – A)", order: [], value: DESCENDING },

    {
        default: true,
        label: "Importance ( V High – V low)",
        order: [
            ImportanceLevelType.VERY_HIGH,
            ImportanceLevelType.HIGH,
            ImportanceLevelType.MEDIUM,
            ImportanceLevelType.LOW,
            ImportanceLevelType.VERY_LOW,
            ImportanceLevelType.EMPTY // TODO: TO BE REMOVED ONCE NEW TECH PARAMS ARE USED IN MODEL
        ],
        value: "VH-VL"
    },
    {
        default: false,
        label: "Importance (V Low – V High)",
        order: [
            ImportanceLevelType.VERY_LOW,
            ImportanceLevelType.LOW,
            ImportanceLevelType.MEDIUM,
            ImportanceLevelType.HIGH,
            ImportanceLevelType.VERY_HIGH,
            ImportanceLevelType.EMPTY // TODO: TO BE REMOVED ONCE NEW TECH PARAMS ARE USED IN MODEL
        ],
        value: "VL-VH"
    }
];

export const KW_IMPACT_CSV_HEADER: string[] = [
    " ",
    "SV",
    "Existing Rank",
    "New Rank",
    "Existing Traffic",
    "New Traffic",
    "Existing Content Score",
    "New Content Score",
    "Existing URL Score",
    "New URL Score",
    "Existing Title Score",
    "New Title Score",
    "Existing H1 Score",
    "New H1 Score",
    "Existing H2 Score",
    "New H2 Score",
    "Existing Meta Desc Score",
    "New Meta Desc Score",
    "Existing Body Content Score",
    "New Body Content Score",
    "Existing Authority Score",
    "New Authority Score",
    "Existing Technical Score",
    "New Technical Score"
];

export const KW_IMPACT_CSV_HEADER_BETA: string[] = [
    " ",
    "SV",
    "Existing Rank",
    "New Rank",
    "Existing Traffic",
    "New Traffic",
    "Existing Content Score",
    "New Content Score",
    "Existing URL Score",
    "New URL Score",
    "Existing Title Score",
    "New Title Score",
    "Existing H1 Score",
    "New H1 Score",
    "Existing H2 Score",
    "New H2 Score",
    "Existing H3 Score",
    "New H3 Score",
    "Existing Meta Desc Score",
    "New Meta Desc Score",
    "Existing Body Content Score",
    "New Body Content Score",
    "Existing Authority Score",
    "New Authority Score",
    "Existing Technical Score",
    "New Technical Score"
];

export const KEYWORD_URL_SELECTION_OPTION: RadioInterface[] = [
    {
        label: COPY.KEYWORD_URL_BOX_I_HAVE_LIVE_URL,
        value: true
    },
    {
        label: COPY.KEYWORD_URL_BOX_I_DO_NOT_HAVE_LIVE_URL,
        value: false
    }
];

export const DATA_ON_DEMAND_SELECT_DATA_OPTION: OptionTypeInterface[] = [
    { label: COPY.DOD_DATA_TYPE_KEYWORD_RANK, value: COPY.RANK },
    { label: COPY.CONTENT_SIMULATOR_SEARCH_VOLUME, value: COPY.SV },
    { label: COPY.DOD_DATA_TYPE_PAGE_FOOTPRINT, value: COPY.URL_FOOTPRINT }
];

export const CAT_DOWNLOAD_REPORT_HEADER = ["Performance Indicators", "Existing", "New"];
export const CAT_PARAMETERS_DOWNLOAD_REPORT_HEADER = ["Parameter", "Existing", "New"];
export const CAT_CATEGORIES_C = "C";
export const CAT_CATEGORIES_A = "A";
export const CAT_CATEGORIES_T = "T";
export const SIMULATION_REPORT_CAT_FILE_NAME = "SimulatedContent.docx";
export const DEFAULT_FOR_DATA_FETCH_FAIL = "-";
export const SIMULATION_REPORT_KW_IMPACT_FILE_NAME = "Simulation-Impact-on-Keywords.csv";
export const TRUE_IN_DASHBOARD = "Yes";
export const FALSE_IN_DASHBOARD = "No";
export const VISIBLE_BACK_LINKS_WITH_URL_ANCHOR_TEXT = "Backlinks with URL as anchor text (%)";
export const VISIBLE_BACK_LINKS_WITH_GENERIC_ANCHOR_TEXT = "Backlinks with generic anchor text (%)";
export const VISIBLE_BACK_LINK_WITH_NON_EMPTY_ANCHOR_TEXT = "Backlinks with non-empty textual anchor text (%)";
export const VISIBLE_KW_URL_BACK_AC_TEXT_FULL_MATCH_TEXT = "Keyword Occurrence in backlinks anchor texts (%)";
export const VISIBLE_FETCH_TIME = "Time To First Byte (sec)";
export const VISIBLE_HTML_SIZE = "Page size (in KB)";
export const EMPTY_DATA = "";
export const SIMULATION_REPORT_KW_IMPACT_XLSX_FILE_NAME = "Simulation-Impact-on-Keywords.xlsx";
export const XLSX_TEMPLATE_FILE = "ALPS Template.xlsx";
export const SIMULATION_REPORT_XLSX_FILE_NAME = "Simulation-Changes.xlsx";

export const HtmlUploadErrors = [
    {
        id: 101,
        status: 400,
        errMsg: "As this is not a HTML file content, please upload a valid file to proceed"
    }
];

export const InitiateRequestErrors = [
    {
        id: 201,
        status: 422,
        errMsg: "The URL entered cannot be crawled, please enter a correct URL."
    },
    {
        id: 202,
        status: "default",
        errMsg: "Server is unable to process your request at this moment. We are looking into it."
    }
];

export const contentBriefRequestErrors = [
    {
        id: 203,
        status: 400,
        errMsg: "Server is unable to process your request. Something went wrong please try again."
    },
    {
        id: 204,
        status: 401,
        errMsg: "Authentication failed. Please try again."
    }
];

export const DOD_ERROR_CODES = {
    400001: COPY.DOD_FILE_UPPLOAD_EMPTY_FILE_ERROR_TEXT,
    400002: COPY.DOD_FILE_UPLOAD_MAX_SIZE_ERROR_TEXT,
    400003: COPY.DOD_FILE_UPPLOAD_NON_CSV_ERROR_TEXT
};

export const SIMULATION_HISTORY_TABLE_CONTENT: SimulationHistoryTableInterface = {
    Table_Heading: "SIMULATION HISTORY",
    Search_URL: "Search URL",
    Search_Placeholder: "Search",
    URL: "URL",
    Description: "Description",
    Type: "Type",
    View: "View",
    Created_By: "Created By",
    Created_Date: "Created Date",
    Last_Modified_By: "Last Modified By",
    Last_modified_Date: "Last modified Date",
    Keyword: "Keyword(s)",
    Existing_Content: "Existing Content",
    New_Content: "New Content",
    Simulations_not_available: "No Saved Simulations available",
    Matching_results_found: "No matching results found for search",
    More: "more...",
    Action: "Action",
    AI_Content: "AI-assisted Content"
};

export const DEFAULT_SELECTED_USER = {
    label: "All Users",
    value: "1"
};

export const SIMULATOR_ALERT_NEW_RUN_SIMULATION = {
    MESSAGE:
        "Some data from the previous simulation is being processed and may take a few minutes. If you {{condition}}, some of the metrics from the previous simulation will not be available in simulation history.",
    DEFAULT_CONDITION: "simulate right now",
    INITIATE_CONDITION: "update URL/keywords right now",
    REFRESH_CONDITION: "continue with this refresh action",
    SWITCH_VERSION_TEXT: "switch the version right now"
};

export const SIMULATION_VERSION_STATUS = {
    kw_rank_data: false,
    kw_sv: false,
    request_id: "",
    url_auth_tech: false,
    url_content: false
};

export const VERSION_NOTIFICATION = {
    isVisible: false,
    isOlderOneMonth: false
};

export const PROJECT_SETUP_TAB_LIST = [
    {
        key: 1,
        tab_name: "Domain Details"
    },
    {
        key: 2,
        tab_name: "Competitors"
    },
    {
        key: 3,
        tab_name: "Integrations"
    },
    {
        key: 4,
        tab_name: "Add Keywords"
    },
    {
        key: 5,
        tab_name: "Manage Keywords"
    }
];

export const KEYWORD_SORT_TYPES: OptionTypeInterface[] = [
    { value: "SV_H_TO_L", label: "Search Volume(High-Low)" },
    { value: "SV_L_TO_H", label: "Search Volume(Low-High)" },
    { value: "ASC_A_TO_Z", label: "Ascending (A - Z)" },
    { value: "DES_Z_TO_A", label: "Descending (Z - A)" },
    { value: "RS_H_TO_L", label: "Relevance Score(High-Low)" },
    { value: "RS_L_TO_H", label: "Relevance Score(Low-High)" },
    { value: "RANK_H_TO_L", label: "Rank(High-Low)" },
    { value: "RANK_L_TO_H", label: "Rank(Low-High)" },
    { value: "URL_ASC_A_TO_Z", label: "URL Ascending (A - Z)" },
    { value: "URL_DES_Z_TO_A", label: "URL Descending (Z - A)" }
];
export const SEARCH_VOLUME_LIST: OptionTypeInterface[] = [
    { value: "6", label: "> 1,000,000" },
    { value: "5", label: "100,001 - 1,000,000" },
    { value: "4", label: "10,001 - 100,000" },
    { value: "3", label: "1,001 - 10,000" },
    { value: "2", label: "101 - 1,000" },
    { value: "1", label: "< 100" }
];
export const RELEVANCE_SCORE_LIST: OptionTypeInterface[] = [
    { value: "4", label: "0.7 - 1" },
    { value: "3", label: "0.5 - 0.7" },
    { value: "2", label: "0.3 - 0.5" },
    { value: "1", label: "0 - 0.3" }
];

export const APPLIED_FILTERS_ON_KEYWORD: AppliedFilterOnKeywordInterface = {
    theme: [],
    keywordtype: [],
    brandtype: undefined,
    keywordfilter: undefined,
    keyword_advance_filter: [],
    selectall: false,
    searchvolume: [],
    relevancescore: [],
    rank: [],
    search_by_urls: [],
    sorttype: KEYWORD_SORT_TYPES[0]
};

export const KW_RESEARCH_PAGE_SEARCH_TYPES: KwResearchInputTypesInterface = {
    keyword: "keyword",
    url: "url",
    domain: "domain"
};

export const KW_RESEARCH_PAGE_SEARCH_TYPE_OPTIONS: OptionTypeInterface[] = [
    { label: "Keyword", value: KW_RESEARCH_PAGE_SEARCH_TYPES.keyword },
    { label: "URL", value: KW_RESEARCH_PAGE_SEARCH_TYPES.url },
    { label: "Domain", value: KW_RESEARCH_PAGE_SEARCH_TYPES.domain }
];

// PRT REPORT CODES
export const SHARE_OF_VOICE = "sov";
export const TOPICAL_AUTHORITY = "ta";
export const PERFORMANCE_REPORTS = "performance-reports";

export const RELATED_KW_SSE_EVENT_TYPES: RelatedKwSseEventInterface = {
    receive_related_kwds: "receive_related_kwds",
    req_completed: "req_completed",
    req_failed: "req_failed",
    error: "error"
};

export const RELATED_KW_INITIATE_API_TYPE: RelatedKwInititeApiTypeInterface = {
    keyword: "keyword",
    url: "url",
    domain: "domain"
};

export const KwResearchInitiateRequestErrors = [
    {
        id: 401,
        status: 400,
        errMsg: "Please enter a valid Keyword or URL"
    },
    {
        id: 402,
        status: "default",
        errMsg: "Unable to process your request at this moment. Please reach out to alps@iquanti.com if the error persists."
    }
];

export const KwResearchSSEErrors = [
    {
        id: 501,
        status: "default",
        errMsg: "Unable to process your request at this moment. Please reach out to alps@iquanti.com if the error persists."
    }
];

// KRT report code
export const KW_RESEARCH_REPORT_CODE = "krt-reports";

export const KW_RESEARCH_KEYWORD_SECTION_CODE = "keyword";
export const KW_RESEARCH_URL_SECTION_CODE = "url";
export const KW_RESEARCH_DOMAIN_SECTION_CODE = "domain";

export const KW_RESEARCH_REPORT_TABLE_NAMES = {
    keyword: "vw_keyword_metrics_relevant_keywords",
    url: "vw_url_metrics_relevant_keywords",
    domain: "vw_domain_metrics_ranking_keywords"
};

export const KW_RESEARCH_HOME_BANNER_SUBTITLE_DATA: HomeSubtitleDataInterface[] = [
    {
        line1: "Enter a",
        line2: "Keyword/URL",
        step: 1
    },
    {
        line1: "Get related keywords",
        line2: "with search volume",
        step: 2
    },
    {
        line1: "View relevance",
        line2: "score for each related keyword",
        step: 3
    },
    {
        line1: "Filter data",
        line2: "with additional dimensions",
        step: 4
    },
    {
        line1: "Download",
        line2: "related keywords",
        step: 5
    }
];
export const PERFORMANCE_REPORTS_PAGES_REPORT = "pages-reports";
export const SCORES_REPORT_SCORE_TRENDS = "scores-reports";
export const RANK_TRACKING_COMBINED_REPORT = "rank-tracking";
export const RANK_TRACKING_DASHBOARD_REPORT = "rank-tracking";
export const GSC_REPORT = "gsc-reports";
export const GSC_RAW_REPORT = "gsc-raw-reports";
export const SCORES_REPORT_GAP_ANALYSIS = "scores-dq-reports";

export const CONTENT_BRIEF_TABS = [
    {
        id: 1,
        name: "Keywords"
    },
    {
        id: 2,
        name: "Competitor Pages"
    },
    {
        id: 3,
        name: "Questions"
    },
    {
        id: 4,
        name: "Phrases"
    },
    {
        id: 5,
        name: "H1"
    },
    {
        id: 6,
        name: "H2"
    },
    {
        id: 7,
        name: "Titles"
    },
    {
        id: 8,
        name: "Meta Description"
    }
];

export const CONTENT_BRIEF_ADD_KEYWORD_TABS = [
    {
        id: 1,
        name: "Keyword Suggestions"
    },
    {
        id: 2,
        name: "Input Keywords"
    }
];

export const KW_RESEARCH_FILTER: OptionTypeInterface[] = [
    { label: "Include", value: "Include" },
    { label: "Exclude", value: "Exclude" }
];

export const KRT_INCLUDE_KW_OPTION: OptionTypeInterface[] = [
    { label: "All", value: "all" },
    { label: "Any", value: "any" }
];

export const MANAGE_KEYWORD_TABLE_HEADER_ORDER = ["Keyword", "Target URL", "Category", "Theme"];

export const MANAGE_KEYWORD_EDIT_PLACEHOLDER = "Edit";
export const MANAGE_KEYWORD_EDIT_OPTIONS: OptionTypeInterface[] = [
    { label: "Target URL", value: "Target URL" },
    { label: "Keyword Type", value: "Keyword Type" }
];

export const MK_EO_TARGET_URL_MODAL_SEARCH_PLACEHOLDER = "Search Target URL";

export const MK_EO_KEYWORD_TYPE_MODAL_MESSAGE = "Do you want to optimise for these keywords";
export const MK_EO_TARGET_URL_MODAL_SUB_HEADING = "Target URL";
export const MK_EO_TARGET_URL_MODAL_SEARCH_BAR_LABEL = "Select Target URL";
export const MK_EO_TARGET_URL_MODAL_ADD_TARGET_URL_LINK = "+ Add Target URL";
export const MK_EO_TARGET_URL_MODAL_ADD_TARGET_URL_PLACEHOLDER = "Enter New Target URL";
export const MK_EO_TARGET_URL_MODAL_ADD_TARGET_URL_ALREADY_EXIST = "Target URL already exist";
export const MK_EO_TARGET_URL_MODAL_ADD_TARGET_URL_INVALID =
    "Invalid URL. Target URL should belong to one of the domains or sub-domains included in the project setup";
export const MK_EO_TARGET_URL_MODAL_ADD_TARGET_URL_INCORRECT_URL = "Please enter a valid URL (eg. https://www.example.com)";

export const DOMAIN_DETAILS_CREATE_SUCCESS_MSG = "Successfully added Project!";
export const DOMAIN_DETAILS_CREATE_SUCCESS_POPUP_LABEL = "What do you want to do first?";
export const DOMAIN_DETAILS_CREATE_SUCCESS_POPUP_TABS_LIST = [
    { title: "Add Competitors", URL: PROJECT_SETUP_COMPETITORS_URL },
    { title: "Add Integrations", URL: PROJECT_SETUP_INTEGRATIONS_URL },
    { title: "Add Keywords", URL: PROJECT_SETUP_ADD_KEYWORDS_URL }
];

export const HIGHLIGHT_COLORS = [
    { color: "yellow", value: "#f9dc5c" },
    { color: "green", value: "#b4d06f" },
    { color: "orange", value: "#fbaa15" },
    { color: "blue", value: "#3a85ef" }
];

export const EDITOR_MODES = {
    browser: "browser",
    editor: "editor"
};
export const RANK_LIST: OptionTypeInterface[] = [
    { value: "1", label: "1" },
    { value: "2", label: "2 - 3" },
    { value: "3", label: "4 - 10" },
    { value: "4", label: "11 - 20" },
    { value: "5", label: "21 - 50" },
    { value: "6", label: "51 - 100" }
];

export const EMPTY_AI_DATA = {
    h1: [],
    h2: [],
    h3: [],
    title: []
};

export const NAV_ITEM_SHARE_OF_VOICE_DESCRIPTION = "Discover competitive insights across your entire topical landscape";
export const NAV_ITEM_TOPICAL_AUTHORITY_DESCRIPTION = "Uncover competitive gaps for key topics and identify new content opportunities";
export const NAV_ITEM_ALPS_SCORE_ANALYSIS_DESCRIPTION = "Identify and prioritize optimization opportunities based on competitive SEO gaps";
export const NAV_ITEM_THEME_PRIORITIZATION_DESCRIPTION =
    "Prioritize opportunities based on forecasted SEO impact and your page's likelihood to rank";
export const NAV_ITEM_KEYWORD_RESEARCH_DESCRIPTION = "Identify the most relevant keywords to target for any page or topic";
export const NAV_ITEM_KEYWORD_GAP_ANALYSIS_DESCRIPTION =
    "Uncover SEO gaps on your page for Content, Authority, and Technical ranking factors";
export const NAV_ITEM_CONTENT_BRIEF_DESCRIPTION = "Generate comprehensive topic research and enable writers to create high-ranking content";
export const NAV_ITEM_PAGE_SIMULATION_DESCRIPTION = "Predict the rank and traffic impact of optimization changes before publishing";
export const NAV_ITEM_PROJECT_DASHBOARD_DESCRIPTION = "View all the key search performance metrics for your project in one place";
export const NAV_ITEM_RANK_TRACKING_DESCRIPTION = "Track changes in keyword ranks and page performance over time";
export const NAV_ITEM_GSC_ANALYTICS_DESCRIPTION = "Analyze your page’s key performance metrics with the Google Search Console integration";
export const NAV_ITEM_CONTENT_AUDIT_DESCRIPTION = "Identify content gaps on your pages and get recommendations to rank higher";
export const NAV_ITEM_BACKLINK_AUDIT_DESCRIPTION = "Monitor your site's backlink profile and identify opportunities to improve authority";
export const NAV_ITEM_TECHNICAL_AUDIT_DESCRIPTION = "Audit all pages of your domain on 80+ technical SEO parameters";

export const NAV_ITEM_DEMO_DESCRIPTION = "This is demo description for module fetures";

export const BODY_CONTENT_ELEMENTS = ["H4", "H5", "H6", "SPAN", "DIV", "P", "A", "UL", "LI"];
export const HEADER_TAG_ELEMENTS = ["H1", "H2", "H3"];

export const RETURN_CLASS = ["north", "east", "west", "south", "ne", "nw", "sw", "se"];
export const COMMON_TAGS = ["H2", "H3"];

export const RECOMMENDATION_SSE_EVENT_TYPES: RecommendationSseEventInterface = {
    receive_h1_text: "receive_h1_text",
    receive_h1_scores: "receive_h1_scores",
    receive_title_text: "receive_title_text",
    receive_title_scores: "receive_title_scores",
    receive_h2_text: "receive_h2_text",
    receive_h2_scores: "receive_h2_scores",
    req_completed: "req_completed",
    req_failed: "req_failed",
    error: "error"
};

export const USER_EV_FALIURE = "Verification failed. Please try after sometime or reach out to ";
export const USER_EV_INVALID_LINK = "Invalid Link. Please use the link provided in the verification email.";
export const USER_EV_LINK_EXPIRED =
    "This verification link has expired. Please click on Resend to request a new verification link in your inbox.";
export const USER_EV_RESEND_MAIL_SUCCESS = "Verification email Sent. Please verify your account to proceed to Login.";
export const USER_EV_RESEND_MAIL_FAILURE = "Failed to send Verification Email. Please try after sometime or reach out to ";
export const USER_EV_VERIFYING = "Verifying...";
export const USER_EV_REVERIFYING = "Resending Verification Mail...";
export const USER_EV_ALPSEMAIL = "alps@iquanti.com";

export const KEYWORD_STUFFING_MODAL_INFO =
    "Below are the words/phrases which seem to be repetitive. This is just a warning. Please ignore, if you are confident of the copy.";

export const DOWNLOAD_OPTIONS_BROWSER = [{ label: "Download Content Authority Technical", value: "2" }];

export const BACKLINK_RELEVENCE = ["backlink_content_score", "kw_url_bck_ac_txt_full_mtch_pct"];

export const ContextRecomApiPayloadMap: { [id: string]: string } = {
    title: "title",
    meta_description: "meta_desc",
    h1: "h1",
    h2: "h2",
    h3: "h3"
};

export const PAGENOTCRAWL: PageNotCrawlInterface = {
    TITLE: "Your page could not be crawled",
    CONTENT_TRACK: `Please ensure that the URL includes the protocol <span style={{ fontWeight: "bold" }}> 'https://' </span>
    or <span style={{ fontWeight: "bold" }}> 'https://' </span>  as well as the <span style={{ fontWeight: "bold" }}> 'www' </span>  prefix,
    as required. You can also edit the below URL and click on <span style={{ fontWeight: "bold" }}> 'Go' </span>  to proceed`,
    CONTENT_SERP: `Please review the keyword(s) once again, as we are currently unable to retrieve the Search Engine Results Page (SERP) for them. You can input another keyword(s) and then click on 'Go' to proceed`,
    LOCALE: "Locale",
    URL: "Enter the URL",
    kEYWORDS: "Enter Keyword(s)",
    TRACK: "track request failed",
    SERP: "SERP request failed",
    INSTANCE_ID: "page-not-crawled",
    MODAL_POPUP_ID: "page-not-crawled-message-pop"
};

export const COOKIE_SUB_PLAN = "__iQ_SUB_PLAN";
export const COOKIE_LFT_USER = "__iQ_ALPS_LFT_USER";
