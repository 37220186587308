import { NavLinkType, PrimaryNavListInterface } from "./types";
import {
    HOME_URL,
    KEYWORD_ANALYSIS_URL,
    KEYWORD_GAP_ANALYSIS_URL,
    OPTIMIZATION_URL,
    PAGE_OPTIMIZER_URL,
    COMPETITIVE_RESEARCH_URL,
    CR_SHARE_OF_VOICE_CATEGORY_URL,
    SIMULATOR_URL,
    CR_TOPICAL_AUTHORITY_CATEGORY_URL,
    CR_TOPICAL_AUTHORITY_DOMAIN_URL,
    CR_SHARE_OF_VOICE_OVERVIEW_URL,
    KEYWORD_RESEARCH_HOME_URL,
    CONTENT_BRIEF,
    CONTENT_BRIEF_NEW,
    CONTENT_BRIEF_VIEW,
    SERP_RESULT_URL,
    CR_DATA_ON_DEMAND_URL,
    PR_GSC_KEYWORD_DETAILS_URL,
    PR_GSC_PAGE_ANALYSIS_URL,
    CR_ALPS_SCORE_ANALYSIS_SCORE_TRENDS_URL,
    KEYWORD_RESEARCH_TOOL_URL,
    PRS_PERFORMANCE_REPORTS_URL,
    PRS_RANK_TRACKING_KEYWORD_RANK_URL,
    PRS_RANK_TRACKING_PAGES_REPORT_URL,
    PRS_RANK_TRACKING_URL,
    CR_ALPS_SCORE_ANALYSIS_URL,
    PR_GSC_URL,
    CR_ALPS_SCORE_ANALYSIS_GAP_ANALYSIS_URL,
    PRS_PROJECT_DASHBOARD_URL,
    PRS_PROJECT_DASHBOARD_PERFORMANCE_DASHBOARD_URL,
    PR_GSC_MASTERVIEW,
    PR_GSC_KEYWORD_URL,
    PR_GSC_KEYWORD_ONLY,
    PR_GSC_URL_ONLY,
    CUSTOM_REPORTING_URL,
    CUSTOM_REPORTING_EDIT_URL,
    CUSTOM_REPORTING_VIEW_URL,
    PRS_PERFORMANCE_IMPACT_URL,
    PRS_PERFORMANCE_IMPACT_REPORT_URL,
    SERP_FEATURE_COVERAGE_URL
} from "./routes-url-constant";
import {
    NAV_ITEM_SHARE_OF_VOICE_DESCRIPTION,
    NAV_ITEM_TOPICAL_AUTHORITY_DESCRIPTION,
    NAV_ITEM_ALPS_SCORE_ANALYSIS_DESCRIPTION,
    NAV_ITEM_KEYWORD_RESEARCH_DESCRIPTION,
    NAV_ITEM_KEYWORD_GAP_ANALYSIS_DESCRIPTION,
    NAV_ITEM_CONTENT_BRIEF_DESCRIPTION,
    NAV_ITEM_PAGE_SIMULATION_DESCRIPTION,
    NAV_ITEM_PROJECT_DASHBOARD_DESCRIPTION,
    NAV_ITEM_RANK_TRACKING_DESCRIPTION,
    NAV_ITEM_GSC_ANALYTICS_DESCRIPTION,
    NAV_ITEM_DEMO_DESCRIPTION
} from "../../../src/app/const";

import {
    auditIcon,
    navShareOfVoiceIcon,
    navTropicalAuthorityIcon,
    navALPSScoreAnalysisIcon,
    navKeywordResearchIcon,
    navKeywordGapAnalysisIcon,
    navContentBriefIcon,
    navPageSimulationIcon,
    navProjectDashboardIcon,
    navRankTrackingIcon,
    navGSCAnalyticsIcon
} from "../../app/icons";

import { isTrialUser, getFeatureAddons } from "../../app/duck/utils";
const featureAddOns = getFeatureAddons();

const CUSTOM_GSC_TABS_LIST = featureAddOns.includes("custom-gsc")
    ? [
        {
            id: "gsc-masterview-url",
            to: PR_GSC_MASTERVIEW,
            title: "Masterview",
            type: NavLinkType.INTERNAL,
            breadcrumbs: [
                {
                    id: "PerformanceReportsBr",
                    title: "Performance Reports",
                    type: NavLinkType.INTERNAL,
                    to: ""
                },
                {
                    id: "gscBr",
                    title: "GSC Analytics",
                    type: NavLinkType.INTERNAL,
                    to: ""
                },
                {
                    id: "gscMasterviewBr",
                    title: "Masterview",
                    type: NavLinkType.INTERNAL,
                    to: ""
                }
            ]
        },
        {
            id: "gsc-keyword-url-url",
            to: PR_GSC_KEYWORD_URL,
            title: "Keyword + URL",
            type: NavLinkType.INTERNAL,
            breadcrumbs: [
                {
                    id: "PerformanceReportsBr",
                    title: "Performance Reports",
                    type: NavLinkType.INTERNAL,
                    to: ""
                },
                {
                    id: "gscBr",
                    title: "GSC Analytics",
                    type: NavLinkType.INTERNAL,
                    to: ""
                },
                {
                    id: "gscKeywordURLBr",
                    title: "Keyword + URL",
                    type: NavLinkType.INTERNAL,
                    to: ""
                }
            ]
        },
        {
            id: "gsc-keyword-only-url",
            to: PR_GSC_KEYWORD_ONLY,
            title: "Keyword Only",
            type: NavLinkType.INTERNAL,
            breadcrumbs: [
                {
                    id: "PerformanceReportsBr",
                    title: "Performance Reports",
                    type: NavLinkType.INTERNAL,
                    to: ""
                },
                {
                    id: "gscBr",
                    title: "GSC Analytics",
                    type: NavLinkType.INTERNAL,
                    to: ""
                },
                {
                    id: "gscKeywordOnlyBr",
                    title: "Keyword Only",
                    type: NavLinkType.INTERNAL,
                    to: ""
                }
            ]
        },
        {
            id: "gsc-url-only-url",
            to: PR_GSC_URL_ONLY,
            title: "URL Only",
            type: NavLinkType.INTERNAL,
            breadcrumbs: [
                {
                    id: "PerformanceReportsBr",
                    title: "Performance Reports",
                    type: NavLinkType.INTERNAL,
                    to: ""
                },
                {
                    id: "gscBr",
                    title: "GSC Analytics",
                    type: NavLinkType.INTERNAL,
                    to: ""
                },
                {
                    id: "gscUrlOnlyBr",
                    title: "URL Only",
                    type: NavLinkType.INTERNAL,
                    to: ""
                }
            ]
        }
    ]
    : [];

const isTrial: boolean = isTrialUser();

const SERP_FEATURE_COVERAGE_TAB = [{
    id: "serp-feature-coverage",
    to: SERP_FEATURE_COVERAGE_URL,
    title: "SERP FEATURE COVERAGE",
    description: "Track keyword SERP feature coverage and presence with visual insights",
    icon: navProjectDashboardIcon,
    type: NavLinkType.INTERNAL,
    isLocked: isTrial,
    breadcrumbs: [
        {
            id: "PerformanceReportsBr",
            title: "Performance Reports",
            type: NavLinkType.INTERNAL,
            to: ""
        },
        {
            id: "SERPFeatureCoverageBr",
            title: "SERP Feature Coverage",
            type: NavLinkType.INTERNAL,
            to: ""
        },
    ],
    tabs: {
        title: "SERP Feature Coverage",
        icon: auditIcon,
        navLinks: []
    }
}];

const PrimaryNavList: PrimaryNavListInterface[] = [
    {
        id: "competitive-research",
        to: COMPETITIVE_RESEARCH_URL,
        highlightWhen: [
            CR_SHARE_OF_VOICE_OVERVIEW_URL,
            CR_SHARE_OF_VOICE_CATEGORY_URL,
            CR_ALPS_SCORE_ANALYSIS_URL,
            CR_ALPS_SCORE_ANALYSIS_SCORE_TRENDS_URL,
            CR_ALPS_SCORE_ANALYSIS_GAP_ANALYSIS_URL
        ],
        title: "COMPETITIVE RESEARCH",
        type: NavLinkType.INTERNAL,
        children: [
            {
                id: "/share-of-voice",
                to: CR_SHARE_OF_VOICE_OVERVIEW_URL,
                title: "SHARE OF VOICE",
                description: NAV_ITEM_SHARE_OF_VOICE_DESCRIPTION,
                icon: navShareOfVoiceIcon,
                type: NavLinkType.INTERNAL,
                isLocked: isTrial,
                breadcrumbs: [
                    {
                        id: "CompetitiveResearchBr",
                        title: "Competitive Research",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    },
                    {
                        id: "shareOfVoiceBr",
                        title: "Share of Voice",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    },
                    {
                        id: "shareOfVoiceOverviewBr",
                        title: "Overview",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    }
                ],
                tabs: {
                    title: "SHARE OF VOICE",
                    icon: auditIcon,
                    navLinks: [
                        {
                            id: "share-of-voice-overview-url",
                            to: CR_SHARE_OF_VOICE_OVERVIEW_URL,
                            title: "Overview",
                            type: NavLinkType.INTERNAL,
                            breadcrumbs: [
                                {
                                    id: "CompetitiveResearchBr",
                                    title: "Competitive Research",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "shareOfVoiceBr",
                                    title: "Share of Voice",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "shareOfVoiceOverviewBr",
                                    title: "Overview",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                }
                            ]
                        },
                        {
                            id: "share-of-voice-category-url",
                            to: CR_SHARE_OF_VOICE_CATEGORY_URL,
                            title: "Category",
                            type: NavLinkType.INTERNAL,
                            breadcrumbs: [
                                {
                                    id: "CompetitiveResearchBr",
                                    title: "Competitive Research",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "shareOfVoiceBr",
                                    title: "Share of Voice",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "shareOfVoiceCategoryBr",
                                    title: "Category",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                }
                            ]
                        }
                    ]
                }
            },
            {
                id: "/share-of-voice",
                to: CR_SHARE_OF_VOICE_CATEGORY_URL,
                isHidden: true,
                isLocked: isTrial,
                title: "SHARE OF VOICE",
                description: NAV_ITEM_SHARE_OF_VOICE_DESCRIPTION,
                icon: navShareOfVoiceIcon,
                type: NavLinkType.INTERNAL,
                breadcrumbs: [
                    {
                        id: "CompetitiveResearchBr",
                        title: "Competitive Research",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    },
                    {
                        id: "shareOfVoiceBr",
                        title: "Share of Voice",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    },
                    {
                        id: "shareOfVoiceOverviewBr",
                        title: "Category",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    }
                ],
                tabs: {
                    title: "SHARE OF VOICE",
                    icon: auditIcon,
                    navLinks: [
                        {
                            id: "share-of-voice-overview-url",
                            to: CR_SHARE_OF_VOICE_OVERVIEW_URL,
                            title: "Overview",
                            type: NavLinkType.INTERNAL,
                            breadcrumbs: [
                                {
                                    id: "CompetitiveResearchBr",
                                    title: "Competitive Research",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "shareOfVoiceBr",
                                    title: "Share of Voice",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "shareOfVoiceOverviewBr",
                                    title: "Overview",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                }
                            ]
                        },
                        {
                            id: "share-of-voice-category-url",
                            to: CR_SHARE_OF_VOICE_CATEGORY_URL,
                            title: "Category",
                            type: NavLinkType.INTERNAL,
                            breadcrumbs: [
                                {
                                    id: "CompetitiveResearchBr",
                                    title: "Competitive Research",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "shareOfVoiceBr",
                                    title: "Share of Voice",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "shareOfVoiceCategoryBr",
                                    title: "Category",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                }
                            ]
                        }
                    ]
                }
            },
            {
                id: "/topical-authority",
                to: CR_TOPICAL_AUTHORITY_DOMAIN_URL,
                title: "TOPICAL AUTHORITY",
                description: NAV_ITEM_TOPICAL_AUTHORITY_DESCRIPTION,
                icon: navTropicalAuthorityIcon,
                isLocked: isTrial,
                type: NavLinkType.INTERNAL,
                breadcrumbs: [
                    {
                        id: "CompetitiveResearchBr",
                        title: "Competitive Research",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    },
                    {
                        id: "TopicalAuthorityBr",
                        title: "Topical Authority",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    },
                    {
                        id: "TopicalAuthorityCategoryBr",
                        title: "Domain",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    }
                ],
                tabs: {
                    title: "TOPICAL AUTHORITY",
                    icon: auditIcon,
                    navLinks: [
                        {
                            id: "topical-authority-domain-tab",
                            to: CR_TOPICAL_AUTHORITY_DOMAIN_URL,
                            title: "Domain",
                            type: NavLinkType.INTERNAL,
                            breadcrumbs: []
                        },
                        {
                            id: "topical-authority-overview-tab",
                            to: CR_TOPICAL_AUTHORITY_CATEGORY_URL,
                            title: "Category",
                            type: NavLinkType.INTERNAL,
                            breadcrumbs: []
                        }
                    ]
                }
            },
            {
                id: "/topical-authority-category",
                to: CR_TOPICAL_AUTHORITY_CATEGORY_URL,
                title: "TOPICAL AUTHORITY",
                description: NAV_ITEM_TOPICAL_AUTHORITY_DESCRIPTION,
                icon: navTropicalAuthorityIcon,
                type: NavLinkType.INTERNAL,
                isHidden: true,
                isLocked: isTrial,
                breadcrumbs: [
                    {
                        id: "CompetitiveResearchBr",
                        title: "Competitive Research",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    },
                    {
                        id: "TopicalAuthorityBr",
                        title: "Topical Authority",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    },
                    {
                        id: "TopicalAuthorityDomainBr",
                        title: "Category",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    }
                ],
                tabs: {
                    title: "TOPICAL AUTHORITY",
                    icon: auditIcon,
                    navLinks: [
                        {
                            id: "topical-authority-domain-tab",
                            to: CR_TOPICAL_AUTHORITY_DOMAIN_URL,
                            title: "Domain",
                            type: NavLinkType.INTERNAL,
                            breadcrumbs: []
                        },
                        {
                            id: "topical-authority-overview-tab",
                            to: CR_TOPICAL_AUTHORITY_CATEGORY_URL,
                            title: "Category",
                            type: NavLinkType.INTERNAL,
                            breadcrumbs: []
                        }
                    ]
                }
            },
            {
                id: "/alps-score-analysis-gap-analysis",
                to: CR_ALPS_SCORE_ANALYSIS_GAP_ANALYSIS_URL,
                title: "ALPS SCORE ANALYSIS",
                description: NAV_ITEM_ALPS_SCORE_ANALYSIS_DESCRIPTION,
                icon: navALPSScoreAnalysisIcon,
                type: NavLinkType.INTERNAL,
                isLocked: isTrial,
                breadcrumbs: [
                    {
                        id: "CompetitiveResearchBr",
                        title: "Competitive Research",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    },
                    {
                        id: "AlpsScoreAnalysisBr",
                        title: "Alps Score Analysis",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    },
                    {
                        id: "GapAnalysisBr",
                        title: "Gap Analysis",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    }
                ],
                tabs: {
                    title: "ALPS SCORE ANALYSIS",
                    icon: auditIcon,
                    navLinks: [
                        {
                            id: "alps-score-analysis-gap-analysis-url",
                            to: CR_ALPS_SCORE_ANALYSIS_GAP_ANALYSIS_URL,
                            title: "Gap Analysis",
                            type: NavLinkType.INTERNAL,
                            breadcrumbs: [
                                {
                                    id: "CompetitiveResearchBr",
                                    title: "Competitive Research",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "AlpsScoreAnalysisBr",
                                    title: "Alps Score Analysis",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "GapAnalysisBr",
                                    title: "Gap Analysis",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                }
                            ]
                        },
                        {
                            id: "alps-score-analysis-score-trends-url",
                            to: CR_ALPS_SCORE_ANALYSIS_SCORE_TRENDS_URL,
                            title: "Score Trends",
                            type: NavLinkType.INTERNAL,
                            breadcrumbs: [
                                {
                                    id: "CompetitiveResearchBr",
                                    title: "Competitive Research",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "AlpsScoreAnalysisBr",
                                    title: "Alps Score Analysis",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "ScoreTrendsBr",
                                    title: "Score Trends",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                }
                            ]
                        }
                    ]
                }
            },
            {
                id: "/alps-score-analysis",
                to: CR_ALPS_SCORE_ANALYSIS_SCORE_TRENDS_URL,
                isHidden: true,
                isLocked: isTrial,
                title: "ALPS SCORE ANALYSIS",
                description: NAV_ITEM_ALPS_SCORE_ANALYSIS_DESCRIPTION,
                icon: navALPSScoreAnalysisIcon,
                type: NavLinkType.INTERNAL,
                breadcrumbs: [
                    {
                        id: "CompetitiveResearchBr",
                        title: "Competitive Research",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    },
                    {
                        id: "AlpsScoreAnalysisBr",
                        title: "Alps Score Analysis",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    },
                    {
                        id: "ScoreTrendsBr",
                        title: "Score Trends",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    }
                ],
                tabs: {
                    title: "ALPS SCORE ANALYSIS",
                    icon: auditIcon,
                    navLinks: [
                        {
                            id: "alps-score-analysis-gap-analysis-url",
                            to: CR_ALPS_SCORE_ANALYSIS_GAP_ANALYSIS_URL,
                            title: "Gap Analysis",
                            type: NavLinkType.INTERNAL,
                            breadcrumbs: [
                                {
                                    id: "CompetitiveResearchBr",
                                    title: "Competitive Research",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "AlpsScoreAnalysisBr",
                                    title: "Alps Score Analysis",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "GapAnalysisBr",
                                    title: "Gap Analysis",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                }
                            ]
                        },
                        {
                            id: "alps-score-analysis-score-trends-url",
                            to: CR_ALPS_SCORE_ANALYSIS_SCORE_TRENDS_URL,
                            title: "Score Trends",
                            type: NavLinkType.INTERNAL,
                            breadcrumbs: [
                                {
                                    id: "CompetitiveResearchBr",
                                    title: "Competitive Research",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "AlpsScoreAnalysisBr",
                                    title: "Alps Score Analysis",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "ScoreTrendsBr",
                                    title: "Score Trends",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                }
                            ]
                        }
                    ]
                }
            },
            {
                id: "data-on-demand",
                to: CR_DATA_ON_DEMAND_URL,
                title: "DATA ON DEMAND",
                description: NAV_ITEM_DEMO_DESCRIPTION,
                icon: navShareOfVoiceIcon,
                isLocked: isTrial,
                type: NavLinkType.INTERNAL,
                breadcrumbs: [
                    {
                        id: "CompetitiveResearchBr",
                        title: "Competitive Research",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    },
                    {
                        id: "DataOnDemandBr",
                        title: "Data On Demand",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    }
                ],
                tabs: {
                    title: "",
                    icon: "",
                    navLinks: []
                }
            }
        ],
        breadcrumbs: [],
        tabs: {
            title: "",
            icon: "",
            navLinks: []
        }
    },
    {
        id: "optimization",
        to: OPTIMIZATION_URL,
        highlightWhen: [
            KEYWORD_GAP_ANALYSIS_URL,
            PAGE_OPTIMIZER_URL,
            SERP_RESULT_URL,
            KEYWORD_ANALYSIS_URL,
            SIMULATOR_URL,
            CONTENT_BRIEF,
            CONTENT_BRIEF_NEW,
            CONTENT_BRIEF_VIEW,
            KEYWORD_RESEARCH_TOOL_URL,
            KEYWORD_RESEARCH_HOME_URL
        ],
        title: "OPTIMIZATION TOOL",
        type: NavLinkType.INTERNAL,
        children: [
            {
                id: "/kwResearch",
                to: KEYWORD_RESEARCH_HOME_URL,
                title: "KEYWORD RESEARCH",
                description: NAV_ITEM_KEYWORD_RESEARCH_DESCRIPTION,
                icon: navKeywordResearchIcon,
                type: NavLinkType.INTERNAL,
                isHidden: false,
                isLocked: isTrial,
                isBeta: true,
                isNew: false,
                breadcrumbs: [
                    {
                        id: "optimization-br",
                        title: "Optimization Tool",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    },
                    {
                        id: "kwResearch-br",
                        title: "Keyword Research",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    },
                    {
                        id: "kwResearch-home-br",
                        title: "Home",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    }
                ],
                tabs: {
                    title: "",
                    icon: "",
                    navLinks: []
                }
            },
            {
                id: "keyword-gap-analysis",
                to: KEYWORD_GAP_ANALYSIS_URL,
                highlightWhen: [SERP_RESULT_URL, KEYWORD_ANALYSIS_URL, SIMULATOR_URL],
                title: "KEYWORD GAP ANALYSIS",
                description: NAV_ITEM_KEYWORD_GAP_ANALYSIS_DESCRIPTION,
                icon: navKeywordGapAnalysisIcon,
                type: NavLinkType.INTERNAL,
                breadcrumbs: [
                    // {
                    //     id: "home",
                    //     title: "Welcome!",
                    //     type: NavLinkType.INTERNAL,
                    //     to: HOME_URL
                    // },
                    {
                        id: "optimization-br",
                        title: "Optimization Tool",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    },
                    {
                        id: "keyword-gap-analysis-br",
                        title: "Keyword Gap Analysis",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    },
                    {
                        id: "home-br",
                        title: "Home",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    }
                ],
                tabs: {
                    title: "",
                    icon: "",
                    navLinks: []
                }
            },
            {
                id: "content-brief-url",
                to: CONTENT_BRIEF,
                title: "CONTENT BRIEF",
                description: NAV_ITEM_CONTENT_BRIEF_DESCRIPTION,
                icon: navContentBriefIcon,
                type: NavLinkType.INTERNAL,
                isNew: false,
                breadcrumbs: [
                    {
                        id: "optimization-br",
                        title: "Optimization Tool",
                        type: NavLinkType.INTERNAL,
                        to: KEYWORD_GAP_ANALYSIS_URL
                    },
                    {
                        id: "content-brief",
                        title: "Content Brief",
                        type: NavLinkType.INTERNAL,
                        to: HOME_URL
                    }
                ],
                tabs: {
                    title: "",
                    icon: "",
                    navLinks: []
                }
            },
            {
                id: "page-optimizer-url",
                to: PAGE_OPTIMIZER_URL,
                title: "PAGE SIMULATION",
                description: NAV_ITEM_PAGE_SIMULATION_DESCRIPTION,
                icon: navPageSimulationIcon,
                type: NavLinkType.INTERNAL,
                breadcrumbs: [
                    {
                        id: "optimization-br",
                        title: "Optimization Tool",
                        type: NavLinkType.INTERNAL,
                        to: KEYWORD_GAP_ANALYSIS_URL
                    },
                    {
                        id: "page-simulation-br",
                        title: "Page Simulation",
                        type: NavLinkType.INTERNAL,
                        to: HOME_URL
                    }
                ],
                tabs: {
                    title: "",
                    icon: "",
                    navLinks: []
                }
            }
        ],
        breadcrumbs: [],
        tabs: {
            title: "",
            icon: "",
            navLinks: []
        }
    },
    {
        id: "performance-reports",
        to: PRS_PERFORMANCE_REPORTS_URL,
        highlightWhen: [
            PRS_RANK_TRACKING_URL,
            PRS_RANK_TRACKING_KEYWORD_RANK_URL,
            PRS_RANK_TRACKING_PAGES_REPORT_URL,
            PR_GSC_KEYWORD_DETAILS_URL,
            PR_GSC_PAGE_ANALYSIS_URL,
            PR_GSC_URL,
            PRS_PERFORMANCE_REPORTS_URL,
            PRS_PROJECT_DASHBOARD_URL,
            PRS_PROJECT_DASHBOARD_PERFORMANCE_DASHBOARD_URL,
            PRS_PERFORMANCE_IMPACT_URL,
            PRS_PERFORMANCE_IMPACT_REPORT_URL
        ],
        title: "PERFORMANCE REPORTING",
        type: NavLinkType.INTERNAL,
        children: [
            {
                id: "performance-reports-project-dashboard",
                to: PRS_PROJECT_DASHBOARD_PERFORMANCE_DASHBOARD_URL,
                title: "PROJECT DASHBOARD",
                description: NAV_ITEM_PROJECT_DASHBOARD_DESCRIPTION,
                icon: navProjectDashboardIcon,
                type: NavLinkType.INTERNAL,
                isLocked: isTrial,
                breadcrumbs: [
                    {
                        id: "PerformanceReportsBr",
                        title: "Performance Reports",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    },
                    {
                        id: "ProjectDashboardBr",
                        title: "Project Dashboard",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    },
                    {
                        id: "ProjectDashboardPerformanceDashboardBr",
                        title: "Performance Dashboard",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    }
                ],
                tabs: {
                    title: "PROJECT DASHBOARD",
                    icon: auditIcon,
                    navLinks: [
                        {
                            id: "project-dashboard-performance-dashboard-url",
                            to: PRS_PROJECT_DASHBOARD_PERFORMANCE_DASHBOARD_URL,
                            title: "PERFORMANCE DASHBOARD",
                            type: NavLinkType.INTERNAL,
                            breadcrumbs: [
                                {
                                    id: "PerformanceReportsBr",
                                    title: "Performance Reports",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "ProjectDashboardBr",
                                    title: "Project Dashboard",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "ProjectDashboardPerformanceDashboardBr",
                                    title: "Performance Dashboard",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                }
                            ]
                        }
                    ]
                }
            },
            {
                id: "performance-reports-page-reports",
                to: PRS_RANK_TRACKING_PAGES_REPORT_URL,
                title: "RANK TRACKING",
                description: NAV_ITEM_RANK_TRACKING_DESCRIPTION,
                icon: navRankTrackingIcon,
                type: NavLinkType.INTERNAL,
                isLocked: isTrial,
                breadcrumbs: [
                    {
                        id: "PerformanceReportsBr",
                        title: "Performance Reports",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    },
                    {
                        id: "RankTrackingBr",
                        title: "Rank Tracking",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    },
                    {
                        id: "RankTrackingKwPageReportBr",
                        title: "Page Performance",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    }
                ],
                tabs: {
                    title: "RANK TRACKING",
                    icon: auditIcon,
                    navLinks: [
                        {
                            id: "performance-reports-page-report-url",
                            to: PRS_RANK_TRACKING_PAGES_REPORT_URL,
                            title: "Page Performance",
                            type: NavLinkType.INTERNAL,
                            breadcrumbs: [
                                {
                                    id: "PerformanceReportsBr",
                                    title: "Performance Reports",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "RankTrackingBr",
                                    title: "Rank Tracking",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "RankTrackingKwPageReportBr",
                                    title: "Page Performance",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                }
                            ]
                        },
                        {
                            id: "performance-reports-url",
                            to: PRS_RANK_TRACKING_KEYWORD_RANK_URL,
                            title: "Keyword Performance",
                            type: NavLinkType.INTERNAL,
                            breadcrumbs: [
                                {
                                    id: "PerformaceReportsBr",
                                    title: "Performance Reports",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "RankTrackingBr",
                                    title: "Rank Tracking",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "RankTrackingKwRankBr",
                                    title: "Keyword Performance",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                }
                            ]
                        }
                    ]
                }
            },
            {
                id: "performance-reports-keyword-rank",
                to: PRS_RANK_TRACKING_KEYWORD_RANK_URL,
                title: "RANK TRACKING",
                description: NAV_ITEM_RANK_TRACKING_DESCRIPTION,
                icon: navRankTrackingIcon,
                type: NavLinkType.INTERNAL,
                isHidden: true,
                isLocked: isTrial,
                breadcrumbs: [
                    {
                        id: "PerformanceReportsBr",
                        title: "Performance Reports",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    },
                    {
                        id: "RankTrackingBr",
                        title: "Rank Tracking",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    },
                    {
                        id: "RankTrackingKwRankBr",
                        title: "Keyword Performance",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    }
                ],
                tabs: {
                    title: "RANK TRACKING",
                    icon: auditIcon,
                    navLinks: [
                        {
                            id: "performance-reports-page-report-url",
                            to: PRS_RANK_TRACKING_PAGES_REPORT_URL,
                            title: "Page Performance",
                            type: NavLinkType.INTERNAL,
                            breadcrumbs: [
                                {
                                    id: "CompetitiveResearchBr",
                                    title: "Competitive Research",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "PerformanceReportBr",
                                    title: "Performance Reports",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "PerformanceReportKwPageReportBr",
                                    title: "Page Performance",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                }
                            ]
                        },
                        {
                            id: "performance-reports-url",
                            to: PRS_RANK_TRACKING_KEYWORD_RANK_URL,
                            title: "Keyword Performance",
                            type: NavLinkType.INTERNAL,
                            breadcrumbs: [
                                {
                                    id: "PerformanceReportsBr",
                                    title: "Performance Reports",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "RankTrackingBr",
                                    title: "Rank Tracking",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "RankTrackingKwRankBr",
                                    title: "Keyword Performance",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                }
                            ]
                        }
                    ]
                }
            },
            {
                id: "GSC-PAGE_ANALYSIS",
                to: PR_GSC_PAGE_ANALYSIS_URL,
                title: "GSC ANALYTICS",
                description: NAV_ITEM_GSC_ANALYTICS_DESCRIPTION,
                icon: navGSCAnalyticsIcon,
                isLocked: isTrial,
                type: NavLinkType.INTERNAL,
                isNew: false,
                breadcrumbs: [
                    {
                        id: "PerformanceReportsBr",
                        title: "Performance Reports",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    },
                    {
                        id: "gscBr",
                        title: "GSC Analytics",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    },
                    {
                        id: "gscPageAnalysisBr",
                        title: "Page Analysis",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    }
                ],
                tabs: {
                    title: "GSC ANALYTICS",
                    icon: auditIcon,
                    navLinks: [
                        {
                            id: "gsc-page-analysis-url",
                            to: PR_GSC_PAGE_ANALYSIS_URL,
                            title: "Page Analysis",
                            type: NavLinkType.INTERNAL,
                            breadcrumbs: [
                                {
                                    id: "PerformanceReportsBr",
                                    title: "Performance Reports",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "gscBr",
                                    title: "GSC Analytics",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "gscPageAnalysisBr",
                                    title: "Page Analysis",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                }
                            ]
                        },
                        {
                            id: "gsc-keyword-details-url",
                            to: PR_GSC_KEYWORD_DETAILS_URL,
                            title: "Keyword Details",
                            type: NavLinkType.INTERNAL,
                            breadcrumbs: [
                                {
                                    id: "PerformanceReportsBr",
                                    title: "Performance Reports",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "gscBr",
                                    title: "GSC Analytics",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "gscKeywordDetailsBr",
                                    title: "Keyword Details",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                }
                            ]
                        },
                        ...CUSTOM_GSC_TABS_LIST
                    ]
                }
            },
            {
                id: "GSC-KEYWORD_DETAILS",
                to: PR_GSC_KEYWORD_DETAILS_URL,
                isHidden: true,
                isLocked: isTrial,
                title: "GSC ANALYTICS",
                description: NAV_ITEM_GSC_ANALYTICS_DESCRIPTION,
                icon: navGSCAnalyticsIcon,
                type: NavLinkType.INTERNAL,
                breadcrumbs: [
                    {
                        id: "PerformanceReportsBr",
                        title: "Performance Reports",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    },
                    {
                        id: "gscBr",
                        title: "GSC Analytics",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    },
                    {
                        id: "gscKeywordDetailsBr",
                        title: "Keyword Details",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    }
                ],
                tabs: {
                    title: "GSC ANALYTICS",
                    icon: auditIcon,
                    navLinks: [
                        {
                            id: "gsc-page-analysis-url",
                            to: PR_GSC_PAGE_ANALYSIS_URL,
                            title: "Page Analysis",
                            type: NavLinkType.INTERNAL,
                            breadcrumbs: [
                                {
                                    id: "PerformanceReportsBr",
                                    title: "Performance Reports",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "gscBr",
                                    title: "GSC Analytics",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "gscPageAnalysisBr",
                                    title: "Page Analysis",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                }
                            ]
                        },
                        {
                            id: "gsc-keyword-details-url",
                            to: PR_GSC_KEYWORD_DETAILS_URL,
                            title: "Keyword Details",
                            type: NavLinkType.INTERNAL,
                            breadcrumbs: [
                                {
                                    id: "PerformanceReportsBr",
                                    title: "Performance Reports",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "gscBr",
                                    title: "GSC Analytics",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "gscKeywordDetailsBr",
                                    title: "Keyword Details",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                }
                            ]
                        },
                        ...CUSTOM_GSC_TABS_LIST
                    ]
                }
            },
            {
                id: "GSC-MASTERVIEW",
                to: PR_GSC_MASTERVIEW,
                isHidden: true,
                isLocked: isTrial,
                title: "GSC ANALYTICS",
                description: NAV_ITEM_GSC_ANALYTICS_DESCRIPTION,
                icon: navGSCAnalyticsIcon,
                type: NavLinkType.INTERNAL,
                breadcrumbs: [
                    {
                        id: "PerformanceReportsBr",
                        title: "Performance Reports",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    },
                    {
                        id: "gscBr",
                        title: "GSC Analytics",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    },
                    {
                        id: "gscMasterviewBr",
                        title: "Masterview",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    }
                ],
                tabs: {
                    title: "GSC ANALYTICS",
                    icon: auditIcon,
                    navLinks: [
                        {
                            id: "gsc-page-analysis-url",
                            to: PR_GSC_PAGE_ANALYSIS_URL,
                            title: "Page Analysis",
                            type: NavLinkType.INTERNAL,
                            breadcrumbs: [
                                {
                                    id: "PerformanceReportsBr",
                                    title: "Performance Reports",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "gscBr",
                                    title: "GSC Analytics",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "gscPageAnalysisBr",
                                    title: "Page Analysis",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                }
                            ]
                        },
                        {
                            id: "gsc-keyword-details-url",
                            to: PR_GSC_KEYWORD_DETAILS_URL,
                            title: "Keyword Details",
                            type: NavLinkType.INTERNAL,
                            breadcrumbs: [
                                {
                                    id: "PerformanceReportsBr",
                                    title: "Performance Reports",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "gscBr",
                                    title: "GSC Analytics",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "gscKeywordDetailsBr",
                                    title: "Keyword Details",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                }
                            ]
                        },
                        {
                            id: "gsc-masterview-url",
                            to: PR_GSC_MASTERVIEW,
                            title: "Masterview",
                            type: NavLinkType.INTERNAL,
                            breadcrumbs: [
                                {
                                    id: "PerformanceReportsBr",
                                    title: "Performance Reports",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "gscBr",
                                    title: "GSC Analytics",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "gscMasterviewBr",
                                    title: "Masterview",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                }
                            ]
                        },
                        {
                            id: "gsc-keyword-url-url",
                            to: PR_GSC_KEYWORD_URL,
                            title: "Keyword + URL",
                            type: NavLinkType.INTERNAL,
                            breadcrumbs: [
                                {
                                    id: "PerformanceReportsBr",
                                    title: "Performance Reports",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "gscBr",
                                    title: "GSC Analytics",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "gscKeywordURLBr",
                                    title: "Keyword + URL",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                }
                            ]
                        },
                        {
                            id: "gsc-keyword-only-url",
                            to: PR_GSC_KEYWORD_ONLY,
                            title: "Keyword Only",
                            type: NavLinkType.INTERNAL,
                            breadcrumbs: [
                                {
                                    id: "PerformanceReportsBr",
                                    title: "Performance Reports",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "gscBr",
                                    title: "GSC Analytics",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "gscKeywordOnlyBr",
                                    title: "Keyword Only",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                }
                            ]
                        },
                        {
                            id: "gsc-url-only-url",
                            to: PR_GSC_URL_ONLY,
                            title: "URL Only",
                            type: NavLinkType.INTERNAL,
                            breadcrumbs: [
                                {
                                    id: "PerformanceReportsBr",
                                    title: "Performance Reports",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "gscBr",
                                    title: "GSC Analytics",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "gscUrlOnlyBr",
                                    title: "URL Only",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                }
                            ]
                        }
                    ]
                }
            },
            {
                id: "GSC-KEYWORD_URL",
                to: PR_GSC_KEYWORD_URL,
                isHidden: true,
                isLocked: isTrial,
                title: "GSC ANALYTICS",
                description: NAV_ITEM_GSC_ANALYTICS_DESCRIPTION,
                icon: navGSCAnalyticsIcon,
                type: NavLinkType.INTERNAL,
                breadcrumbs: [
                    {
                        id: "PerformanceReportsBr",
                        title: "Performance Reports",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    },
                    {
                        id: "gscBr",
                        title: "GSC Analytics",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    },
                    {
                        id: "gscKeywordURLBr",
                        title: "Keyword + URL",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    }
                ],
                tabs: {
                    title: "GSC ANALYTICS",
                    icon: auditIcon,
                    navLinks: [
                        {
                            id: "gsc-page-analysis-url",
                            to: PR_GSC_PAGE_ANALYSIS_URL,
                            title: "Page Analysis",
                            type: NavLinkType.INTERNAL,
                            breadcrumbs: [
                                {
                                    id: "PerformanceReportsBr",
                                    title: "Performance Reports",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "gscBr",
                                    title: "GSC Analytics",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "gscPageAnalysisBr",
                                    title: "Page Analysis",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                }
                            ]
                        },
                        {
                            id: "gsc-keyword-details-url",
                            to: PR_GSC_KEYWORD_DETAILS_URL,
                            title: "Keyword Details",
                            type: NavLinkType.INTERNAL,
                            breadcrumbs: [
                                {
                                    id: "PerformanceReportsBr",
                                    title: "Performance Reports",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "gscBr",
                                    title: "GSC Analytics",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "gscKeywordDetailsBr",
                                    title: "Keyword Details",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                }
                            ]
                        },
                        {
                            id: "gsc-masterview-url",
                            to: PR_GSC_MASTERVIEW,
                            title: "Masterview",
                            type: NavLinkType.INTERNAL,
                            breadcrumbs: [
                                {
                                    id: "PerformanceReportsBr",
                                    title: "Performance Reports",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "gscBr",
                                    title: "GSC Analytics",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "gscMasterviewBr",
                                    title: "Masterview",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                }
                            ]
                        },
                        {
                            id: "gsc-keyword-url-url",
                            to: PR_GSC_KEYWORD_URL,
                            title: "Keyword + URL",
                            type: NavLinkType.INTERNAL,
                            breadcrumbs: [
                                {
                                    id: "PerformanceReportsBr",
                                    title: "Performance Reports",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "gscBr",
                                    title: "GSC Analytics",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "gscKeywordURLBr",
                                    title: "Keyword + URL",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                }
                            ]
                        },
                        {
                            id: "gsc-keyword-only-url",
                            to: PR_GSC_KEYWORD_ONLY,
                            title: "Keyword Only",
                            type: NavLinkType.INTERNAL,
                            breadcrumbs: [
                                {
                                    id: "PerformanceReportsBr",
                                    title: "Performance Reports",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "gscBr",
                                    title: "GSC Analytics",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "gscKeywordOnlyBr",
                                    title: "Keyword Only",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                }
                            ]
                        },
                        {
                            id: "gsc-url-only-url",
                            to: PR_GSC_URL_ONLY,
                            title: "URL Only",
                            type: NavLinkType.INTERNAL,
                            breadcrumbs: [
                                {
                                    id: "PerformanceReportsBr",
                                    title: "Performance Reports",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "gscBr",
                                    title: "GSC Analytics",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "gscUrlOnlyBr",
                                    title: "URL Only",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                }
                            ]
                        }
                    ]
                }
            },
            {
                id: "GSC-KEYWORD_ONLY",
                to: PR_GSC_KEYWORD_ONLY,
                isHidden: true,
                isLocked: isTrial,
                title: "GSC ANALYTICS",
                description: NAV_ITEM_GSC_ANALYTICS_DESCRIPTION,
                icon: navGSCAnalyticsIcon,
                type: NavLinkType.INTERNAL,
                breadcrumbs: [
                    {
                        id: "PerformanceReportsBr",
                        title: "Performance Reports",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    },
                    {
                        id: "gscBr",
                        title: "GSC Analytics",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    },
                    {
                        id: "gscKeywordOnlyBr",
                        title: "Keyword Only",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    }
                ],
                tabs: {
                    title: "GSC ANALYTICS",
                    icon: auditIcon,
                    navLinks: [
                        {
                            id: "gsc-page-analysis-url",
                            to: PR_GSC_PAGE_ANALYSIS_URL,
                            title: "Page Analysis",
                            type: NavLinkType.INTERNAL,
                            breadcrumbs: [
                                {
                                    id: "PerformanceReportsBr",
                                    title: "Performance Reports",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "gscBr",
                                    title: "GSC Analytics",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "gscPageAnalysisBr",
                                    title: "Page Analysis",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                }
                            ]
                        },
                        {
                            id: "gsc-keyword-details-url",
                            to: PR_GSC_KEYWORD_DETAILS_URL,
                            title: "Keyword Details",
                            type: NavLinkType.INTERNAL,
                            breadcrumbs: [
                                {
                                    id: "PerformanceReportsBr",
                                    title: "Performance Reports",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "gscBr",
                                    title: "GSC Analytics",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "gscKeywordDetailsBr",
                                    title: "Keyword Details",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                }
                            ]
                        },
                        {
                            id: "gsc-masterview-url",
                            to: PR_GSC_MASTERVIEW,
                            title: "Masterview",
                            type: NavLinkType.INTERNAL,
                            breadcrumbs: [
                                {
                                    id: "PerformanceReportsBr",
                                    title: "Performance Reports",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "gscBr",
                                    title: "GSC Analytics",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "gscMasterviewBr",
                                    title: "Masterview",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                }
                            ]
                        },
                        {
                            id: "gsc-keyword-url-url",
                            to: PR_GSC_KEYWORD_URL,
                            title: "Keyword + URL",
                            type: NavLinkType.INTERNAL,
                            breadcrumbs: [
                                {
                                    id: "PerformanceReportsBr",
                                    title: "Performance Reports",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "gscBr",
                                    title: "GSC Analytics",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "gscKeywordURLBr",
                                    title: "Keyword + URL",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                }
                            ]
                        },
                        {
                            id: "gsc-keyword-only-url",
                            to: PR_GSC_KEYWORD_ONLY,
                            title: "Keyword Only",
                            type: NavLinkType.INTERNAL,
                            breadcrumbs: [
                                {
                                    id: "PerformanceReportsBr",
                                    title: "Performance Reports",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "gscBr",
                                    title: "GSC Analytics",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "gscKeywordOnlyBr",
                                    title: "Keyword Only",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                }
                            ]
                        },
                        {
                            id: "gsc-url-only-url",
                            to: PR_GSC_URL_ONLY,
                            title: "URL Only",
                            type: NavLinkType.INTERNAL,
                            breadcrumbs: [
                                {
                                    id: "PerformanceReportsBr",
                                    title: "Performance Reports",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "gscBr",
                                    title: "GSC Analytics",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "gscUrlOnlyBr",
                                    title: "URL Only",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                }
                            ]
                        }
                    ]
                }
            },
            {
                id: "GSC-URL_ONLY",
                to: PR_GSC_URL_ONLY,
                isHidden: true,
                isLocked: isTrial,
                title: "GSC ANALYTICS",
                description: NAV_ITEM_GSC_ANALYTICS_DESCRIPTION,
                icon: navGSCAnalyticsIcon,
                type: NavLinkType.INTERNAL,
                breadcrumbs: [
                    {
                        id: "PerformanceReportsBr",
                        title: "Performance Reports",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    },
                    {
                        id: "gscBr",
                        title: "GSC Analytics",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    },
                    {
                        id: "gscUrlOnlyBr",
                        title: "URL Only",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    }
                ],
                tabs: {
                    title: "GSC ANALYTICS",
                    icon: auditIcon,
                    navLinks: [
                        {
                            id: "gsc-page-analysis-url",
                            to: PR_GSC_PAGE_ANALYSIS_URL,
                            title: "Page Analysis",
                            type: NavLinkType.INTERNAL,
                            breadcrumbs: [
                                {
                                    id: "PerformanceReportsBr",
                                    title: "Performance Reports",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "gscBr",
                                    title: "GSC Analytics",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "gscPageAnalysisBr",
                                    title: "Page Analysis",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                }
                            ]
                        },
                        {
                            id: "gsc-keyword-details-url",
                            to: PR_GSC_KEYWORD_DETAILS_URL,
                            title: "Keyword Details",
                            type: NavLinkType.INTERNAL,
                            breadcrumbs: [
                                {
                                    id: "PerformanceReportsBr",
                                    title: "Performance Reports",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "gscBr",
                                    title: "GSC Analytics",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "gscKeywordDetailsBr",
                                    title: "Keyword Details",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                }
                            ]
                        },
                        {
                            id: "gsc-masterview-url",
                            to: PR_GSC_MASTERVIEW,
                            title: "Masterview",
                            type: NavLinkType.INTERNAL,
                            breadcrumbs: [
                                {
                                    id: "PerformanceReportsBr",
                                    title: "Performance Reports",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "gscBr",
                                    title: "GSC Analytics",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "gscMasterviewBr",
                                    title: "Masterview",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                }
                            ]
                        },
                        {
                            id: "gsc-keyword-url-url",
                            to: PR_GSC_KEYWORD_URL,
                            title: "Keyword + URL",
                            type: NavLinkType.INTERNAL,
                            breadcrumbs: [
                                {
                                    id: "PerformanceReportsBr",
                                    title: "Performance Reports",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "gscBr",
                                    title: "GSC Analytics",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "gscKeywordURLBr",
                                    title: "Keyword + URL",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                }
                            ]
                        },
                        {
                            id: "gsc-keyword-only-url",
                            to: PR_GSC_KEYWORD_ONLY,
                            title: "Keyword Only",
                            type: NavLinkType.INTERNAL,
                            breadcrumbs: [
                                {
                                    id: "PerformanceReportsBr",
                                    title: "Performance Reports",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "gscBr",
                                    title: "GSC Analytics",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "gscKeywordOnlyBr",
                                    title: "Keyword Only",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                }
                            ]
                        },
                        {
                            id: "gsc-url-only-url",
                            to: PR_GSC_URL_ONLY,
                            title: "URL Only",
                            type: NavLinkType.INTERNAL,
                            breadcrumbs: [
                                {
                                    id: "PerformanceReportsBr",
                                    title: "Performance Reports",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "gscBr",
                                    title: "GSC Analytics",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "gscUrlOnlyBr",
                                    title: "URL Only",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                }
                            ]
                        }
                    ]
                }
            },
            {
                id: "performance-reports-performance-impact",
                to: PRS_PERFORMANCE_IMPACT_URL,
                title: "PAGE VISIBILITY TRACKER",
                description: "Configure and Track Performance Metrics for Your Published Pages",
                icon: navProjectDashboardIcon,
                type: NavLinkType.INTERNAL,
                isLocked: isTrial,
                breadcrumbs: [
                    {
                        id: "PerformanceReportsBr",
                        title: "Performance Reports",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    },
                    {
                        id: "PageVisibilityTrackerBr",
                        title: "Page Visibility Tracker",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    }
                ],
                tabs: {
                    title: "Page Visibility Tracker",
                    icon: auditIcon,
                    navLinks: []
                }
            },
            {
                id: "performance-reports-performance-reports",
                to: PRS_PERFORMANCE_IMPACT_REPORT_URL,
                isHidden: true,
                title: "PAGE VISIBILITY TRACKER",
                description: "Configure and Track Performance Metrics for Your Published Pages",
                icon: navProjectDashboardIcon,
                type: NavLinkType.INTERNAL,
                isLocked: isTrial,
                breadcrumbs: [
                    {
                        id: "PerformanceReportsBr",
                        title: "Performance Reports",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    },
                    {
                        id: "PageVisibilityTrackerReportBr",
                        title: "Page Visibility Tracker Report",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    }
                ],
                tabs: {
                    title: "Page Visibility Tracker",
                    icon: auditIcon,
                    navLinks: []
                }
            },
            ...SERP_FEATURE_COVERAGE_TAB,
        ],
        breadcrumbs: [],
        tabs: {
            title: "",
            icon: "",
            navLinks: []
        }
    },
    {
        id: "custom-reporting",
        to: CUSTOM_REPORTING_URL,
        highlightWhen: [CUSTOM_REPORTING_EDIT_URL, CUSTOM_REPORTING_VIEW_URL],
        title: "CUSTOM DASHBOARD",
        type: NavLinkType.INTERNAL,
        children: [
            {
                id: "custom-reporting-edit",
                to: CUSTOM_REPORTING_EDIT_URL,
                title: "DASHBOARDS",
                description: "Create and view custom dashboards for your projects",
                icon: navShareOfVoiceIcon,
                type: NavLinkType.INTERNAL,
                isLocked: isTrial,
                isHidden: true,
                breadcrumbs: [
                    {
                        id: "CustomReportingBr",
                        title: "Custom Dashboard",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    },
                    {
                        id: "EditBr",
                        title: "Edit",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    }
                ],
                tabs: {
                    title: "CUSTOM DASHBOARD",
                    icon: auditIcon,
                    navLinks: [
                        {
                            id: "CustomReportingViewNavlink",
                            to: CUSTOM_REPORTING_VIEW_URL,
                            title: "View",
                            type: NavLinkType.INTERNAL,
                            breadcrumbs: [
                                {
                                    id: "CustomReportingBr",
                                    title: "Custom Dashboard",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "CustomReportingViewBr",
                                    title: "View",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                }
                            ]
                        },
                        {
                            id: "CustomReportingEditNavlink",
                            to: CUSTOM_REPORTING_EDIT_URL,
                            title: "Edit",
                            type: NavLinkType.INTERNAL,
                            breadcrumbs: [
                                {
                                    id: "CustomReportingBr",
                                    title: "Custom Dashboard",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "CustomReportingEditBr",
                                    title: "Edit",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                }
                            ]
                        }
                    ]
                }
            },
            {
                id: "custom-reporting-view",
                to: CUSTOM_REPORTING_VIEW_URL,
                title: "DASHBOARDS",
                description: "Create and view custom dashboards for your projects",
                icon: navShareOfVoiceIcon,
                type: NavLinkType.INTERNAL,
                isLocked: isTrial,
                breadcrumbs: [
                    {
                        id: "CustomReportingBr",
                        title: "Custom Dashboard",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    },
                    {
                        id: "ReportsBr",
                        title: "View",
                        type: NavLinkType.INTERNAL,
                        to: ""
                    }
                ],
                tabs: {
                    title: "CUSTOM DASHBOARD",
                    icon: auditIcon,
                    navLinks: [
                        {
                            id: "CustomReportingViewNavlink",
                            to: CUSTOM_REPORTING_VIEW_URL,
                            title: "View",
                            type: NavLinkType.INTERNAL,
                            breadcrumbs: [
                                {
                                    id: "CustomReportingBr",
                                    title: "Custom Dashboard",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "CustomReportingViewBr",
                                    title: "View",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                }
                            ]
                        },
                        {
                            id: "CustomReportingEditNavlink",
                            to: CUSTOM_REPORTING_EDIT_URL,
                            title: "Edit",
                            type: NavLinkType.INTERNAL,
                            breadcrumbs: [
                                {
                                    id: "CustomReportingBr",
                                    title: "Custom Dashboard",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                },
                                {
                                    id: "CustomReportingEditBr",
                                    title: "Edit",
                                    type: NavLinkType.INTERNAL,
                                    to: ""
                                }
                            ]
                        }
                    ]
                }
            }
        ],
        breadcrumbs: [],
        tabs: {
            title: "",
            icon: "",
            navLinks: []
        }
    },
    {
        id: "kwResearch-tool",
        to: KEYWORD_RESEARCH_TOOL_URL,
        title: "KEYWORD RESEARCH",
        type: NavLinkType.INTERNAL,
        isHidden: true,
        isLocked: isTrial,
        children: [],
        breadcrumbs: [
            {
                id: "CompetitiveResearchBr",
                title: "Competitive Research",
                type: NavLinkType.INTERNAL,
                to: ""
            },
            {
                id: "kwResearch-br",
                title: "Keyword Research",
                type: NavLinkType.INTERNAL,
                to: KEYWORD_RESEARCH_HOME_URL
            },
            {
                id: "kwResearch-tool-br",
                title: "Keyword Research Tool",
                type: NavLinkType.INTERNAL,
                to: ""
            }
        ],
        tabs: {
            title: "",
            icon: "",
            navLinks: []
        }
    },
    {
        id: "content-brief-new",
        to: CONTENT_BRIEF_NEW,
        title: "CONTENT BRIEF NEW",
        type: NavLinkType.INTERNAL,
        isHidden: true,
        children: [],
        breadcrumbs: [
            {
                id: "optimization-br",
                title: "Optimization Tool",
                type: NavLinkType.INTERNAL,
                to: KEYWORD_GAP_ANALYSIS_URL
            },
            {
                id: "content-brief",
                title: "Content Brief",
                type: NavLinkType.INTERNAL,
                to: CONTENT_BRIEF
            },
            {
                id: "content-brief-new",
                title: "New Content Brief",
                type: NavLinkType.INTERNAL,
                to: ""
            }
        ],
        tabs: {
            title: "",
            icon: "",
            navLinks: []
        }
    },
    {
        id: "content-brief-view",
        to: CONTENT_BRIEF_VIEW,
        title: "CONTENT BRIEF VIEW",
        type: NavLinkType.INTERNAL,
        isHidden: true,
        children: [],
        breadcrumbs: [
            {
                id: "optimization-br",
                title: "Optimization Tool",
                type: NavLinkType.INTERNAL,
                to: KEYWORD_GAP_ANALYSIS_URL
            },
            {
                id: "content-brief",
                title: "Content Brief",
                type: NavLinkType.INTERNAL,
                to: CONTENT_BRIEF
            },
            {
                id: "content-brief-view",
                title: "Content Brief",
                type: NavLinkType.INTERNAL,
                to: ""
            }
        ],
        tabs: {
            title: "",
            icon: "",
            navLinks: []
        }
    },
    {
        id: "serp-result",
        to: SERP_RESULT_URL,
        title: "SERP RESULT",
        type: NavLinkType.INTERNAL,
        isHidden: true,
        isLocked: isTrial,
        children: [],
        breadcrumbs: [
            {
                id: "optimization-br",
                title: "Optimization Tool",
                type: NavLinkType.INTERNAL,
                to: ""
            },
            {
                id: "keyword-gap-analysis-br",
                title: "Keyword Gap Analysis",
                type: NavLinkType.INTERNAL,
                to: ""
            },
            {
                id: "results",
                title: "Results",
                type: NavLinkType.INTERNAL,
                to: ""
            }
        ],
        tabs: {
            title: "",
            icon: "",
            navLinks: []
        }
    },
    {
        id: "simulation",
        to: SIMULATOR_URL,
        title: "SIMULATION",
        type: NavLinkType.INTERNAL,
        isHidden: true,
        isLocked: isTrial,
        children: [],
        breadcrumbs: [
            {
                id: "optimization-br",
                title: "Optimization Tool",
                type: NavLinkType.INTERNAL,
                to: KEYWORD_GAP_ANALYSIS_URL
            },
            {
                id: "page-simulation-br",
                title: "Page Simulation",
                type: NavLinkType.INTERNAL,
                to: ""
            },
            {
                id: "simulator",
                title: "Simulator",
                type: NavLinkType.INTERNAL,
                to: ""
            }
        ],
        tabs: {
            title: "",
            icon: "",
            navLinks: []
        }
    },
    {
        id: "keyword-analysis",
        to: KEYWORD_ANALYSIS_URL,
        title: "KEYWORD GAP ANALYSIS",
        type: NavLinkType.INTERNAL,
        isHidden: true,
        isLocked: isTrial,
        children: [],
        breadcrumbs: [
            {
                id: "optimization-br",
                title: "Optimization Tool",
                type: NavLinkType.INTERNAL,
                to: KEYWORD_GAP_ANALYSIS_URL
            },
            {
                id: "keyword-gap-analysis-br",
                title: "Keyword Gap Analysis",
                type: NavLinkType.INTERNAL,
                to: ""
            }
        ],
        tabs: {
            title: "",
            icon: "",
            navLinks: []
        }
    }
];

export default PrimaryNavList;
