import { KeyWordAnalysisReducerInterface } from "../../beta/key-word-analysis/ducks/types";
import { UserSubscriptionReducerInterface } from "../../user-subscription/ducks/types";
import { SimulatorReducerInterface as SimulatorReducerInterfaceBeta } from "../../beta/simulator/ducks/types";
import { SerpResultReducerInterface } from "../../beta/serp-result/ducks/types";
import { GetSaveHistoryPayloadInterface, RoutesReducerInterface } from "../../routes/ducks/types";
import { PageOptimizerReducerInterface } from "../../beta/page-optimizer/ducks/types";
import { MessageInternalPropsInterface } from "../styledComponents/general-message/messaging-provider-hoc";
import { CompetitiveIntelligenceReducerInterface } from "../../competitive-intelligence/ducks/types";
import { KwResearchReducerInterface } from "../../kw-research/ducks/types";
import { OptionTypeInterface, OptionValueType } from "../styledComponents/drop-down/types";
import { PageContentBriefReducerInterface } from "../../content-brief/ducks/types";
import { ContentBriefNewReducerInterface } from "../../content-brief-new/ducks/types";
import { ContentBriefViewReducerInterface } from "../../content-brief-view/ducks/types";
import { DodReducerInterface } from "../../data-on-demand/ducks/types";
import { SuggestedKeywordReducerInterface } from "../../suggested-keywords/ducks/types";
import { MultiKeywordReducerInterface as MultiKeywordReducerInterfaceBeta } from "../../beta/simulator/Components/AddModifyKeyword/ducks/types";
import { AutoLoginReducerInterface } from "../../auto-login/ducks/types";
import { ProjectSetupReducerInterface } from "../../project-setup/ducks/types";
import { EmailVerificationReducerInterface } from "../../email-verification/ducks/types";
import { CustomReportingReducerInterface } from "../../custom-reporting/ducks/types";
import { SimulatorAuthorityTechnicalInterface } from "../../beta/simulator/Components/ducks/types";
import { RecommendationReducerInterface } from "../../beta/simulator/Components/ContentOptimization/SummaryComponent/ducks/types";
import { CompetitiveAnalysisReducerInterface } from "../../beta/simulator/Components/CompetitiveAnalysis/ducks/types";
import { PerformanceImpactReducerInterface } from "src/performance-impact/ducks/types";
import { UserAccountReducerInterface } from "../../user_account/ducks/types";
import { PackDataReducerInterface } from "src/serp-dashboard/ducks/types";

export enum BrandType {
    Branded = "branded",
    Non_branded = "non_branded"
}
export enum VARIANTTYPE {
    PRIMARY = "PRIMARY",
    SECONDARY = "SECONDARY"
}
export interface COPYInterface {
    [key: string]: string;
}
export interface InlineValidationInterface {
    isValid: boolean;
    message: string;
}

export interface KeywordLimitInterface {
    [key: string]: number;
}

export interface RelatedKwSseEventInterface {
    receive_related_kwds: string;
    req_completed: string;
    req_failed: string;
    error: string;
}

export interface ApplicationStateInterface {
    context: ContextReducerInterface;
    app: AppReducerInterface;
    // simulator: SimulatorReducerInterfaceBeta;
    // serpResult: SerpResultReducerInterface;
    dataOnDemand: DodReducerInterface;
    // keyWordAnalysis: KeyWordAnalysisReducerInterface;
    userSubscription: UserSubscriptionReducerInterface;
    // multiKeyword: MultiKeywordReducerInterface;
    routes: RoutesReducerInterface;
    // pageOptimizer: PageOptimizerReducerInterface;
    competitiveIntelligence: CompetitiveIntelligenceReducerInterface;
    customReporting: CustomReportingReducerInterface;
    keyWordResearch: KwResearchReducerInterface;
    performanceImpact: PerformanceImpactReducerInterface;
    // beta reducers
    simulatorBeta: SimulatorReducerInterfaceBeta & SimulatorAuthorityTechnicalInterface & CompetitiveAnalysisReducerInterface;
    multiKeywordBeta: MultiKeywordReducerInterfaceBeta;
    keyWordAnalysisBeta: KeyWordAnalysisReducerInterface;
    pageOptimizerBeta: PageOptimizerReducerInterface;
    serpResultBeta: SerpResultReducerInterface;
    contentBrief: PageContentBriefReducerInterface;
    contentBriefNew: ContentBriefNewReducerInterface;
    contentBriefView: ContentBriefViewReducerInterface;
    suggestedKeyword: SuggestedKeywordReducerInterface;
    autoLogin: AutoLoginReducerInterface;
    projectSetup: ProjectSetupReducerInterface;
    recommendation: RecommendationReducerInterface;
    emailVreification: EmailVerificationReducerInterface;
    userAccount: UserAccountReducerInterface;
    packData: PackDataReducerInterface;
}
export interface ContextReducerInterface {
    user_email?: string;
    uniqueDomainUrl?: string;
    selectedDomain?: string;
    projectDomain?: string;
    domainAliasUrl?: string;
    selectedKeyword?: string;
    selectedLocale?: string;
    projectId?: number;
    projectName?: string;
    isMobileProject?: boolean;
    isStandardProject?: boolean;
    userId?: string;
    tenantCode?: string;
    username?: string;
    flowType?: FlowTypeInterface;
    featureAddons?: string[];
    selectedSID?: string;
    isEnableRunSimulation?: boolean;
    // tslint:disable-next-line: no-any
    projectsList?: ProjectListInterface[];
    keywordLimit?: number;
    domainLimit?: number;
}

export interface ProjectListInterface {
    [key: string]: OptionalValueInterface | null;
}

export interface LocalesListInterface {
    code: string;
    country: string;
    language: string;
    name: string;
}

export interface SerpListResponseInterface {
    ctr: number;
    pack_type: string;
    url: string;
    organic_rank: number;
    blended_rank: number;
    traffic: number;
    is_organic: boolean;
    is_my_domain: boolean;
    original_url?: null | string;
}

export interface SerpResponseInterface extends SerpListResponseInterface {
    status: string;
}
// SerpResponseInterface
export interface MessageInterface {
    type: MessageType;
    title: string;
    message: string;
    autoClose: boolean;
    linkText?: string;
    onClickLinkText?(): void;
}
export interface AppReducerInterface {
    enableViewALPSScore: boolean;
    serpList: SerpResponseInterface[];
    requestId?: string;
    serpProcessedStatus: SerpListProgressStatusType;
    allCompetitorStatus: SerpListProgressStatusType;
    serpSearchVolume?: number;
    serpTopRank?: number;
    serpPacks: string[];
    clubbedUrl: CompetitorAndTargetURLsListInterface;
    serp_date: string;

    target_urls: CompetitorAndTargetURLsListInterface;
    selectedTargetUrl?: string;
    competitor_urls: CompetitorAndTargetURLsListInterface;
    isProjectContext: boolean;
    isLoading: boolean;
    locales: LocaleInterface[];
    is_url_live: boolean;
    fileUpload_requestId?: string;
    fileName?: string;
    insightOpenModalStatus?: boolean;
}
export interface LocaleInterface {
    code: string;
    country: string;
    language: string;
    name: string;
}
export interface SelectListInterface {
    value: string;
    label: string;
}
export interface RadioInterface {
    value: boolean;
    label: string;
}
export interface LegendInterface {
    symbolColor?: string;
    symbolText: string;
}

export interface ScoreHeaderInterface {
    id: string | number;
    isActive: boolean;
    title: string;
    subtitle: string;
    classProp?: string;
    onClickCallBack(id: string | number): void;
}

export interface KeywordSearchCallbackInterface {
    keyword: string;
    locale: string;
    req_id: string;
}

/** App Enum */
export enum MessageType {
    SUCCESS = "SUCCESS",
    ERROR = "ERROR",
    INFO = "INFO",
    WARNING = "WARNING"
}
export enum CategoryType {
    DEFAULT = "default",
    ALPS = "alps",
    PAGE_ASSESSMENT = "page_assessment",
    COMP_ANALYSIS = "comp_analysis",
    CONTENT = "content",
    AUTHORITY = "authority",
    TECHNICAL = "technical"
}

export enum ImportanceLevelType {
    LOW = "Low",
    VERY_LOW = "Very Low",
    VERY_HIGH = "Very High",
    HIGH = "High",
    MEDIUM = "Medium",
    EMPTY = ""
}

export enum SubscriptionNavigationType {
    USAGE = "USAGE",
    PROFILE = "PROFILE",
    BILLING = "BILLING",
    FAQ = "FAQ"
}
export enum PerformanceType {
    TRAFFIC = "traffic",
    ORGANIC_RANK = "organic_rank"
}
export enum DeltaPerformanceType {
    DELTA_TRAFFIC = "delta_traffic"
}
export enum BucketType {
    HIGH = "high",
    MEDIUM = "medium",
    LOW = "low"
}

export enum ImpactBucketType {
    INCREASED = "increased",
    DECREASED = "decreased"
}


export enum ContentSimulatorModelScoreListTypeBeta {
    H1_SCORE = "h1_score",
    H2_SCORE = "h2_score",
    H3_SCORE = "h3_score",
    META_DESCRIPTION_SCORE = "meta_description_score",
    VISIBLE_CONTENT_SCORE = "visible_content_score",
    TITLE_SCORE = "title_score",
    CATEGORY_SCORES = "category_scores",
    URL_SCORE = "url_score",
    ORGANIC_RANK = "organic_rank",
    READABILITY_GRADE = "readability_grade"
}


export enum OnPageElementsType {
    MISSING_H1 = "missing_h1",
    MISSING_H2 = "missing_h2",
    META_DESCRIPTION = "meta_description",
    TITLE = "title",
    URL = "url",
    STRIPPED_PAGE = "stripped_page"
}

export enum ALPSScoreSubCategoryType {
    CONTENT_SCORE = "content_score",
    AUTHORITY_SCORE = "authority_score",
    TECHNICAL_SCORE = "technical_score"
}
export enum ComponentInformationType {
    CONTENT_SCORE = "content_score",
    CONTENT_SCORE_IMPACT_BUCKET = "content_score_impact_bucket",
    AUTHORITY_SCORE = "authority_score",
    AUTHORITY_SCORE_IMPACT_BUCKET = "authority_score_impact_bucket",
    TECHNICAL_SCORE = "technical_score",
    TECHNICAL_SCORE_IMPACT_BUCKET = "technical_score_impact_bucket",
    IS_PRESENT = "is_present",
    LENGTH = "length",
    COUNT = "count",
    WORD_COUNT = "word_count",
    SCORES_BUCKET = "scores_bucket",
    ORGANIC_RANK_BUCKET = "organic_rank_bucket",
    WORD_FREQUENCY_DISTRIBUTION = "word_frequency_distribution",
    PHRASE_MATCHES = "phrase_frequency_distribution"
}
export interface TabsHeaderInterface {
    displayName: string;
    count?: number;
    id: CategoryType;
    isActive: boolean;
}

export interface ImportanceColorSchemeInterface {
    hexCode: string;
    color: string;
    importance: string;
}

export interface FilterListInterface {
    classProp: string;
    isChecked: boolean;
    label: string;
    value: string;
    disabled: boolean;
}

export interface SortingOptionsInterface extends SelectListInterface {
    default: boolean;
    order: string[];
}

/** app level Request Pram Interface */
export interface InitiateRequestForRequestIdRequestInterface extends NotifyErrorInterface {
    keyword: string;
    search_url: string;
    locale: string;
    isBeta?: boolean;
    project_id: number | null;
    tenant_code: string;
}

export interface SubscriptionNavigationOptionInterface {
    id: SubscriptionNavigationType;
    label: string;
    isActive: boolean;
}

export interface CalculatedDateInterface {
    Y: number;
    M: number;
    D: number;
}

export interface ApiPathInterface {
    [key: string]: string;
}

export interface ParserInterface {
    tenantCode: string;
    projectId?: number;
}

export interface CompetitorAndTargetURLsListValuesInterface {
    start_time: string;
    last_updated: string;
    status: SerpListProgressStatusType;
    rank: number;
}

export interface CompetitorAndTargetURLsListInterface {
    [key: string]: CompetitorAndTargetURLsListValuesInterface;
}

export interface FormattedUrlRankListInterface {
    url: string;
    rank: number;
}
export enum UrlDomainType {
    DOMAIN = "domain",
    URL = "url"
}
export enum FooterLinkType {
    DEV = "dev",
    PROD = "prod",
    QA = "qa",
    UAT = "uat"
}

export type FooterLinkInterface = {
    [key in FooterLinkType]: COPYInterface;
};
export enum SerpListProgressStatusType {
    QUEUED = "QUEUED",
    STARTED = "STARTED",
    DONE = "DONE",
    FAILED = "FAILED",
    ERROR = "ERROR",
    DEFAULT = "DEFAULT"
}

// tslint:disable-next-line: no-any
export type StyledComponentStylesType = any;

export enum AppliedFilterSort {
    FILTER = "filter",
    SORT = "sort"
}

export interface AppliedFilterSortInterface {
    [AppliedFilterSort.SORT]: string;
    [AppliedFilterSort.FILTER]: string[];
}
export interface FilterSortInterface {
    type: AppliedFilterSort;
    value: string | undefined | string[];
}
export interface MultiKeywordRequestIdsApiResponseInterface {
    [key: string]: string;
}

export interface MultiKeywordTrackRequestApiRequestIdStatusResponseInterface {
    id?: string;
    keywordName?: string;
    completion_status: SerpListProgressStatusType;
    is_my_url_processed: boolean;
    percent_completed: number;
    my_url_status: SerpListProgressStatusType;
}

export interface MultiKeywordTrackRequestApiRequestIdsResponseInterface {
    [key: string]: MultiKeywordTrackRequestApiRequestIdStatusResponseInterface;
}

export interface KeywordRequestIdsInterface {
    [key: string]: string;
}

export enum FlowTypeInterface {
    RANK_SIMULATOR = "Rank",
    PAGE_OPTIMIZER = "Page",
    PAGE_CONTENTBRIEF = "Content",
    PAGE_KW_RESEARCH = "KW_Research"
}

// Project details response interface

export interface ProjectDetailsResponseInterface {
    data: ProjectMetaDataInResponseInterface;
    request_id: string;
}
export interface ProjectMetaDataInResponseInterface {
    competitor_domains: string[];
    project_name: string;
    project_domain: string[];
    is_standard_project: boolean;
    project_locale: string;
    has_project: boolean;
    sub_domains: string[];
    is_mobile_project: boolean;
    projectId?: number;
}

export interface NotifyErrorInterface {
    needToHandleError?: boolean;
    isSpecificError?: boolean;
    errorList?: ErrorType[];
    notifyErrorMethod?(args: MessageInternalPropsInterface): void;
}

export interface ErrorType {
    id: number;
    status: number | string;
    errMsg: string;
}

export interface SaveSimulationHistory {
    description: string;
    create_by: string;
    keywords: string[];
    alias_url?: string;
    url: string;
    create_date: string;
    sID: string;
    is_url_live?: boolean;
    tenant_code: string;
    modify_by: string;
    modify_date: string;
    project_id?: number;
    save_type: string;
    url_hash: string;
    is_browser_mode?: boolean;
    is_ai_generated_content?: number;
    user_prompt?: string;
}

export interface MainPaginationInterface {
    count: number;
    prev_page: number | null;
    curr_page: number;
    next_page: number | null;
    total_pages: number;
    data_list: SaveSimulationHistory[] | OptionTypeInterface[];
}

export interface SimulationHistoryResponseInterface {
    count: number;
    prev_page: number | null;
    curr_page: number;
    next_page: number | null;
    total_pages: number;
    sim_list?: SaveSimulationHistory[];
}

export interface ProjectKeywordListInterface {
    count: number;
    prev_page: number | null;
    curr_page: number;
    next_page: number | null;
    total_pages: number;
    keywords: OptionTypeInterface[];
}

export interface SimulationHistoryTableInterface {
    Table_Heading: string;
    Search_URL: string;
    Search_Placeholder: string;
    URL: string;
    Description: string;
    Type: string;
    View: string;
    Created_By: string;
    Created_Date: string;
    Last_Modified_By: string;
    Last_modified_Date: string;
    Keyword: string;
    Existing_Content: string;
    New_Content: string;
    Simulations_not_available: string;
    Matching_results_found: string;
    More: string;
    Action: string;
    AI_Content: string;
}
export interface RelatedKwSseEventInterface {
    receive_related_kwds: string;
    req_completed: string;
    req_failed: string;
    error: string;
}
export interface RelatedKwInititeApiTypeInterface {
    keyword: string;
    url: string;
    domain: string;
}
export interface KwResearchInputTypesInterface {
    keyword: string;
    url: string;
    domain: string;
}
export interface PowerBiTargetInterface {
    table: string;
    column: string;
}
export interface PowerBiPayloadFilterInterface {
    $schema: string;
    target: PowerBiTargetInterface;
    operator: string;
    values: OptionValueType[];
    filterType: number;
    requireSingleSelection: boolean;
}
export interface PowerBiPayloadSlicerInterface {
    selector: {
        target: PowerBiTargetInterface;
        $schema: string;
    };
    state: {
        filters: PowerBiPayloadFilterInterface[];
    };
}
export interface OptionalValueInterface {
    [key: string]: string | undefined | number | boolean;
}

export enum EditorToolbarInterface {
    FILTER = "Filter",
    SELECT_HIGHLIGHT_ELEMENT = "SELECT_HIGHLIGHT_ELEMENT",
    PAGE_LOAD_STATUS = "PAGE_LOAD_STATUS",
    SET_ELEMENT_BACKGROUND_COLOR = "SET_ELEMENT_BACKGROUND_COLOR",
    GET_CHANGE_PAGE_CONTENT = "GET_CHANGE_PAGE_CONTENT",
    GET_PAGE_CONTENT = "GET_PAGE_CONTENT",
    FIND_ELEMENT_STATUS = "FIND_ELEMENT_STATUS",
    SET_ADD_SECTION_MODAL = "SET_ADD_SECTION_MODAL",
    BROWSER_CONTENT_STATUS = "BROWSER_CONTENT_STATUS",
    CLICKED_ON_CONTENT = "CLICKED_ON_CONTENT",
    SET_ADD_SECTION_MODAL_POSITION = "SET_ADD_SECTION_MODAL_POSITION",
    PAGE_LOAD_CONTENT = "PAGE_LOAD_CONTENT",
    REMOVE_NEW_SECTION_EVENT = "REMOVE_NEW_SECTION_EVENT",
    CREATE_NEW_SECTION_EVENT = "CREATE_NEW_SECTION_EVENT"
}
export interface TabListInterface {
    key: number;
    tab_name: string;
}

export interface RecommendationSseEventInterface {
    receive_h1_text: string;
    receive_h1_scores: string;
    receive_title_text: string;
    receive_title_scores: string;
    receive_h2_text: string;
    receive_h2_scores: string;
    req_completed: string;
    req_failed: string;
    error: string;
}

export interface PageNotCrawlInterface {
    [key: string]: string;
}

export interface GetTenantLimitInterface {
    userID: string;
    isCalledFromHomePage: boolean;
    getSimHistorypayload?: GetSaveHistoryPayloadInterface;
}
