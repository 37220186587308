import { call, put, takeEvery } from "redux-saga/effects";
import { AnyAction } from "redux";
import Apis from "./apis";
import ActionTypes, {
    savePackData
} from "./actions";
import { getContext } from "../../app/duck/context-container";
import { saveSubDomainList } from "../../project-setup/ducks/actions";

// tslint:disable-next-line:no-any
export function* fetchSubDomains(action: AnyAction): any {
    const { tenantCode } = getContext();
    const parser = {
        tenantCode: tenantCode as string
    };
    const subDomainListResponse = yield call(Apis.getSubDomainList, action.payload, parser);
    if (subDomainListResponse.status === 200) {
        yield put(saveSubDomainList(subDomainListResponse.data));
    }

}

// tslint:disable-next-line:no-any
export function* fetchPackData(action: AnyAction): any {
    const { tenantCode } = getContext();
    const parser = {
        tenantCode: tenantCode as string,
        projectId: action.payload.projectID
    };

    const packDataResponse = yield call(Apis.getPackData, action.payload, parser);
    if (packDataResponse.status === 200) {
        yield put(savePackData(packDataResponse.data));
    } else {
        yield put(savePackData({
            packTypes: [],
            weekStartDate: "",
            updatedOn: "",
            domainDetails: {}
        }));
    }

}

// tslint:disable-next-line: typedef
export function* watchFetchSubDomains() {
    yield takeEvery(ActionTypes.GET_SUB_DOMAIN_LIST, fetchSubDomains);
}

// tslint:disable-next-line: typedef
export function* watchFetchPackData() {
    yield takeEvery(ActionTypes.GET_PACK_DATA, fetchPackData);
}
